import React, { useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box, MenuItem, Typography } from "@mui/material";
import { getAllCategory } from "actions/adminActions/categoriesActions";
import { Card, CardHeader, Container } from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import SweetAlert from "react-bootstrap-sweetalert";

// import "./Quiz.css";
import { useState } from "react";
import MuiOverride from "components/Common/MuiOverride";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteCategory } from "actions/adminActions/categoriesActions";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "type";
import { getAllCourseCategory } from "actions/adminActions/courseAction";
import api2 from "api2";
import axios from "axios";

const CourseCategories = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [catId, setCatId] = useState(null);
  const [deleteCategoryId, setDeleteCategoryId] = useState();
  const token = useSelector((state) => state?.authState?.user?.accessToken);

  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });
  const { courseCategory } = useSelector((state) => state?.categories);
  useEffect(() => {
    trackPromise(
      dispatch(getAllCourseCategory(0, 500)),
      loaderType.GENERAL_REQUEST
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Course Category Name",
        accessorKey: "name",
        Cell: ({ row }) => (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography className="">{row?.original?.name}</Typography>
            </Box>
          </>
        ),
      },

      {
        header: "Parent Category",
        Cell: ({ row }) => (
          <>
            <Typography className="">
              {typeof row?.original?.parent === "object"
                ? row?.original?.parent?.parent
                : ""}
            </Typography>
          </>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };

  useEffect(() => {
    trackPromise(dispatch(getAllCategory(0, 3000)), loaderType.GENERAL_REQUEST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const confirmAlert = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      let result = await api2.delete(
        `api/courseCategory/${deleteCategoryId}`,
        config
      );
      setAlert({
        isOpenAlert: true,
        status: "success",
        message: "Course category deleted successfully.",
      });
      trackPromise(dispatch(getAllCourseCategory(0, 500)));
      return result.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Course Category</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push('/course/add-category')}
              >
                <PlusIcon color="white" />
                Add Course Category
              </button>
            </div>
          </div>
        </CardHeader>
        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={courseCategory}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={() => {
                    history.push(`/course/edit-category/${row.original?._id}`);
                  }}
                  sx={{ m: 0 }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={() => {
                    setDeleteCategoryId(row.original?._id);
                    handleDelete();
                  }}
                  sx={{ m: 0 }}
                >
                  Delete
                </MenuItem>
              </>,
            ]}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'warning' && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === 'success' && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default CourseCategories;
