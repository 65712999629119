export const loaderType = {
    GENERAL_REQUEST: "GENERAL_REQUEST",
    GET_CUSTOM_CATEGORIES: "GET_CUSTOM_CATEGORIES",
    GET_CATEGORIES: "GET_CATEGORIES",
    GET_CUSTOM_LABS: "GET_CUSTOM_LABS",
    GET_LABS: "GET_LABS",
    GET_ORGANISATIONS: "GET_ORGANISATIONS",
    GET_USERS: "GET_USERS",
    GET_LICENSES: "GET_LICENSES"
}

export const examType = [
    {
        id: 0,
        value: 'text',
        label: "text",
    },
    {
        id: 1,
        value: 'document',
        label: "document",
    },
    {
        id: 2,
        value: 'video',
        label: "video",
    },
    {
        id: 3,
        value: 'challenge',
        label: "challenge",
    },
]