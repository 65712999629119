import React from 'react'

export default function InfoIcon({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.32"
        d="M9.99935 1.66675C5.39685 1.66675 1.66602 5.39758 1.66602 10.0001C1.66602 14.6026 5.39685 18.3334 9.99935 18.3334C14.6018 18.3334 18.3327 14.6026 18.3327 10.0001C18.3327 5.39758 14.6018 1.66675 9.99935 1.66675ZM9.99935 3.33341C13.681 3.33341 16.666 6.31841 16.666 10.0001C16.666 13.6817 13.681 16.6667 9.99935 16.6667C6.31768 16.6667 3.33268 13.6817 3.33268 10.0001C3.33268 6.31841 6.31768 3.33341 9.99935 3.33341Z"
        fill={color} />
    <path opacity="0.988987"
        d="M10.0002 5.83325C9.54016 5.83325 9.16683 6.20659 9.16683 6.66659C9.16683 7.12659 9.54016 7.49992 10.0002 7.49992C10.4602 7.49992 10.8335 7.12659 10.8335 6.66659C10.8335 6.20659 10.4602 5.83325 10.0002 5.83325ZM9.16683 8.33325C8.70683 8.33325 8.3335 8.70659 8.3335 9.16659C8.3335 9.56825 8.63267 9.86824 9.01017 9.94824L8.51599 12.3433C8.32266 13.3108 9.01433 14.1666 10.0002 14.1666H10.8335C11.2935 14.1666 11.6668 13.7933 11.6668 13.3333C11.6668 12.8733 11.2935 12.4999 10.8335 12.4999H10.1827L10.8077 9.32324C10.911 8.80741 10.526 8.33325 10.0002 8.33325C9.89516 8.33325 9.30183 8.33325 9.16683 8.33325Z"
        fill={color} />
</svg>
  )
}