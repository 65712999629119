import { MenuItem } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useMemo } from 'react';

import {
  getAllExam,
  getSingleScheduledExam,
} from 'actions/adminActions/examsAction';
import api2 from 'api2';
import axios from 'axios';
import MuiOverride from 'components/Common/MuiOverride';
import PlusIcon from 'iconComponents/PlusIcon';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Card, CardHeader, Container } from 'reactstrap';
const Reports = () => {
  const history = useHistory();
  var token = useSelector((state) => state?.authState?.user?.accessToken);
  const { _id } = useParams();
  const ref = useRef();
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState([]);
  const completeExamUsersCount = (row) => {
    const completedExam = [];
    row.original.userIds.forEach((i) => {
      if (i.resultId?.length) {
        completedExam.push(i.resultId);
      }
    });
    return completedExam.length;
  };
  const averageScore = (row) => {
    const totalScore =
      row.original.userIds?.[0]?.resultId?.[0]?.totalScore *
      row.original.userIds.length;
    let score = 0;
    row.original.userIds.forEach((i) => {
      if (i?.resultId?.length) {
        score += i?.resultId[0]?.score;
      }
    });
    return parseFloat((score * 100) / totalScore || 0).toFixed(2);
  };
  const averageDuration = (row) => {
    const totalDuration = row.original?.duration * row.original.userIds.length;
    let duration = 0;
    row.original.userIds.forEach((i) => {
      if (i?.resultId?.length) {
        duration += i?.resultId[0]?.examTime;
      }
    });
    return duration ? (duration / row.original.userIds.length).toFixed(2) : 0;
  };
  const columns = useMemo(
    () => [
      {
        header: 'Exam Name',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <span
            className="test_name cursor-pointer"
            onClick={() => history.push(`/user/report/${row?.original?._id}`)}
          >
            {row?.original?.name}
          </span>
        ),
      },
      // {
      //   header: "Total quiz",
      //   Cell: ({ row }) => <>{row?.original?.examId?.quiz.length}</>,
      // },
      {
        header: 'Total Users',
        accessorKey: 'userIds.length',
        Cell: ({ row }) => <>{row?.original?.userIds.length}</>,
      },
      {
        header: 'Completed Users',

        Cell: ({ row }) => <>{completeExamUsersCount(row)}</>,
      },
      {
        header: 'Average Score',
        Cell: ({ row }) => <>{averageScore(row)}</>,
      },
      {
        header: 'Average Duration',
        Cell: ({ row }) => <>{averageDuration(row)}</>,
      },
      {
        header: 'Duration',
        Cell: ({ row }) => <>{row?.original?.duration} Minutes</>,
      },
    ],
    []
  );
  const allExams = useSelector(
    (state) => state?.examlabs?.allExams?.data?.list
  );
  useEffect(() => {
    dispatch(getAllExam('all'));
  }, [dispatch]);
  const handleTest = () => {
    history.push('/exam/schedule');
  };
  const singleUserExams = useSelector(
    (state) => state.examlabs.singleUserExams
  );

  const handleResult = async (examData) => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      const payload = {
        isPublic: !examData?.isPublic,
      };
      await api2
        .put(`/api/scheduledExam/${examData?._id}`, payload, config)
        .then((res, err) => {
          if (res) {
            dispatch(getAllExam('all'));
          }
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };

  const headers = [
    { label: 'SR No.', key: 'srNo' },
    { label: 'User Name', key: 'userName' },
    { label: 'Exam Name', key: 'testName' },
    { label: 'Status', key: 'status' },
    { label: 'Total Marks', key: 'totalMarks' },
    { label: 'Obtained Marks', key: 'obtainedMarks' },
    { label: 'Total Time', key: 'duration' },
    { label: 'Attempt Duration Time', key: 'examTime' },
    { label: 'Attempted Date', key: 'attemptedDate' },
  ];

  useEffect(() => {
    const examResultArray = [];
    if (singleUserExams?.data?.exams?.length) {
      singleUserExams?.data?.exams.map((item) => {
        let examData = {};
        item.userIds.forEach((exam, index) => {
          examData = {
            ...examData,
            srNo: index + 1,
            userName: exam?.userId?.name,
            testName: item?.name,
            status: exam?.resultId?.length ? 'Complete' : 'Not Attempted',
            totalMarks: exam?.resultId?.length && exam?.resultId[0]?.totalScore,
            obtainedMarks:
              (exam?.resultId?.length && exam?.resultId[0]?.score) || '--',
            attemptedDate:
              exam?.resultId?.length && exam?.resultId[0]?.createdAt
                ? moment(
                    exam?.resultId?.length && exam?.resultId[0]?.createdAt
                  ).format('DD/MM/YYYY')
                : '--',
            duration: item?.duration,
            examTime: exam?.resultId?.length && exam?.resultId[0]?.examTime,
          };
          examResultArray.push(examData);
        });
      });
    }
    setCsvData(examResultArray);
  }, [singleUserExams]);
  const handleCSVDownload = (id) => {
    dispatch(getSingleScheduledExam(id));
  };
  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Reports</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button className="pr-btn-with-icon" onClick={handleTest}>
                <PlusIcon color="white" />
                Scheduled exam
              </button>
            </div>
          </div>
        </CardHeader>
        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={allExams || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={(e) => {
                    ref.current.link.click();
                  }}
                  sx={{ m: 0 }}
                >
                  CSV Download
                </MenuItem>
                <CSVLink
                  ref={ref}
                  filename={`${moment().format('DD-MM-YYYY')}`}
                  data={csvData}
                  className="d-none"
                  headers={headers}
                ></CSVLink>
                <MenuItem
                  key={1}
                  onClick={() => {
                    handleResult(row?.original);
                  }}
                  sx={{ m: 0 }}
                >
                  {row?.original.isPublic ? 'Private' : 'Public'} result
                </MenuItem>
              </>,
            ]}
          />
        </MuiOverride>
      </Card>
    </Container>
  );
};

export default Reports;
