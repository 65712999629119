import React from "react";

export default function MicIcon({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.25 9.75V12C20.25 16.1719 17.1562 19.5938 13.125 20.1562V21.75H15C15.8438 21.75 16.5 22.4062 16.5 23.25C16.5 23.6719 16.1719 24 15.75 24H8.25C7.82812 24 7.5 23.6719 7.5 23.25C7.5 22.4062 8.15625 21.75 9 21.75H10.875V20.1562C6.75 19.5938 3.75 15.8438 3.75 11.625V9.75C3.75 9.32812 4.07812 9 4.5 9H5.25C5.67188 9 6 9.32812 6 9.75V11.7656C6 14.8594 8.53125 17.8125 11.625 18C15.0938 18.1875 18 15.4219 18 12V9.75C18 9.32812 18.3281 9 18.75 9H19.5C19.9219 9 20.25 9.32812 20.25 9.75Z"
        fill={color}
      />
      <path
        d="M12 16.5C14.4844 16.5 16.5 14.4844 16.5 12V4.5C16.5 2.01562 14.4844 0 12 0C9.51562 0 7.5 2.01562 7.5 4.5V12C7.5 14.4844 9.51562 16.5 12 16.5Z"
        fill={color}
      />
    </svg>
  );
}
