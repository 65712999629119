import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

import "@fortawesome/fontawesome-free/css/all.min.css";

import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-dashboard.min.css";
import "assets/css/custom.css";
//import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import AdminLayout from "layouts/Admin.jsx";
import Login from "views/pages/single/Login.jsx";
import { Provider } from "react-redux";
import { persistor, store } from "store";

import { PersistGate } from "redux-persist/integration/react";

//import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { normalStore } from "normalStore";

//const root = ReactDOM.createRoot(document.getElementById("root"));

ReactDOM.render(
  <Provider store={normalStore}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}></PersistGate>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
          {/* <Redirect from="*" to="/categories" /> */}
          <Redirect from="*" to="/users" />
          {/* <LoadingIndicator/> */}
        </Switch>
      </BrowserRouter>
    </Provider>
  </Provider>,
  document.getElementById("root")
);
