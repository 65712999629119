/** @format */

import React from "react";

export const CardStats = ({ title, value, width }) => {
  return (
    // <div style={{ width }} className=" col-xl-4 col-lg-6 mb-4">
    <div className="card card-stats mb-xl-0">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5 className="card-title states-card-title text-uppercase text-muted mb-0">
              {title}
            </h5>
            <span className="states-card-sub-title mb-0">{value}</span>
          </div>
          <div className="col-auto">
            <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
              <i className="ni ni-chart-pie-35"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
