import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          // ".MuiTableRow-root": {
          //   background: "rgb(246, 249, 252) !important",
          // },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        // root: {
        //   background: "rgb(246, 249, 252) !important",
        // },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // boxShadow: "none !important",
          // border: "1px solid #D5D8E8 !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "155%",
          color: "#070707",
        },
      },
    },
  },
});

const MuiOverride = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

export default MuiOverride;
