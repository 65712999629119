import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import moment from "moment";

import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Col,
  Container,
  Button,
  Modal,
  Row,
  CardBody,
  Media,
  Form,
  Input,
  Badge,
} from "reactstrap";
import {
  deletePlan,
  editPlan,
  editCoursePlan,
  getAllPlans,
  getAllCoursePlans,
} from "actions/adminActions/plansActions";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { hideAlert } from "actions/templateAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { loaderType } from "../../../type";
import { trackPromise } from "react-promise-tracker";
import {
  disableLicenseActivation,
  enableLicenseActivation,
} from "../../../actions/adminActions/plansActions";
import classnames from "classnames";
import Select2 from "react-select2-wrapper";
import { Checkbox } from "@material-ui/core";
import { getAllLab } from "actions/adminActions/labsActions";

const CoursePlans = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plans.coursePlans);
  const categories = useSelector((state) => state.categories.categories);
  const courses = useSelector((state) => state.courses.courses);
  const labs = useSelector((state) => state.labs.labs);

  const [planId, setPlanId] = useState(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [editData, setEditData] = useState({
    allowedCategories: [],
    allowedChallenges: [],
  });
  const [editStatus, setEditStatus] = useState(false);
  const [categoryRestricted, setCategoryRestricted] = useState(true);
  const [labsRestricted, setlabsRestricted] = useState(true);

  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  useEffect(() => {
    if (props.location) {
      setEditStatus(true);
      setEditData(props.location.state.data);
    }

    trackPromise(
      dispatch(getAllCoursePlans(0, 300)),
      loaderType.GENERAL_REQUEST
    );
    trackPromise(dispatch(getAllLab(0, 3000)), loaderType.GENERAL_REQUEST);
  }, [dispatch]);

  const toggleModal = () => {
    setNotificationModal(!notificationModal);
  };

  const validateCustomStylesForm = async () => {
    let newState = editData;
    if (newState.type === "") {
      newState.typeErr = "invalid";
    } else {
      newState.typeErr = "valid";
    }

    if (newState.validity === "") {
      newState.validityErr = "invalid";
    } else {
      newState.validityErr = "valid";
    }

    if (newState.typeErr === "invalid" || newState.contentErr === "invalid") {
      setEditData({
        ...editData,
        ...newState,
      });
    } else {
      trackPromise(
        dispatch(editCoursePlan(editData.courseId, editData)),
        loaderType.GENERAL_REQUEST
      )
        .then((res) => {
          setEditData(false);
        })
        .catch((err) => {});
    }
  };

  const handleLicenseActivation = (e, item) => {
    e.preventDefault();
    trackPromise(
      !item.isActive
        ? dispatch(enableLicenseActivation(item))
        : dispatch(disableLicenseActivation(item)),
      loaderType.GENERAL_REQUEST
    ).then(() => {
      trackPromise(
        dispatch(getAllCoursePlans(0, 300)),
        loaderType.GENERAL_REQUEST
      );
    });
  };

  const capFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = editData;
    newState[name] = value;
    if (value === "") {
      newState[name + "Err"] = "invalid";
    } else {
      newState[name + "Err"] = "valid";
    }
    setEditData({
      ...editData,
      ...newState,
    });
  };

  const categoryIdsHander = useCallback(
    (e, stateName) => {
      let newState = editData;
      let targetValue = e.params.data.id;
      let isExist =
        newState[stateName].findIndex((x) => x === targetValue) >= 0;

      if (isExist) {
        newState[stateName] = newState[stateName].filter(
          (x) => x !== targetValue
        );
      } else {
        newState[stateName].push(targetValue);
      }

      setEditData(newState);
    },
    [editData.allowedCategories, editData.allowedChallenges]
  );

  const handleConfirm = () => {
    const isSuccess = type === "success";
    dispatch(hideAlert());
    if (isSuccess) {
      // navigateTo();
      setEditStatus(false);
    }
  };

  const test = (data) => {};

  return (
    <>
      <SimpleHeader name="React Tables" parentName="Tables" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      {!editStatus ? (
        <Container className="" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">&nbsp;</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon btn-success"
                        id="tooltip969372949"
                        size="sm"
                        onClick={() => history.push('/addCourseLicenses')}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Add Labs</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <MaterialTable
                  title="Licenses"
                  columns={[
                    {
                      title: 'Name',
                      field: 'name',
                      render: (item) => (
                        <Media
                          className="align-items-center"
                          onClick={(e) => {
                            setEditStatus(true);
                            setEditData({
                              ...item,
                              allowedCategories: item.allowedCategories.map(
                                (x) => x._id
                              ),
                              allowedChallenges: (item.allowedChallenges
                                ? item.allowedChallenges
                                : []
                              ).map((x) => x._id),
                            });
                          }}
                        >
                          <Media>
                            <span className="name mb-0 text-sm text-primary">
                              {item.name}
                            </span>
                          </Media>
                        </Media>
                      ),
                    },
                    // {
                    //   title: "Allowed Categories", field: 'allowedCategories', render: (item) => {
                    //     return <span>{item.allowedCategories.map(c => c.fullName).join(",")}</span>
                    //   }
                    // },
                    {
                      title: 'Allowed Labs',
                      field: 'allowedChallenges',
                      render: (item) => {
                        return (
                          <span>
                            {item.allowedChallenges
                              .map((c) => c.title)
                              .join(',')}
                          </span>
                        );
                      },
                    },
                    // { title: "Type", field: "type", render: (item) => capFirstLetter(item.type) },
                    // { title: "Users Enrolled", field: "usersEnrolled" },
                    // {
                    //   title: 'Active', field: 'isActive', render: rowData => <Badge color="" className="badge-dot mr-4">
                    //     <i
                    //       className={classnames(
                    //         { "bg-success": rowData.isActive },
                    //         { "bg-warning": !rowData.isActive }
                    //       )}
                    //     />
                    //     <span
                    //       className={classnames(
                    //         "status",
                    //         { "text-success": rowData.isActive },
                    //         { "text-warning": !rowData.isActive }
                    //       )}
                    //     >
                    //       {rowData.isActive ? "Yes" : "No"}
                    //     </span>
                    //   </Badge>
                    // },
                    {
                      title: 'Created On',
                      field: 'createdAt',
                      render: (item) =>
                        moment(item.createdAt).format('DD-MM-YYYY'),
                    },
                    {
                      title: 'Validity',
                      field: 'validity',
                      render: (item) => <span>{item.validity} days</span>,
                    },
                    // {
                    //   title: "License Id",
                    //   field: "_id",
                    // },
                    {
                      title: '',
                      field: '',
                      align: 'right',
                      render: (item) => (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            color=""
                            role="button"
                            size="sm"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu
                            className="dropdown-menu-arrow table-dropdown-action"
                            right
                          >
                            <DropdownItem
                              onClick={(e) => {
                                setEditStatus(true);
                                setEditData({
                                  ...item,
                                  allowedCategories: item.allowedCategories.map(
                                    (x) => x._id
                                  ),
                                  allowedChallenges: (item.allowedChallenges
                                    ? item.allowedChallenges
                                    : []
                                  ).map((x) => x._id),
                                });
                              }}
                            >
                              Edit
                            </DropdownItem>
                            {/* <DropdownItem
                              href="#p"
                              onClick={(e) => handleLicenseActivation(e, item)}>
                              {!item.isActive ? "Enable License" : "Disable License"}
                            </DropdownItem> */}
                            <DropdownItem
                              onClick={() => {
                                setPlanId(item._id);
                                toggleModal();
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ),
                    },
                  ]}
                  data={plans}
                  options={{
                    actionsColumnIndex: -1,
                    sorting: true,
                    selection: true,
                    exportButton: true,
                    exportAllData: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#8898aa',
                      fontFamily: 'inherit',
                      fontSize: '12px',
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                    },

                    rowStyle: {
                      fontFamily: 'inherit',
                      fontSize: '13px',
                      color: '#525f7f',
                      padding: '2px',
                    },
                  }}
                />

                {/* <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="table-check-all"
                          type="checkbox"
                        />
                        <planel
                          className="custom-control-planel"
                          htmlFor="table-check-all"
                        />
                      </div>
                    </th>
                    <th className="sort" data-sort="name" scope="col">
                      License ID
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Allowed Categories
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Type
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Created On
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Validity
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {plans &&
                    plans.map((plan) => {
                      return (
                        <tr key={plan.id}>
                          <td>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="table-check-all"
                                type="checkbox"
                              />
                              <planel
                                className="custom-control-planel"
                                htmlFor="table-check-all"
                              />
                            </div>
                          </td>
                          <td>
                            <span className="name mb-0 text-sm">
                              {plan._id}
                            </span>
                          </td>
                          <td className="budget">{plan.allowedCategories}</td>
                          <td className="budget">{plan.type}</td>
                          <td className="budget">{plan.createdAt}</td>
                          <td className="budget">{plan.validity}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setCatId(plan.id);
                                    toggleModal();
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-planel="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
             */}
              </Card>
            </div>
          </Row>
        </Container>
      ) : (
        <Container className="" fluid>
          {test(editData)}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Edit License</h3>
                  </CardHeader>
                  <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                        {/* <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Name
                          </label>
                          <Input
                            value={editData.name}
                            name="name"
                            id="validationCustom01"
                            placeholder="License Name"
                            type="text"
                            valid={editData.name && editData.name.length > 0}
                            invalid={editData.name && editData.name.length < 0}
                            onChange={customStylesForm}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col> */}
                        {/* <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Total Allowed User's
                          </label>
                          <Input
                            value={editData.allowedUsersCount}
                            name="allowedUsersCount"
                            id="validationCustom04"
                            placeholder="0"
                            type="text"
                            valid={editData.allowedUsersCount && editData.allowedUsersCount >= 0}
                            invalid={editData.allowedUsersCount && editData.allowedUsersCount < 0}
                            onChange={customStylesForm}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col> */}
                        {/* <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Type
                          </label>
                          <Input
                            value={editData.type}
                            name="type"
                            id="validationCustom01"
                            placeholder="Type"
                            type="select"
                            valid={editData.typeErr === "valid"}
                            invalid={editData.typeErr === "invalid"}
                            onChange={customStylesForm}
                          >
                            <option value="" selected disabled>Select Type</option>
                            <option value="trial">Trial</option>
                            <option value="standard">Standard</option>
                            <option value="full">Full</option>
                          </Input>
                          <div className="valid-feedback">Looks good!</div>
                        </Col> */}
                        {/* <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Validity
                          </label>
                          <Input
                            value={editData.validity}
                            name="validity"
                            id="validationCustom03"
                            placeholder="Validity"
                            type="text"
                            valid={editData.validityErr === "valid"}
                            invalid={editData.validityErr === "invalid"}
                            onChange={customStylesForm}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col> */}

                        {/* <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Course
                        </label>

                        <Input
                          value={editData.courseId}
                          name="courseid"
                          id="validationCustom02"
                          placeholder="courseid"
                          type="select"
                          // valid={state.typeErr === "valid"}
                          // invalid={state.typeErr === "invalid"}
                          onChange={customStylesForm}
                        >
                          <option value="" selected disabled>Select Course</option>
                          {courses.map((x)=>{
                            return <option value={x.id}>{x.courseName}</option>
                          })}
                        </Input>
                      </Col> */}

                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom07"
                          >
                            Category
                          </label>

                          <Select2
                            className="form-control-selector"
                            multiple
                            value={editData.allowedCategories}
                            valid={
                              editData.allowedCategories &&
                              editData.allowedCategories.length > 0
                            }
                            onSelect={(e) =>
                              categoryIdsHander(e, 'allowedCategories')
                            }
                            onUnselect={(e) =>
                              categoryIdsHander(e, 'allowedCategories')
                            }
                            options={{
                              placeholder: 'Please select Category',
                            }}
                            data={(categoryRestricted
                              ? categories
                              : editData
                              ? editData.allowedCategories
                              : []
                            ).map((x, i) => {
                              return {
                                id: categoryRestricted ? x._id : x,
                                text: x.fullName,
                                value: categoryRestricted ? x._id : x,
                              };
                            })}
                          />
                          {/* <Row className="d-flex align-items-center m-0 ">
                            <span>
                              Show all categories
                            </span>
                            <Checkbox checked={categoryRestricted} onChange={(e) => {
                              setCategoryRestricted(e.target.checked)
                            }}
                            />
                          </Row> */}
                          <div className="valid-feedback">Looks good!</div>
                        </Col>

                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom07"
                          >
                            labs
                          </label>
                          <Select2
                            className="form-control"
                            multiple
                            value={editData.allowedChallenges}
                            valid={
                              editData.allowedChallenges &&
                              editData.allowedChallenges.length > 0
                            }
                            onSelect={(e) =>
                              categoryIdsHander(e, 'allowedChallenges')
                            }
                            onUnselect={(e) =>
                              categoryIdsHander(e, 'allowedChallenges')
                            }
                            options={{
                              placeholder: 'Please select Category',
                            }}
                            data={(labsRestricted
                              ? labs
                              : editData
                              ? editData.allowedChallenges
                              : []
                            ).map((x, i) => {
                              return {
                                id: labsRestricted ? x._id : x,
                                text: x.title,
                                value: labsRestricted ? x._id : x,
                              };
                            })}
                          />
                          {/* <Row className="d-flex align-items-center m-0 ">
                          <span>
                            Show all labs
                          </span>
                          <Checkbox checked={labsRestricted} onChange={(e) => {
                            setlabsRestricted(e.target.checked);
                          }}
                          />
                        </Row> */}
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          validateCustomStylesForm();
                        }}
                      >
                        Edit License
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      )}
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={notificationModal}
        toggle={toggleModal}
      >
        <div className="modal-body">
          <div className="">
            <h4 className="heading mt-2">
              Are you sure you want to delete? the selected license?
            </h4>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              trackPromise(
                dispatch(deletePlan(planId)),
                loaderType.GENERAL_REQUEST
              );
              toggleModal();
            }}
          >
            Delete
          </Button>
          <Button
            className="text-white"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CoursePlans;
