import API from "api2";
import { showAlert } from "actions/templateAction";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "../../type";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const createCategory = (formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    const bodyFormData = new FormData();
    bodyFormData.append('fullName', formData.fullName);
    bodyFormData.append('shortName', formData.shortName);
    bodyFormData.append('subTitle', formData.subTitle);
    bodyFormData.append('isPublic', formData.isPublic);
    bodyFormData.append('description', formData.description);
    bodyFormData.append('path', formData.path);
    bodyFormData.append('type', formData.type);
    bodyFormData.append('subType', formData.subType);
    if (formData.mainCategoryId)
      bodyFormData.append('mainCategoryId', formData.mainCategoryId);
    if (formData.bannerImage)
      bodyFormData.append('bannerImage', formData.bannerImage?.file);
    if (formData.iconImage)
      bodyFormData.append('iconImage', formData.iconImage?.file);
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/category`,
        headers: config(token),
        data: bodyFormData,
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'CREATE_CATEGORY_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          resolve({ success: true, message: data.message });
        })
        .catch((err) => {
          let errMessage = err.response && err.response.data.message;
          dispatch({ type: 'CREATE_CATEGORY_ERROR' });
          dispatch(showAlert(true, 'warning', errMessage));
          reject({ success: true, message: errMessage });
        });
    });
  };
};

export const editCategory = (categoryId, formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    const bodyFormData = new FormData();
    bodyFormData.append('fullName', formData.fullName);
    bodyFormData.append('shortName', formData.shortName);
    bodyFormData.append('subTitle', formData.subTitle);
    bodyFormData.append('path', formData.path);
    bodyFormData.append('type', formData.type);
    bodyFormData.append('subType', formData.subType);
    bodyFormData.append('isPublic', formData.isPublic);
    bodyFormData.append('description', formData.description);
    if (formData.type === 'sub-category')
      bodyFormData.append('mainCategoryId', formData.mainCategoryId);
    if (formData.bannerImage)
      bodyFormData.append('bannerImage', formData.bannerImage?.file);
    if (formData.iconImage)
      bodyFormData.append('iconImage', formData.iconImage?.file);
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/category/${categoryId}`,
        headers: config(token),
        data: bodyFormData,
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_CATEGORY_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          dispatch(getAllCategory(0, 1000));
          resolve({ success: true, message: data.message });
        })
        .catch((err) => {
          dispatch({ type: 'UPDATE_CATEGORY_ERROR' }, err);
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch(showAlert(true, 'warning', errMessage));
          reject({ success: false, message: errMessage });
        });
    });
  };
};

export const deleteCategory = (categoryId) => {
  return (dispatch, getState) => {
    const state = getState().categories.categories;
    const token = getState().authState.user.accessToken;

    return trackPromise(new Promise((resolve, reject) => {
      API({
        method: "delete",
        url: `api/category/${categoryId}`,
        headers: config(token),
      })
        .then(() => {
          let c = state.filter((item) => item._id !== categoryId);
          dispatch({ type: "DELETE_CATEGORY_SUCCESS", data: c });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: "DELETE_CATEGORY_ERROR" }, err);
          reject();
        });
    }), loaderType.GET_CATEGORIES)

  };
};

export const getAllCategory = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: "get",
        url: `api/category?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: "GET_ALL_CATEGORY_SUCCESS",
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({
            type: "GET_ALL_CATEGORY_FAILED",
            payload: "failed",
            err: errMessage,
          });
          reject();
        });
    })
  };
};
