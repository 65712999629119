import React from "react";

export default function SmMultiQueIcon({ color }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        d="M11.6668 3C11.2068 3 10.8335 3.37333 10.8335 3.83333V5.5C10.8335 5.96 11.2068 6.33333 11.6668 6.33333H16.6668C17.1268 6.33333 17.5002 5.96 17.5002 5.5V3.83333C17.5002 3.37333 17.1268 3 16.6668 3H11.6668ZM11.6668 8C11.2068 8 10.8335 8.37333 10.8335 8.83333C10.8335 9.29333 11.2068 9.66667 11.6668 9.66667H15.0002C15.4602 9.66667 15.8335 9.29333 15.8335 8.83333C15.8335 8.37333 15.4602 8 15.0002 8H11.6668ZM11.6668 11.3333C11.2068 11.3333 10.8335 11.7067 10.8335 12.1667V13.8333C10.8335 14.2933 11.2068 14.6667 11.6668 14.6667H16.6668C17.1268 14.6667 17.5002 14.2933 17.5002 13.8333V12.1667C17.5002 11.7067 17.1268 11.3333 16.6668 11.3333H11.6668ZM11.6668 16.3333C11.2068 16.3333 10.8335 16.7067 10.8335 17.1667C10.8335 17.6267 11.2068 18 11.6668 18H15.0002C15.4602 18 15.8335 17.6267 15.8335 17.1667C15.8335 16.7067 15.4602 16.3333 15.0002 16.3333H11.6668Z"
        fill={color}
      />
      <path
        d="M4.5575 3C3.41583 3 2.5 3.91583 2.5 5.0575V7.60917C2.5 8.75084 3.41583 9.66667 4.5575 9.66667H7.10917C8.25084 9.66667 9.16667 8.75084 9.16667 7.60917V5.0575C9.16667 3.91583 8.25084 3 7.10917 3H4.5575ZM6.79667 5.21334C6.96 5.05 7.20666 5.05 7.37 5.21334C7.53333 5.3775 7.53333 5.62333 7.37 5.78666L5.70333 7.45333C5.53999 7.61666 5.29334 7.61666 5.13 7.45333L4.29667 6.62C4.13334 6.45583 4.13334 6.21 4.29667 6.04667C4.46 5.88334 4.70666 5.88334 4.87 6.04667L5.41667 6.59334L6.79667 5.21334ZM4.5575 11.3333C3.41583 11.3333 2.5 12.2492 2.5 13.3908V15.9425C2.5 17.0842 3.41583 18 4.5575 18H7.10917C8.25084 18 9.16667 17.0842 9.16667 15.9425V13.3908C9.16667 12.2492 8.25084 11.3333 7.10917 11.3333H4.5575ZM6.79667 13.5467C6.96 13.3833 7.20666 13.3833 7.37 13.5467C7.53333 13.7108 7.53333 13.9567 7.37 14.12L5.70333 15.7867C5.53999 15.95 5.29334 15.95 5.13 15.7867L4.29667 14.9533C4.13334 14.7892 4.13334 14.5433 4.29667 14.38C4.46 14.2167 4.70666 14.2167 4.87 14.38L5.41667 14.9267L6.79667 13.5467Z"
        fill={color}
      />
    </svg>
  );
}
