import React from "react";

export default function SmTrashIcon({ color }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.16659 4V12C5.16659 12.7364 5.76354 13.3333 6.49992 13.3333H10.4999C11.2363 13.3333 11.8333 12.7364 11.8333 12V4H13.1666V12C13.1666 13.4728 11.9727 14.6667 10.4999 14.6667H6.49992C5.02716 14.6667 3.83325 13.4728 3.83325 12V4H5.16659Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49861 1.33301C7.76195 1.33301 7.16528 1.92793 7.16528 2.66245H3.83195C3.46395 2.66245 3.16528 2.96025 3.16528 3.32717C3.16528 3.6941 3.46395 3.9919 3.83195 3.9919H13.1653C13.5333 3.9919 13.8319 3.6941 13.8319 3.32717C13.8319 2.96025 13.5333 2.66245 13.1653 2.66245H9.83194C9.83194 1.92793 9.23528 1.33301 8.49861 1.33301ZM7.16675 5.35243C7.53494 5.35243 7.83342 5.65004 7.83342 6.01716V11.335C7.83342 11.7021 7.53494 11.9997 7.16675 11.9997C6.79856 11.9997 6.50008 11.7021 6.50008 11.335V6.01716C6.50008 5.65004 6.79856 5.35243 7.16675 5.35243ZM10.5001 6.01716C10.5001 5.65004 10.2016 5.35243 9.83342 5.35243C9.46522 5.35243 9.16675 5.65004 9.16675 6.01716V11.335C9.16675 11.7021 9.46522 11.9997 9.83342 11.9997C10.2016 11.9997 10.5001 11.7021 10.5001 11.335V6.01716Z"
        fill={color}
      />
    </svg>
  );
}
