const initState = {
  plans: [],
  coursePlans: []
}

const plansReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_PLAN_SUCCESS':
      return {
        ...state,
        message: 'PLAN created Successfully!',
      };
    case 'CREATE_PLAN_ERROR':
      return {
        ...state,
        message: 'PLAN creation failed!',
      };
    case 'UPDATE_PLAN_SUCCESS':
      return {
        ...state,
        message: 'PLAN updated Successfully!',
      };
    case 'UPDATE_PLAN_ERROR':
      return {
        ...state,
        message: 'PLAN updation failed!',
      };
    case 'DELETE_PLAN_SUCCESS':
      return {
        ...state,
        plans: action.data,
        message: 'PLAN deleted Successfully!',
      };
    case 'DELETE_PLAN_ERROR':
      return {
        ...state,
        message: 'PLAN deletion failed!',
      };
    case "GET_ALL_PLAN_SUCCESS":
      return {
        ...state,
        plans: action.data,
        message: "Lab fetch Successfully!",
      };
    case "GET_ALL_PLAN_FAILED":
      return {
        ...state,
        plans: [],
        message: action.err,
      };
    case "GET_ALL_COURSE_PLAN_SUCCESS":
      return {
        ...state,
        coursePlans: action.data,
        message: "Lab fetch Successfully!",
      };
    case "GET_ALL_COURSE_PLAN_FAILED":
      return {
        ...state,
        coursePlans: [],
        message: action.err,
      };

    case 'UPDATE_COURSE_PLAN_SUCCESS':
      return {
        ...state,
        message: 'PLAN updated Successfully!',
      };
    case 'UPDATE_COURSE_PLAN_ERROR':
      return {
        ...state,
        message: 'PLAN updation failed!',
      };
    case "FETCH_LICENSE_SUCCESS":
      return {
        ...state,
        singleLicense: action?.data,
        message: "License fetch successfully"
      }
    case "FETCH_COURSE_LICENSE_SUCCESS":
      return {
        ...state,
        singleCourseLicense: action?.data,
        message: "License fetch successfully"
      }
    case "FETCH_COURSE_LICENSE_FAILED":
      return {
        ...state,
        singleCourseLicense: {},
        message: "Can't fetch license"
      }

    default:
      return state;
  }
};

export default plansReducer;
