import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import moment from "moment";

import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Col,
  Container,
  Button,
  Modal,
  Row,
  CardBody,
  Media,
  Form,
  Input,
  Badge,
} from "reactstrap";
import {
  deletePlan,
  editPlan,
  editCourseExamLabs,
  getAllPlans,
  getAllCoursePlans,
} from "actions/adminActions/plansActions";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { hideAlert } from "actions/templateAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { loaderType } from "../../../type";
import { trackPromise } from "react-promise-tracker";
import {
  disableLicenseActivation,
  enableLicenseActivation,
} from "../../../actions/adminActions/plansActions";
import classnames from "classnames";
import Select2 from "react-select2-wrapper";
import { Checkbox } from "@material-ui/core";
import { getAllLab } from "actions/adminActions/labsActions";
import { getExamLab } from "../../../actions/adminActions/examlabsActions";

const CourseExamPlans = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const Examlabs = useSelector((state) => state.examlabs.examlabs);
  const plans = useSelector((state) => state.plans.coursePlans);
  const categories = useSelector((state) => state.categories.categories);
  const courses = useSelector((state) => state.courses.courses);
  const labs = useSelector((state) => state.labs.labs);

  const [planId, setPlanId] = useState(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [editData, setEditData] = useState({
    _id: props.location.state.data._id ? props.location.state.data._id : "",
    allowedCategories: [],
    allowedChallenges: [],
    examChallenges: [],
  });
  const [editStatus, setEditStatus] = useState(false);
  const [categoryRestricted, setCategoryRestricted] = useState(true);
  const [labsRestricted, setlabsRestricted] = useState(true);

  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  useEffect(() => {
    if (props.location) {
      setEditStatus(true);
      // setEditData(props.location.state.data);
    }

    // trackPromise(dispatch(getAllCoursePlans(0, 3000)), loaderType.GENERAL_REQUEST);
    // trackPromise(dispatch(getAllLab(0, 3000)), loaderType.GENERAL_REQUEST);
    trackPromise(
      dispatch(getExamLab(props.location.state.courseid, 0, 3000)),
      loaderType.GENERAL_REQUEST
    );
    // let examids = Examlabs.map((x)=>x._id)
    setEditData({
      ...editData,
      _id: props.location.state.courseid,
      examChallenges: props.location.state.data.examChallenges,
    });
  }, [dispatch]);

  const toggleModal = () => {
    setNotificationModal(!notificationModal);
  };

  const validateCustomStylesForm = async () => {
    let newState = editData;
    if (newState.type === "") {
      newState.typeErr = "invalid";
    } else {
      newState.typeErr = "valid";
    }

    if (newState.validity === "") {
      newState.validityErr = "invalid";
    } else {
      newState.validityErr = "valid";
    }

    if (newState.typeErr === "invalid" || newState.contentErr === "invalid") {
      setEditData({
        ...editData,
        ...newState,
      });
    } else {
      if (editData._id == "") {
        history.push({ pathname: `/allcourse` });
      } else {
        trackPromise(
          dispatch(editCourseExamLabs(editData._id, editData)),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => {
            // setEditData(false)
            history.push({ pathname: `/allcourse` });
          })
          .catch((err) => {});
      }
    }
  };

  const handleLicenseActivation = (e, item) => {
    e.preventDefault();
    trackPromise(
      !item.isActive
        ? dispatch(enableLicenseActivation(item))
        : dispatch(disableLicenseActivation(item)),
      loaderType.GENERAL_REQUEST
    ).then(() => {
      trackPromise(
        dispatch(getAllCoursePlans(0, 300)),
        loaderType.GENERAL_REQUEST
      );
    });
  };

  const capFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = editData;
    newState[name] = value;
    if (value === "") {
      newState[name + "Err"] = "invalid";
    } else {
      newState[name + "Err"] = "valid";
    }
    setEditData({
      ...editData,
      ...newState,
    });
  };

  const categoryIdsHander = useCallback(
    (e, stateName) => {
      let newState = editData;
      let targetValue = e.params.data.id;
      let isExist =
        newState[stateName].findIndex((x) => x === targetValue) >= 0;

      if (isExist) {
        newState[stateName] = newState[stateName].filter(
          (x) => x !== targetValue
        );
      } else {
        newState[stateName].push(targetValue);
      }

      setEditData(newState);
    },
    [editData.allowedCategories, editData.examChallenges]
  );

  const handleConfirm = () => {
    const isSuccess = type === "success";
    dispatch(hideAlert());
    if (isSuccess) {
      // navigateTo();
      setEditStatus(false);
    }
  };

  const test = (data) => {
    //  let examids = Examlabs.map((x)=>x._id)
    //  setEditData({
    //   ...editData,
    //   _id:props.location.state.courseid,
    //   allowedChallenges : examids
    // })
  };

  return (
    <>
      <SimpleHeader name="React Tables" parentName="Tables" />
      {alertVisible && (
        <SweetAlert
          success={type === "success"}
          warning={type === "warning"}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      {!editStatus ? (
        <Container className="" fluid>
          <Row></Row>
        </Container>
      ) : (
        <Container className="" fluid>
          {test(editData)}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Assign Labs To Exam</h3>
                  </CardHeader>
                  <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom07"
                          >
                            labs
                          </label>
                          <Select2
                            className="form-control"
                            multiple
                            value={editData.examChallenges}
                            valid={
                              editData.examChallenges &&
                              editData.examChallenges > 0
                            }
                            onSelect={(e) =>
                              categoryIdsHander(e, "examChallenges")
                            }
                            onUnselect={(e) =>
                              categoryIdsHander(e, "examChallenges")
                            }
                            options={{
                              placeholder: "Please select Category",
                            }}
                            data={(labsRestricted
                              ? labs
                                ? labs
                                : []
                              : editData
                              ? editData.examChallenges
                              : []
                            ).map((x, i) => {
                              return {
                                id: labsRestricted ? x._id : x,
                                text: x.title,
                                value: labsRestricted ? x._id : x,
                              };
                            })}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          validateCustomStylesForm();
                        }}
                      >
                        Add Labs
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      )}
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={notificationModal}
        toggle={toggleModal}
      >
        <div className="modal-body">
          <div className="">
            <h4 className="heading mt-2">
              Are you sure you want to delete? the selected license?
            </h4>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              trackPromise(
                dispatch(deletePlan(planId)),
                loaderType.GENERAL_REQUEST
              );
              toggleModal();
            }}
          >
            Delete
          </Button>
          <Button
            className="text-white"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CourseExamPlans;
