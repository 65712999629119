import React, { useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box, MenuItem, Typography } from "@mui/material";

import { Badge, Card, CardHeader, Container } from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import classNames from "classnames";
import { trackPromise } from "react-promise-tracker";
import { getAllLab } from "actions/adminActions/labsActions";
import { loaderType } from "type";
import { Link } from "react-router-dom";
import { deleteLab } from "actions/adminActions/labsActions";
import moment from "moment";
import { getAllPlans } from "actions/adminActions/plansActions";
import { getCoursesApi } from "actions/adminActions/courseAction";
import { deletePlan } from "actions/adminActions/plansActions";
import { enableLicenseActivation } from "actions/adminActions/plansActions";
import { disableLicenseActivation } from "actions/adminActions/plansActions";

const Plans = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const plans = useSelector((state) => state.plans.plans);
  const [labId, setLabId] = useState(null);
  const [courseLicensesId, setCourseLicensesId] = useState();
  const labs = useSelector((state) => state.labs.labs);

  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });

  useEffect(() => {
    trackPromise(dispatch(getAllPlans(0, 3000)), loaderType.GENERAL_REQUEST);
    trackPromise(dispatch(getCoursesApi(0, 3000)), loaderType.GENERAL_REQUEST);
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        Cell: ({ row }) => (
          <>
            <Typography className="">{row?.original?.name}</Typography>
          </>
        ),
      },
      {
        header: "Allowed Courses",
        accessorKey: "allowedCourses",
        Cell: ({ row }) => (
          <>
            {row?.original?.allowedCourses.map((c) => c.courseName).join(",")}
          </>
        ),
      },
      {
        header: "Allowed Users",
        accessorKey: "allowedUsersCount",
        Cell: ({ row }) => (
          <>
            <Typography className="">
              {row?.original?.allowedUsersCount}
            </Typography>
          </>
        ),
      },
      {
        header: "Active",
        Cell: ({ row }) => (
          <>
            {" "}
            <Badge color="" className="badge-dot mr-4">
              <i
                className={classNames(
                  { "bg-success": row?.original?.isActive },
                  { "bg-warning": !row?.original?.isActive }
                )}
              />
              <span
                className={classNames(
                  "status",
                  { "text-success": row?.original?.isActive },
                  { "text-warning": !row?.original?.isActive }
                )}
              >
                {row?.original?.isActive ? "Yes" : "No"}
              </span>
            </Badge>
          </>
        ),
      },
      {
        header: "Created On",
        accessorKey: "createdAt",
        Cell: ({ row }) => (
          <Typography className="">
            {moment(row?.original?.createdAt).format("DD-MM-YYYY ")}
          </Typography>
        ),
      },
      {
        header: "Validity",
        accessorKey: "validity",
        Cell: ({ row }) => (
          <Typography className="">{row?.original?.validity} days</Typography>
        ),
      },
      {
        header: "License Id",
        Cell: ({ row }) => (
          <Typography className="">{row?.original?._id}</Typography>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };
  const confirmAlert = () => {
    trackPromise(
      dispatch(deletePlan(courseLicensesId)),
      loaderType.GENERAL_REQUEST
    );
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "success",
      message: "Category deleted successfully.",
    });
  };
  const handleLicenseActivation = (e, item) => {
    e.preventDefault();
    trackPromise(
      !item.original.isActive
        ? dispatch(enableLicenseActivation(item?.original))
        : dispatch(disableLicenseActivation(item?.original)),
      loaderType.GENERAL_REQUEST
    ).then(() => {
      trackPromise(dispatch(getAllPlans(0, 3000)), loaderType.GENERAL_REQUEST);
    });
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Course Licenses</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push("/add-course-licenses")}
              >
                <PlusIcon color="white" />
                Add Course Licenses
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={plans || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={() => {
                    history.push(`edit-course-licenses/${row?.original?._id}`);
                  }}
                  sx={{ m: 0 }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={() => {
                    handleDelete();
                    setCourseLicensesId(row?.original?._id);
                  }}
                  sx={{ m: 0 }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={(e) => {
                    handleLicenseActivation(e, row);
                  }}
                  sx={{ m: 0 }}
                >
                  {!row?.original?.isActive
                    ? "Enable License"
                    : "Disable License"}
                  {/* Enable License */}
                </MenuItem>
              </>,
            ]}
          />
        </MuiOverride>
      </Card>
      {alert.status === "warning" && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: "24px",
              }}
            >
              {alert?.message}{" "}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === "success" && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: "24px",
              }}
            >
              {alert?.message}{" "}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default Plans;
