const initState = {
  userStats: {
    labData: [],
    allFreeLabs: [],
  },
};

const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        message: 'User created Successfully!',
      };
    case 'CREATE_USER_ERROR':
      return {
        ...state,
        message: 'User creation failed!',
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        message: 'User updated Successfully!',
      };
    case 'UPDATE_USER_ERROR':
      return {
        ...state,
        message: 'User updation failed!',
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        message: 'User deleted Successfully!',
      };
    case 'DELETE_USER_ERROR':
      return {
        ...state,
        message: 'User deletion failed!',
      };
    case 'FETCH_ALL_USER_SUCCESS':
      return {
        ...state,
        userData: action.data,
        usersErr: false,
      };
    case 'FETCH_ALL_USER_FAILED':
      return {
        ...state,
        userData: [],
        usersErr: action.err,
      };

    case 'FETCH_ADMIN_ADDED_USER_SUCCESS':
      return {
        ...state,
        adminAddeduserData: action.data,
        usersErr: false,
      };
    case 'FETCH_ADMIN_ADDED_USER_FAILED':
      return {
        ...state,
        adminAddeduserData: [],
        usersErr: action.err,
      };

    case 'USER_STATUS_SUCCESS':
      return {
        ...state,
        userData: action.data,
      };
    case 'USER_STATUS_FAILED':
      return {
        ...state,
        userData: [],
        err: action.err,
      };
    case 'USER_INTERVIEWMODE_SUCCESS':
      return {
        ...state,
        userData: action.data,
      };
    case 'USER_INTERVIEWMODE_FAILED':
      return {
        ...state,
        userData: [],
        err: action.err,
      };
    case 'FETCH_USERS_STATISTICS':
      return {
        ...state,
        userStats: {},
      };
    case 'FETCH_USERS_STATISTICS_SUCCESS':
      return {
        ...state,
        userStats: action.data,
      };
    case 'FETCH_FREE_LAB_SUCCESS':
      return {
        ...state,
        allFreeLabs: action.data,
      };
    case 'FETCH_USERS_STATISTICS_FAILED':
      return {
        ...state,
      };
    case 'USER_EXAMMODE_SUCCESS':
      return {
        ...state,
        userData: action.data,
      };
    case 'USER_EXAMMODE_FAILED':
      return {
        ...state,
        userData: [],
        err: action.err,
      };

    default:
      return state;
  }
};

export default usersReducer;
