export default (state = {loggedIn: true, authError: null, user: null}, action) => {
  switch (action.type) {
    // in both cases, we want to tell our app,
    // if the user is logged in or not
    // if the user registers, he will automatically be logged in
    case "register":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        loggedIn: action.payload
      };
    case "login":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        loggedIn: action.payload,
        user: action.data,
        authError: null
      };
    case "login_failed":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        authError: action.err
      };
    case "register_failed":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        registerError: action.err.message
      };
    case "register_org_failed":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        authError2: action.err.message
      };
    case "password_reset_success":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        loggedIn: action.payload,
        authSuccess: "Please check your email to reset your password",
        authError: null
      };

    case "password_reset_error":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        authError: action.err.message,
        authSuccess: null
      };
    case "logout":
      return {
        // keep old state
        ...state,
        // add true/false if the user is or not logged in
        loggedIn: action.payload,
        user: null
      };
    case "TOKEN_EXPIRE":
      return {
        ...state,
        authError: "Session Expired"
      }
    default:
      return state;
  }
};
