import React from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { tokenExpire } from "../actions/loginAction";
import logoutAction from "../actions/logoutAction";

const AuthVerify = (props) => {
    const dispatch = useDispatch()

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };    

    props.history.listen(() => {
        let user = props.user;
        const oneDay = 86400000 // set token expire 1 days in ms
        if (user) {
            const token = user && user.accessToken ? parseJwt(user.accessToken) : null;
            if (token && token.iat && (token.iat * 1000 + oneDay) < Date.now()) {
                dispatch(logoutAction());   
                dispatch(tokenExpire());
                window.location.reload();
            }
        }
    });

    return <div></div>;
};

const mapStateToProps = (state) => {
    return{
      user: state.authState.user,
      isLogin: state.authState.loggedIn,
      authState: state.authState
    }
  };
  
export default connect(mapStateToProps)(withRouter(AuthVerify));