import { useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ReactToPrint from 'react-to-print';
import { Card, CardHeader, Container } from 'reactstrap';
import './PDFPreview.css';

// import AiOutlineUser fom "AiOutlineUser/ai"
import classNames from 'classnames';
import moment from 'moment';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FaCircle } from 'react-icons/fa';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import OverviewChart from './OverviewChart';
import { getExamResult } from 'actions/adminActions/examsAction';

const PDFPreview = ({ profile }) => {
  const { id } = useParams();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const examResult = useSelector((state) => state?.examlabs?.examResult?.data);
  useEffect(() => {
    dispatch(getExamResult(id));
  }, [dispatch]);
 const calculate = () => {
   return (
     ((examResult?.score || 0) / (examResult?.totalScore || 0)) *
     100
   ).toFixed(2);
 };
  const validAnswer = (item) => {
    let validCount = 0;
    item?.questions.forEach((i) => {
      if (i?.isValid) {
        validCount += 1;
      }
    });
    return validCount === item?.questions ? 1 : 0;
  };
  return (
    <Container fluid>
      <Card className="d-block w-100">
        <div className="download-wrap p-4 d-flex justify-content-between align-items-center pb-3">
          <h5 className="org_page_title">Report Summary</h5>

          {/* <ReactToPrint
            trigger={() => (
              <button className="common-primary-btn">Download</button>
            )}
            content={() => componentRef.current}
          /> */}
        </div>
        <div ref={componentRef} className="p-4 d-block w-100">
          <>
            <div className="d-flex justify-content-start mb-4">
              <img
                alt={''}
                className="user-brand-logo"
                src={
                  profile?.profilePicUrl ||
                  require('assets/img/brand/Upskillr.png')
                }
              />
            </div>

            <CardHeader className="p-0 d-flex justify-content-between flex-wrap gap-5 my-3">
              <div className="d-flex flex-column justify-content-start gap-2">
                <span className="student-name">
                  {examResult?.createdBy?.name}
                </span>

                <div className="report-exam-details-wrapper d-flex align-items-start gap-5">
                  <div className="d-flex flex-column justify-content-start">
                    <span className="report-exam-details-label">Exam</span>
                    <span className="report-exam-details-info report-exam-name">
                      {examResult?.scheduledExamId?.name}.
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <span className="report-exam-details-label">Date</span>
                    <span className="report-exam-details-info">
                      {moment(examResult?.createdAt).format('DD MMM YYYY')}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <span className="report-exam-details-label">Time</span>
                    <span className="report-exam-details-info">
                      {examResult?.examTime} /{' '}
                      {examResult?.scheduledExamId?.duration}{' '}
                      <span className="report-exam-details-label-min">
                        {' '}
                        Min{' '}
                      </span>{' '}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <span className="report-exam-details-label">Score</span>
                    <span className="report-exam-details-info">
                      {examResult?.score || 0} / {examResult?.totalScore || 0}{' '}
                      <span className="report-exam-details-label-min">
                        {' '}
                        Points{' '}
                      </span>{' '}
                    </span>
                  </div>
                </div>
              </div>
              <div className="result-progress-wrapper ">
                <CircularProgressbar
                  value={calculate()}
                  strokeWidth={5}
                  text={`${calculate()}%`}
                  styles={buildStyles({
                    pathColor: 'var(--primary)',
                    textColor: 'var(--primary)',
                    trailColor: 'var(--lighter)',
                    background: '#fff',
                  })}
                />
              </div>
            </CardHeader>
            <div className="my-4">
              <OverviewChart examResult={examResult} />
            </div>

            <div className="question-wrapper">
              {examResult?.mcqQuestionsAnswers?.length ? (
                <>
                  {examResult?.mcqQuestionsAnswers.map((item, index) => (
                    <div className="question-box">
                      <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                        <span className="question-name">
                          {' '}
                          Question {index + 1} : {item?.questionId?.question}{' '}
                        </span>
                        <div className="d-flex flex-column align-items-center gap-1">
                          <span className="report-exam-details-label">
                            Score
                          </span>
                          <div className="d-flex align-items-end gap-1">
                            <span className="get-score">
                              {item.isValid ? item?.marks : 0}
                            </span>
                            <span className="total-score">/</span>
                            <span className="total-score"> {item?.marks} </span>
                          </div>
                        </div>
                      </div>

                      {item?.questionId?.options?.length ? (
                        <>
                          {item?.questionId?.options.map((option, index) => (
                            <div className="d-flex p-2 gap-2 ">
                              {option === item?.answer ? (
                                <>
                                  {option === item?.answer && !item.isValid && (
                                    <span className="d-flex align-items-center">
                                      <IoCloseCircleOutline className="wrong-ans" />
                                    </span>
                                  )}
                                  {option === item?.answer && item.isValid && (
                                    <span className="d-flex align-items-center">
                                      <IoCheckmarkCircleOutline className="right-ans" />
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.answer &&
                                  !item.isValid &&
                                  item?.questionId?.answer?.answers.includes(
                                    option
                                  ) ? (
                                    <span className="d-flex align-items-center">
                                      <IoCheckmarkCircleOutline className="right-ans" />
                                    </span>
                                  ) : (
                                    <span className="d-flex align-items-center">
                                      <FaCircle className="info-ans" />
                                    </span>
                                  )}
                                </>
                              )}

                              <span
                                className={classNames({
                                  'd-flex align-items-center gap-3 exam-options': true,
                                  'danger-text':
                                    option === item?.answer && !item.isValid,
                                  'success-text':
                                    item?.answer === option
                                      ? item?.questionId?.answer?.answers.includes(
                                          item?.answer
                                        )
                                      : '',
                                })}
                              >
                                {option.includes('https://upskillr') ? (
                                  <img
                                    style={{ width: '100px' }}
                                    src={option}
                                  />
                                ) : (
                                  <> {option}</>
                                )}
                              </span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="question-wrapper">
              {examResult?.challengeQuestionsAnswers?.length ? (
                <>
                  {examResult?.challengeQuestionsAnswers.map((item, index) => (
                    <div className="question-box">
                      <span className="exam-challenge">Challenge</span>

                      <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                        <span className="question-name">
                          {' '}
                          Question{' '}
                          {examResult?.mcqQuestionsAnswers.length +
                            index +
                            1} : {item?.challengeId?.title}{' '}
                        </span>
                        <div className="d-flex flex-column align-items-center gap-1">
                          <span className="report-exam-details-label">
                            Score
                          </span>
                          <div className="d-flex align-items-end gap-1">
                            <span className="get-score">
                              {validAnswer(item)}
                            </span>
                            <span className="total-score">/</span>
                            <span className="total-score"> {1} </span>
                          </div>
                        </div>
                      </div>

                      {item?.questions?.length ? (
                        <>
                          {item?.questions.map((question, index) => (
                            <span className="d-flex flex-column gap-1 challenge-question-wrapper">
                              <div className="d-flex align-items-center gap-2">
                                <span className="challenge-question-tag">
                                  Q{index + 1}{' '}
                                </span>
                                <span className="question-name">
                                  {' '}
                                  {question?.question}
                                </span>
                              </div>
                              {question?.answer ? (
                                <div className="d-flex">
                                  <span
                                    className={classNames({
                                      'pl-5 challenge-exam-answer': true,
                                      'challenge-exam-answer-invalid':
                                        !question.isValid,
                                      'challenge-exam-answer-valid':
                                        question.isValid,
                                    })}
                                  >
                                    {question?.answer}
                                  </span>
                                </div>
                              ) : (
                                <span className="challenge-exam-no-answer pl-5">
                                  Answer not given...
                                </span>
                              )}
                            </span>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        </div>
      </Card>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.authState?.profile,
  };
};

export default connect(mapStateToProps)(PDFPreview);
