import { Modal } from "@material-ui/core";
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import BeatLoader from "react-spinners/BeatLoader";

const LoadingIndicator = props => {
   const { promiseInProgress } = usePromiseTracker({area: props.area});
   return (     
     <Modal open={promiseInProgress} BackdropProps={{ style: { opacity: 0.1 } }} >
      <div className="loading-center">
        <div className="sweet-loading">
          <BeatLoader
            size={15}
            color={props.color ? props.color : "#2c3e50"}
          />
        </div>
      </div>
     </Modal>
  );
 }

 export default LoadingIndicator;
