const initState = {};

const labsReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_CHALLENGE_SUCCESS":
      return {
        ...state,
        message: "Challenge created Successfully!",
      };
    case "CREATE_CHALLENGE_ERROR":
      return {
        ...state,
        message: "Challenge creation failed!",
      };
    case "UPDATE_CHALLENGE_SUCCESS":
      return {
        ...state,
        message: "Challenge updated Successfully!",
      };
    case "UPDATE_CHALLENGE_ERROR":
      return {
        ...state,
        message: "Challenge updation failed!",
      };
    case "DELETE_CHALLENGE_SUCCESS":
      return {
        ...state,
        labs: action.data,
        message: "Challenge deleted Successfully!",
      };
    case "DELETE_CHALLENGE_ERROR":
      return {
        ...state,
        message: "Challenge deletion failed!",
      };
    case "GET_ALL_LAB_SUCCESS":
      return {
        ...state,
        labs: action.data,
        message: "Lab fetch Successfully!",
      };
    case "GET_ALL_LAB_FAILED":
      return {
        ...state,
        labs: [],
        message: action.err,
      };
    case "GET_EXAM_LAB_SUCCESS":
      return {
        ...state,
        examLabs: action.data,
        message: "Lab fetch Successfully!",
      };
    case "GET_EXAM_LAB_FAILED":
      return {
        ...state,
        examLabs: [],
        message: action.err,
      };
    case "GET_SINGLE_CHALLENGE_SUCCESS":
      return {
        ...state,
        labData: action.data,
        message: action.err,
      };
    case "GET_SINGLE_CHALLENGE_ERROR":
      return {
        ...state,
        labData: {},
        message: action.err,
      };
    default:
      return state;
  }
};

export default labsReducer;
