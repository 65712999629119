const initState = {
  examlabs: [],
};

const examReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_EXAM_LAB_SUCCESS":
      return {
        ...state,
        examlabs: action.data.map((x) => x._id),
        message: "Exam Labs fetch Successfully!",
      };
    case "GET_EXAM_LAB_FAILED":
      return {
        ...state,
        examlabs: [],
        message: action.err,
      };
      case "FETCH_EXAM_RESULT_SUCCESS":
      return {
        ...state,
        examResult: action?.data?.data,
      };
    case "FETCH_EXAM_RESULT_FAILED":
      return {
        ...state,
        examResult: {},
      };
    case "FETCH_ALLEXAM_SUCCESS":
      return {
        ...state,
        allExams: action.data,
      };
    case "FETCH_SINGLE_EXAM_SUCCESS":
      return {
        ...state,
        singleUserExams: action.data,
      };
    case "FETCH_SINGLE_EXAM_FAILED":
      return {
        ...state,
        singleUserExams: [],
      };
    case "FETCH_MYEXAM_SUCCESS":
      return {
        ...state,
        myExams: action?.data?.data?.list,
      };
    case "FETCH_EXAM_RESULT_SUCCESS":
      return {
        ...state,
        examResult: action?.data?.data,
      };

    case "FETCH_EXAM_RESULT_FAILED":
      return {
        ...state,
        examResult: {},
      };
    case "FETCH_ALLEXAM_FAILED":
      return {
        ...state,
        allExams: [],
      };
    case "FETCH_MYEXAM_FAILED":
      return {
        ...state,
        myExams: [],
      };
    default:
      return state;
  }
};

export default examReducer;
