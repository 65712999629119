import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import OrgLogo from "../../../assets/img/brand/Upskillr.png";
import { loginAction } from "actions/loginAction";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import AuthHeader from "components/Headers/AuthHeader.jsx";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.props.loginAction(this.state.email, this.state.password);
    }
  };

  render() {
    const { profile, authError, history } = this.props;
    const { location } = history;
    let redirectPath;
    if (location && location.state && location.state.redirectPath) {
      redirectPath = location.state.redirectPath;
    }

    if (profile && !redirectPath) {
      if (profile.accessToken && profile.userType === "admin") {
        return <Redirect to="/users" />;
      } else {
        return null;
      }
    } else if (profile && redirectPath) {
      if (redirectPath === "/") {
        // return <Redirect to={"/categories"} />
        return <Redirect to={"/users"} />;
      }
      return <Redirect to={redirectPath} />;
    }

    return (
      <>
        {/* <AuthHeader/> */}
        <div className="">
          <div className="login-wrapper">
            <Card className="bg-white border-0 mb-0 card-border">
              {/*<CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    {/*<Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={require("assets/img/icons/common/github.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Github</span>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#"
                      onClick={() =>
                      this.props.loginWithGoogle()
                      }
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={require("assets/img/icons/common/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                </CardHeader>*/}
              <CardBody className="px-lg-4 py-lg-4">
                <div className="d-flex align-items-center justify-content-center my-3">
                  <img src={OrgLogo} alt="" className=" org-logo" />
                </div>
                <div className="text-center text-muted mb-4">
                  <small>Sign in with credentials</small>
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => this.setState({ focusedEmail: true })}
                        onBlur={() => this.setState({ focusedEmail: false })}
                        onChange={(e) => this.onChange("email", e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: this.state.focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onKeyPress={this.keyPressed}
                        onFocus={() => this.setState({ focusedPassword: true })}
                        onBlur={() => this.setState({ focusedPassword: false })}
                        onChange={(e) =>
                          this.onChange("password", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className=""
                      color="default"
                      type="button"
                      onClick={() =>
                        this.props.loginAction(
                          this.state.email,
                          this.state.password
                        )
                      }
                    >
                      Sign in
                    </Button>
                    <span className="text-danger">
                      {authError ? <p>{authError}</p> : null}
                    </span>
                  </div>
                </Form>
              </CardBody>
            </Card>
            {/* <Row className="mt-3">
                <Col xs="6">
                <Link className="text-light" to="/a/forgot">
                  <small>Forgot Password?</small>
                </Link>
                </Col>
                <Col className="text-right" xs="6">
                  <Link className="text-light" to="/a/register">
                    <small>Create new account</small>
                  </Link>
                </Col>
              </Row> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.authState.authError,
    profile: state.authState.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
