import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { useHistory, useParams } from "react-router";

import { hideAlert, showAlert } from "actions/templateAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "../../../type";
import Select2 from "react-select2-wrapper";
import { Checkbox } from "@material-ui/core";
import Select from "react-select";
import { getSingleLicense } from "actions/adminActions/licenseActions";
import { createPlan } from "actions/adminActions/plansActions";
import { editPlan } from "actions/adminActions/plansActions";
import { getCoursesApi } from "actions/adminActions/courseAction";
import { getAllPlans } from "actions/adminActions/licenseActions";

const typeList = [
  {
    label: "Trial",
    value: "trial"
  },
  {
    label: "Standard",
    value: "standard"
  },
  {
    label: "Full",
    value: "full"
  }
]
const initialState = {
  name: '',
  allowedUsersCount: null,
  type: '',
  typeErr: null,
  validity: '',
  validityErr: null,
  isCategoryViewRestricted: true,
  allowedCourse: [],
};
const AddCourseLicenses = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);
  console.log('initialState', initialState);
  const [state, setState] = useState({ ...initialState });
  const courses = useSelector((state) => state.courses.courses);
  const [courseRestricted, setCourseRestricted] = useState(true);
  const licenseData = useSelector((state) => state?.plans?.singleLicense);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    trackPromise(dispatch(getAllPlans(0, 3000)), loaderType.GENERAL_REQUEST);
  }, [dispatch]);
  useEffect(() => {
    const allCourseArray = courseRestricted
      ? courses
      : state
      ? state.allowedCourse
      : [];
    const option =
      allCourseArray.length &&
      allCourseArray.map((x) => {
        return {
          label: x.courseName,
          value: courseRestricted ? x._id : x,
          // id: courseRestricted ? x._id : x,
          // text: x.courseName,
          // value: courseRestricted ? x._id : x,
        };
      });
    setOptions(option);
  }, [courses]);
  useEffect(() => {
    const license = { _id: id };
    trackPromise(dispatch(getSingleLicense(license), 1000));
  }, []);
  useEffect(() => {
    if (id) {
      setState({
        name: licenseData?.name,
        type: licenseData?.type,
        validity: licenseData?.validity,
        allowedUsersCount: licenseData?.allowedUsersCount,
        allowedCourse: licenseData?.allowedCourses?.map((item) => item?._id),
      });
    }
  }, [licenseData]);

  const validateCustomStylesForm = async () => {
    let newState = state;
    if (newState.type === '') {
      newState.typeErr = 'invalid';
    } else {
      newState.typeErr = 'valid';
    }

    if (newState.validity === '') {
      newState.validityErr = 'invalid';
    } else {
      newState.validityErr = 'valid';
    }

    if (newState.typeErr === 'invalid' || newState.contentErr === 'invalid') {
      setState({
        ...state,
        ...newState,
      });
    } else {
      if (!id) {
        console.log('state', state);
        trackPromise(dispatch(createPlan(state)), loaderType.GENERAL_REQUEST)
          .then((res) => {
            setState(initialState);
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err));
          });
      } else {
        trackPromise(dispatch(editPlan(id, state)), loaderType.GENERAL_REQUEST)
          .then((res) => {
            setState(initialState);
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err));
          });
      }
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = state;
    newState[name] = value;
    if (value === '') {
      newState[name + 'Err'] = 'invalid';
    } else {
      newState[name + 'Err'] = 'valid';
    }
    setState({
      ...state,
      ...newState,
    });
  };

  const navigateTo = () =>
    type === 'success' ? history.push('/licenses') : null;

  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo();
    }
  };

  const categoryIdsHandler = (newValue) => {
    setState({
      ...state,
      allowedCourse: newValue.map((i) => i.value),
    });
    // let newState = state;
    // let targetValue = e.params.data.id;
    // let isExist = newState[stateName].findIndex((x) => x === targetValue) >= 0;

    // if (isExist) {
    //   newState[stateName] = newState[stateName].filter(
    //     (x) => x !== targetValue
    //   );
    // } else {
    //   newState[stateName].push(targetValue);
    // }
    // setState(newState);
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {id ? 'Edit' : 'Add'} Course License
                      </h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/licenses')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={() => {
                          validateCustomStylesForm();
                        }}
                      >
                        {id ? 'Save' : 'Add'} License
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <Row>
                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Name
                          </label>
                          <Input
                            value={state.name}
                            name="name"
                            className="org_form_input"
                            placeholder="License Name"
                            type="text"
                            valid={state.name && state.name.length > 0}
                            invalid={state.name && state.name.length < 0}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Total Allowed User's
                          </label>
                          <Input
                            value={state.allowedUsersCount}
                            name="allowedUsersCount"
                            className="org_form_input"
                            placeholder="0"
                            type="number"
                            valid={
                              state.allowedUsersCount &&
                              state.allowedUsersCount >= 0
                            }
                            invalid={
                              state.allowedUsersCount &&
                              state.allowedUsersCount < 0
                            }
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Validity
                          </label>
                          <Input
                            value={state.validity}
                            name="validity"
                            className="org_form_input"
                            placeholder="Validity"
                            type="number"
                            valid={state.validityErr === 'valid'}
                            invalid={state.validityErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="7">
                        {/* <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Type
                          </label>
                          <Input
                            value={state.type}
                            name="type"
                            className="org_form_input"
                            placeholder="Type"
                            type="select"
                            valid={state.typeErr === "valid"}
                            invalid={state.typeErr === "invalid"}
                            onChange={customStylesForm}
                          >
                            <option value="" selected disabled>
                              Select Type
                            </option>
                            <option value="trial">Trial</option>
                            <option value="standard">Standard</option>
                            <option value="full">Full</option>
                          </Input>
                        </FormGroup> */}
                      </Col>
                      {/* <FormGroup className="">
                        <label htmlFor="" className="org_form_label my-2 mx-0">
                          Type
                        </label>
                        <Select
                          className="form-control"
                          options={typeList}
                          value={
                            state.type &&
                            typeList.find(
                              (i) => i.value === state?.type
                            )
                          }
                          onChange={(newValue) => {
                            setState({
                              ...state,
                              type: newValue?.value
                            })
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: 45,
                            }),
                          }}
                        />
                      </FormGroup> */}
                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Type
                          </label>
                          <Select
                            className=""
                            options={typeList}
                            name="type"
                            placeholder="Type"
                            value={
                              state.type &&
                              typeList.find((i) => i.value === state?.type)
                            }
                            valid={state.typeErr === 'valid'}
                            invalid={state.typeErr === 'invalid'}
                            onChange={(newValue) => {
                              setState({
                                ...state,
                                type: newValue?.value,
                              });
                            }}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: '45px',
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Courses
                          </label>
                          <Select
                            isMulti
                            className=""
                            options={options}
                            name="type"
                            placeholder="Type"
                            value={
                              state?.allowedCourse?.length &&
                              options.filter((i) =>
                                state?.allowedCourse.includes(i.value)
                              )
                            }
                            valid={state.typeErr === 'valid'}
                            invalid={state.typeErr === 'invalid'}
                            onChange={(newValue) => {
                              categoryIdsHandler(newValue);
                            }}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: '45px',
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Category
                          </label>
                          <Select
                            className="form-control"
                            isMulti
                            // value={state.allowedCourse}
                            valid={state?.allowedCourse?.length > 0}
                            onSelect={(e) =>
                              categoryIdsHandler(e, "allowedCourse")
                            }
                            options={options}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col md="7">
                        <span>Show all courses </span>
                        <Checkbox
                          checked={courseRestricted}
                          onChange={(e) => {
                            setCourseRestricted(e.target.checked);
                          }}
                        />
                      </Col>
                    </Row>
                    {/* <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        validateCustomStylesForm();
                      }}
                    >
                      Add License
                    </Button> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddCourseLicenses;
