const initState = {};

const organisationsReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_ORGANISATION_SUCCESS":
      return {
        ...state,
        message: "Organisation created Successfully!",
      };
    case "CREATE_ORGANISATION_ERROR":
      return {
        ...state,
        message: "Organisation creation failed!",
      };
    case "UPDATE_ORGANISATION_SUCCESS":
      return {
        ...state,
        message: "Organisation updated Successfully!",
      };
    case "UPDATE_ORGANISATION_ERROR":
      return {
        ...state,
        message: "Organisation updation failed!",
      };
    case "DELETE_ORGANISATION_SUCCESS":
      return {
        ...state,
        message: "Organisation deleted Successfully!",
      };
    case "DELETE_ORGANISATION_ERROR":
      return {
        ...state,
        message: "Organisation deletion failed!",
      };
    case "FETCH_ALL_ORG_SUCCESS":
      return {
        ...state,
        orgData: action.data,
        orgErr: false,
      };
    case "FETCH_ALL_ORG_FAILED":
      return {
        ...state,
        orgData: [],
        orgErr: action.err,
      };
    case "FETCH_ORGUSERS_SUCCESS":
      return {
        ...state,
        orgUser: action.data,
        orgUsersErr: false,
      };
    case "FETCH_ORGUSERS_FAILED":
      return {
        ...state,
        orgUser: [],
        orgUsersErr: action.err,
      };
    case "ORG_STATUS_SUCCESS":
      return {
        ...state,
        userData: action.data,
      };
    default:
      return state;
  }
};

export default organisationsReducer;
