export const showAlert = (visible, type, msg) => ({
    type: 'SHOW_ALERT',
    payload: {
        visible,
        type, 
        msg
    }
})

export const hideAlert = (visible, type, msg) => ({
    type: 'HIDE_ALERT',
    payload: {
        visible: false,
        type: null, 
        msg: ""
    }
})
