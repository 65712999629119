const initialState = {
    customLabs: [],
}

const customChallengesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "GET_CUSTOM_CHALLENGES_SUCCESS":
            return { ...state, ...payload }
        case "GET_CUSTOM_CHALLENGES_FAILED":
            return { ...state, ...payload }

        default:
            return state
    }
}

export default customChallengesReducer;