import classnames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import Col from "reactstrap/lib/Col";

export const CardProfile = () => {
  const data = useSelector((state) => state?.usersProfile);
  return (
    <div className="col-xl-12 p-0">
      <div className="card card-profile">
        <div className="card-body pt-0">
          <div
            className="p-2 w-100"
            // style={{ display: "flex", flex: 1, justifyContent: "center" }}
          >
            <div className="card-profile-img-wrapper">
              <img
                src={
                  data?.profilePicUrl
                    ? data.profilePicUrl
                    : require('assets/img/theme/blank-profile.png')
                }
                className="avatar-xxl avatar"
                alt="..."
              />
            </div>
          </div>
          <div className="text-center my-2">
            <h4 className="card-profile-name">{data?.name}</h4>
            <div className="h6 font-weight-400">
              <Col>{data?.email}</Col>
              <Col>
                {data?.address ? data.address + ',' : ''}{' '}
                {data?.city ? data.city + ',' : ''} {data?.country}
              </Col>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center gap-4 my-4 ">
              <div className="h6 text-muted">
                Status:{' '}
                <span
                  className={classnames(
                    'status badge rounded-pill',
                    { 'text-white bg-success': data?.isActive },
                    { 'text-white bg-danger': !data?.isActive }
                  )}
                >
                  {data?.isActive ? 'Enabled' : 'Disabled'}
                </span>
              </div>

              <div className="h6 text-muted">
                Solutions:{' '}
                <span
                  className={classnames(
                    'status badge rounded-pill',
                    { 'text-white bg-success': !data?.isExamMode },
                    { 'text-white bg-danger': data?.isExamMode }
                  )}
                >
                  {!data?.isExamMode ? 'Enabled' : 'Disabled'}
                </span>
              </div>

              <div>
                <span className="h6 text-muted">Last Login: </span>
                <span className="h6">
                  {data.lastLogin &&
                    moment(data?.lastLogin).format(
                      'dddd, MMM DD, YYYY hh:mm:ss a'
                    )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
