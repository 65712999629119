import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 2 });

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
    }
    return Promise.reject(error);
  }
);
//
// export default axios.create({
//   baseURL: "http://10.10.11.232:3000/v1/"
// });

// export default axios.create({
//   baseURL: "https://vcr-debug-cloud-backend.ddnsfree.com/backend1/v1/"
// });

export default axios.create({
  // baseURL: "https://vcrbeta-backend.ddnsfree.com/backend1/v1/"
  // baseURL: "http://localhost:3000/v1",
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
