const initState = {
  questionCategories:[],
  categories:[]
};

const categoriesReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_CATEGORY_SUCCESS":
      return {
        ...state,
        message: "Category created Successfully!",
      };
    case "CREATE_CATEGORY_ERROR":
      return {
        ...state,
        message: "Category creation failed!",
      };
    case "UPDATE_CATEGORY_SUCCESS":
      return {
        ...state,
        message: "Category updated Successfully!",
      };
    case "UPDATE_CATEGORY_ERROR":
      return {
        ...state,
        message: "Category updation failed!",
      };
    case "DELETE_CATEGORY_SUCCESS":
      return {
        ...state,
        notificationModal: false,
        categories: action.data,
        message: "Category deleted Successfully!",
      };
    case "DELETE_CATEGORY_ERROR":
      return {
        ...state,
        message: "Category deletion failed!",
      };
    case "GET_ALL_CATEGORY_SUCCESS":
      return {
        ...state,
        categories: action.data,
        message: "Category fetch Successfully!",
      };
    case "GET_ALL_COURSE_CATEGORY_SUCCESS":
      return {
        ...state,
        courseCategory: action.data,

        message: "Course Category fetch Successfully",
      };
    case "GET_ALL_COURSE_CATEGORY_FAILED":
      return {
        ...state,
        courseCategory: [],
        message: action.err,
      };
    case "GET_ALL_QUESTION_CATEGORY_SUCCESS":
      return {
        ...state,
        questionCategories: action.data,
        message: "question Category fetch Successfully",
      }
    case "GET_ALL_CATEGORY_FAILED":
      return {
        ...state,
        questionCategories: [],
        message: action.err,
      };
    case "EDITABLE_CATEGORY":
      return {
        ...state,
        catEdit: action.data,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
