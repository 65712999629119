import React from "react";

export default function ScreenIcon({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3335 15.9795H2.66683C1.91683 15.9795 1.3335 15.3962 1.3335 14.6462V3.97949H4.00016V13.3128H20.0002V3.97949H22.6668V14.6462C22.6668 15.3962 22.0835 15.9795 21.3335 15.9795Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666667 1.3125H23.3333C23.7083 1.3125 24 1.60417 24 1.97917V3.3125C24 3.6875 23.7083 3.97917 23.3333 3.97917H0.666667C0.291667 3.97917 0 3.6875 0 3.3125V1.97917C0 1.60417 0.291667 1.3125 0.666667 1.3125ZM13.3333 15.9792V17.4375L16.4583 20.5625C16.7083 20.8125 16.7083 21.2708 16.4583 21.5208L15.5417 22.4375C15.25 22.7292 14.8333 22.7292 14.5833 22.4375L12 19.8542L9.41667 22.4375C9.16667 22.7292 8.70833 22.7292 8.45833 22.4375L7.54167 21.5208C7.25 21.2708 7.25 20.8125 7.54167 20.5625L10.6667 17.4375V15.9792H13.3333Z"
        fill={color}
      />
    </svg>
  );
}
