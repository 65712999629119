import { combineReducers } from "redux";
const initialState = {
    addCourseData: {
        whatYouWillLearn: [""],
        requirements: [""],
        courseContents: [
            {
                _id:0,
                title: "",
                contents: [
                    {
                        _id:0,
                        title: '',
                        type: "video",
                        link: "",
                        duration: 0
                    }

                ]
            }
        ]
    }
}

const addCourseReducers = (state = initialState, action) => {

    switch (action.type) {
        case "ADD_COURSE_DATA":
            return { ...state, addCourseData: action.addCourse };
    }
}
export default addCourseReducers
