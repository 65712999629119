import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleHeader from 'components/Headers/SimpleHeader.jsx';

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  FormGroup,
} from 'reactstrap';
import {
  createCategory,
  editCategory,
} from 'actions/adminActions/categoriesActions';
import { useHistory, useParams } from 'react-router';
import { hideAlert } from 'actions/templateAction';
import SweetAlert from 'react-bootstrap-sweetalert';
import { loaderType } from 'type';
import { trackPromise } from 'react-promise-tracker';
import InfoIcon from 'iconComponents/InfoIcon';
import NewCustomDropzon from '../../../components/Common/NewCustomDropzon';
import classNames from 'classnames';
import api2 from 'api2';
import axios from 'axios';
import CategoriesAltImg from '../../../assets/img/brand/categories.png';
import Select from 'react-select';
import { Checkbox } from '@mui/material';

const AddCategory = (props) => {
  const history = useHistory();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const [categoriesList, setCategoriesList] = useState([]);
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [showBannerImage, setShowBannerImage] = useState(true);
  const [showIconImage, setShowIconImage] = useState(true);

  const [categoryType, setCategoryType] = useState([
    {
      label: 'Category',
      value: 'category',
    },
    {
      label: 'Sub Category',
      value: 'sub-category',
    },
  ]);
  const [categorySubType, setCategorySubType] = useState([
    {
      label: 'Attack Lab',
      value: 'attack',
    },
    {
      label: 'Certification Lab',
      value: 'certification',
    },
  ]);

  const [state, setState] = useState({
    fullName: '',
    fullNameErr: null,
    shortName: '',
    shortNameErr: null,
    path: '',
    pathErr: null,
    subTitle: '',
    subTitleErr: null,
    type: '',
    typeErr: null,
    subType: '',
    subTypeErr: null,
    description: '',
    descriptionErr: null,
    bannerImage: null,
    iconImage: null,
    mainCategoryId: null,
    score: 0,
    isPublic: false,
  });

  useEffect(() => {
    const categoryFormat = [];
    categories.map((item) => {
      if (item?.type === 'category') {
        categoryFormat.push({
          label: item?.fullName,
          value: item?._id,
        });
      }
    });
    setCategoriesList(categoryFormat);
  }, [categories]);

  const fetchCourseCategory = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      let result = await api2.get(`api/category/${_id}`, config);
      let category = result?.data?.data;
      console.log('category?.subTitle', category?.subTitle);
      let resultObj = {
        fullName: category?.fullName,
        shortName: category?.shortName,
        path: category?.path,
        subTitle: category?.subTitle,
        type: category?.type,
        subType: category?.subType,
        description: category?.description,
        bannerImage: category?.bannerImage,
        iconImage: category?.iconImage,
        mainCategoryId: category?.mainCategoryId,
        score: category?.score,
        isPublic: category?.isPublic,
      };
      setState({
        ...state,
        ...resultObj,
      });
      return;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };

  useEffect(() => {
    fetchCourseCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  const validateCustomStylesForm = async () => {
    let newState = state;
    if (newState.fullName === '') {
      newState.fullNameErr = 'invalid';
    } else {
      newState.fullNameErr = 'valid';
    }

    if (newState.shortName === '') {
      newState.shortNameErr = 'invalid';
    } else {
      newState.shortNameErr = 'valid';
    }

    if (newState.path === '') {
      newState.pathErr = 'invalid';
    } else {
      newState.pathErr = 'valid';
    }

    if (newState.type === false) {
      newState.typeErr = 'invalid';
    } else {
      newState.typeErr = 'valid';
    }

    if (newState.description === false) {
      newState.descriptionErr = 'invalid';
    } else {
      newState.descriptionErr = 'valid';
    }
    if (
      newState.fullNameErr === 'invalid' ||
      newState.shortNameErr === 'invalid' ||
      newState.pathErr === 'invalid' ||
      newState.typeErr === 'invalid' ||
      newState.descriptionErr === 'invalid'
    ) {
      setState({
        ...state,
        ...newState,
      });
    } else {
      if (_id) {
        if (typeof state?.bannerImage === 'string') {
          delete state?.bannerImage;
        }
        if (typeof state?.iconImage === 'string') {
          delete state?.iconImage;
        }
        console.log('state', state);
        trackPromise(
          dispatch(editCategory(_id, state)),
          loaderType.GENERAL_REQUEST
        );
      } else {
        trackPromise(
          dispatch(createCategory(state)),
          loaderType.GENERAL_REQUEST
        );
      }
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = state;
    newState[name] = value;
    if (value === '') {
      newState[name + 'Err'] = 'invalid';
    } else {
      newState[name + 'Err'] = 'valid';
    }
    setState({
      ...state,
      ...newState,
    });
  };

  const navigateTo = () =>
    type === 'success' ? history.push('/categories') : null;

  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo();
    }
  };

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }
  const handleUpload = (file, name) => {
    if (file) {
      const fileType = file?.name.split('.').pop().toLowerCase();

      // Check if the file is of valid type
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        // Create an object URL for the image preview
        const filePreview = URL.createObjectURL(file);

        // Update the state with the file object and its preview URL
        setState({
          ...state,
          [name]: {
            file, // Actual file to be uploaded
            preview: filePreview, // Preview URL for display
          },
        });
      } else {
        console.error(
          'Invalid file type. Only PNG, JPG, and JPEG are allowed.'
        );
      }
    }
  };

  const hideBannerImg = (event) => {
    setShowBannerImage(false);
  };

  const hideIconImg = () => {
    setShowIconImage(false);
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {_id ? 'Edit' : 'Add'} Category
                      </h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/categories')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={() => {
                          validateCustomStylesForm();
                        }}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate>
                    <Row className="">
                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Full Name
                          </label>
                          <Input
                            value={state.fullName}
                            name="fullName"
                            autoComplete="off"
                            className="org_form_input"
                            placeholder="Full Name"
                            type="text"
                            valid={state.fullNameErr === 'valid'}
                            invalid={state.fullNameErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Short Name
                          </label>
                          <Input
                            value={state.shortName}
                            name="shortName"
                            autoComplete="off"
                            className="org_form_input"
                            placeholder="Short Name"
                            type="text"
                            valid={state.shortNameErr === 'valid'}
                            invalid={state.shortNameErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Sub title
                          </label>
                          <Input
                            value={state.subTitle}
                            name="subTitle"
                            autoComplete="off"
                            className="org_form_input"
                            placeholder="Sub Title"
                            type="text"
                            valid={state.subTitleErr === 'valid'}
                            invalid={state.subTitleErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Path
                          </label>
                          <Input
                            value={state.path}
                            name="path"
                            autoComplete="off"
                            className="org_form_input"
                            placeholder="Path"
                            type="text"
                            valid={state.pathErr === 'valid'}
                            invalid={state.pathErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-md-12">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Description
                          </label>
                          <Input
                            value={state.description}
                            name="description"
                            autoComplete="off"
                            className="org_form_input"
                            placeholder="Description"
                            type="textarea"
                            valid={state.descriptionErr === 'valid'}
                            invalid={state.descriptionErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="add_course_form_label my-2 mx-0">
                            Banner Image
                          </label>

                          <NewCustomDropzon
                            name="bannerImage"
                            accept="image/jpeg, image/jpg, image/png"
                            handleUpload={handleUpload}
                            type="img"
                          />

                          <div className="d-flex gap-2 my-2">
                            <InfoIcon color={'#070707'} />
                            <span
                              className={classNames({
                                add_course_form_sublabel: true,
                              })}
                            >
                              File formats should be PNG, JPEG or JPG, Max 10MB
                            </span>
                          </div>

                          {state?.bannerImage && (
                            <div className="add_course_form_img_preview_wrapper">
                              <div className="d-flex align-items-center gap-3">
                                {/* {showBannerImage ? ( */}
                                <img
                                  src={
                                    (state?.bannerImage?.preview &&
                                      state?.bannerImage?.preview) ||
                                    state?.bannerImage
                                  }
                                  className="add_course_form_preview_img"
                                  alt=""
                                  onError={hideBannerImg}
                                />
                                {/* ) : (
                                  <img
                                    className="add_course_form_preview_img"
                                    src={CategoriesAltImg}
                                    alt=""
                                  />
                                )} */}

                                <div className="d-flex flex-column">
                                  <span className="add_course_form_img_preview_name">
                                    {state?.bannerImage?.file?.name ||
                                      state.bannerImage}
                                  </span>
                                  <span className="add_course_form_img_preview_type">
                                    {state?.bannerImage?.file?.type ===
                                    'image/png'
                                      ? 'PNG'
                                      : 'JPG'}
                                  </span>
                                </div>
                              </div>

                              <span className="add_course_form_img_preview_size">
                                {state?.bannerImage?.file?.size &&
                                  bytesToSize(state?.bannerImage?.file?.size)}
                              </span>
                            </div>
                          )}
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="add_course_form_label my-2 mx-0">
                            Icon Image
                          </label>

                          <NewCustomDropzon
                            name="iconImage"
                            accept="image/jpeg, image/jpg, image/png"
                            handleUpload={handleUpload}
                            type="img"
                          />

                          <div className="d-flex gap-2 my-2">
                            <InfoIcon color={'#070707'} />
                            <span
                              className={classNames({
                                add_course_form_sublabel: true,
                              })}
                            >
                              File formats should be PNG, JPEG or JPG, Max 10MB
                            </span>
                          </div>

                          {state?.iconImage && (
                            <div className="add_course_form_img_preview_wrapper">
                              <div className="d-flex align-items-center gap-3">
                                {showIconImage ? (
                                  <img
                                    src={
                                      (state?.iconImage?.preview &&
                                        state?.iconImage?.preview) ||
                                      state?.iconImage
                                    }
                                    className="add_course_form_preview_img"
                                    alt=""
                                    onError={hideIconImg}
                                  />
                                ) : (
                                  <img
                                    className="add_course_form_preview_img"
                                    src={CategoriesAltImg}
                                    alt=""
                                  />
                                )}

                                <div className="d-flex flex-column">
                                  <span className="add_course_form_img_preview_name">
                                    {state?.iconImage?.file?.name ||
                                      state?.iconImage}
                                  </span>
                                  <span className="add_course_form_img_preview_type">
                                    {state?.iconImage?.file?.type ===
                                    'image/png'
                                      ? 'PNG'
                                      : 'JPG'}
                                  </span>
                                </div>
                              </div>

                              <span className="add_course_form_img_preview_size">
                                {state?.iconImage?.file?.size &&
                                  bytesToSize(state?.iconImage?.file?.size)}
                              </span>
                            </div>
                          )}
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Type
                          </label>
                          <Select
                            options={categoryType}
                            name="type"
                            className="org_form_input"
                            placeholder="Type"
                            value={categoryType.find(
                              (i) => i.value === state.type
                            )}
                            valid={state.typeErr === 'valid'}
                            invalid={state.typeErr === 'invalid'}
                            onChange={(newValue) =>
                              setState({
                                ...state,
                                type: newValue.value,
                              })
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: '45px',
                              }),
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Sub Type
                          </label>
                          <Select
                            options={categorySubType}
                            name="type"
                            className="org_form_input"
                            placeholder="Sub Type"
                            value={categorySubType.find(
                              (i) => i.value === state.subType
                            )}
                            valid={state.subTypeErr === 'valid'}
                            invalid={state.subTypeErr === 'invalid'}
                            onChange={(newValue) =>
                              setState({
                                ...state,
                                subType: newValue.value,
                              })
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: '45px',
                              }),
                            }}
                          />
                        </FormGroup>
                      </div>

                      <FormGroup className="my-4 d-flex algin-items-center gap-4">
                        <label className="add_course_form_label mx-0">
                          Make this category private?
                        </label>

                        <label className="is_private_toggle" htmlFor="myToggle">
                          <Input
                            className="toggle__input"
                            name=""
                            type="checkbox"
                            id="myToggle"
                            checked={!state?.isPublic}
                            onChange={(e) => {
                              setState({
                                ...state,
                                isPublic: !e.target.checked,
                              });
                            }}
                          />
                          <div className="toggle__fill"></div>
                        </label>
                      </FormGroup>

                      {state.type === 'sub-category' && (
                        <div className="col-md-6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Category
                            </label>
                            <Select
                              options={categoriesList}
                              name="type"
                              className="org_form_input"
                              placeholder="Type"
                              value={categoriesList.find(
                                (i) => i.value === state.mainCategoryId
                              )}
                              valid={state.typeErr === 'valid'}
                              invalid={state.typeErr === 'invalid'}
                              onChange={(newValue) =>
                                setState({
                                  ...state,
                                  mainCategoryId: newValue.value,
                                })
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  minHeight: '45px',
                                }),
                              }}
                            />
                          </FormGroup>
                        </div>
                      )}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddCategory;
