import React from "react";

export default function SmSingleQueIcon({ color }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        d="M11.6667 3C11.2067 3 10.8333 3.37333 10.8333 3.83333V5.5C10.8333 5.96 11.2067 6.33333 11.6667 6.33333H16.6667C17.1267 6.33333 17.5 5.96 17.5 5.5V3.83333C17.5 3.37333 17.1267 3 16.6667 3H11.6667ZM11.6667 8C11.2067 8 10.8333 8.37333 10.8333 8.83333C10.8333 9.29333 11.2067 9.66667 11.6667 9.66667H15C15.46 9.66667 15.8334 9.29333 15.8334 8.83333C15.8334 8.37333 15.46 8 15 8H11.6667ZM5.83334 11.3333C3.9925 11.3333 2.5 12.8258 2.5 14.6667C2.5 16.5075 3.9925 18 5.83334 18C7.67418 18 9.16668 16.5075 9.16668 14.6667C9.16668 12.8258 7.67418 11.3333 5.83334 11.3333ZM11.6667 11.3333C11.2067 11.3333 10.8333 11.7067 10.8333 12.1667V13.8333C10.8333 14.2933 11.2067 14.6667 11.6667 14.6667H16.6667C17.1267 14.6667 17.5 14.2933 17.5 13.8333V12.1667C17.5 11.7067 17.1267 11.3333 16.6667 11.3333H11.6667ZM5.83334 13C6.75417 13 7.50001 13.7458 7.50001 14.6667C7.50001 15.5875 6.75417 16.3333 5.83334 16.3333C4.9125 16.3333 4.16667 15.5875 4.16667 14.6667C4.16667 13.7458 4.9125 13 5.83334 13ZM11.6667 16.3333C11.2067 16.3333 10.8333 16.7067 10.8333 17.1667C10.8333 17.6267 11.2067 18 11.6667 18H15C15.46 18 15.8334 17.6267 15.8334 17.1667C15.8334 16.7067 15.46 16.3333 15 16.3333H11.6667Z"
        fill={color}
      />
      <circle cx="5.83333" cy="6.33333" r="3.33333" fill={color} />
    </svg>
  );
}
