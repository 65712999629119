const initialState = {
    customCategories: [],
}

const customCategoriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "GET_CUSTOM_CATEGORIES_SUCCESS":
            return { ...state, ...payload }
        case "GET_CUSTOM_CATEGORIES_FAILED":
            return { ...state, ...payload }

        default:
            return state
    }
}

export default customCategoriesReducer;