import React, { useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box, MenuItem, Typography } from "@mui/material";

import {
  Badge,
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import classNames from "classnames";
import { trackPromise } from "react-promise-tracker";
import { getAllLab } from "actions/adminActions/labsActions";
import { loaderType } from "type";
import { Link } from "react-router-dom";
import { deleteLab } from "actions/adminActions/labsActions";
import { getOrgUsers } from "actions/adminActions/organisationsActions";
import { getAllOrgUser } from "actions/adminActions/organisationsActions";
import { deleteOrganisation } from "actions/adminActions/organisationsActions";

const ListOrganizationUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [orgId, setOrgId] = useState(null);
  const users = useSelector((state) => {
    return state.organisation.orgData;
  });
  const { orgUser } = useSelector((state) => state.organisation);
  const plans = useSelector((state) => state.plans.plans);
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  useEffect(() => {
    dispatch(getOrgUsers(0));
    trackPromise(dispatch(getAllOrgUser(0, 2000)), loaderType.GENERAL_REQUEST);
    dispatch(getOrgUsers(id, 0, 1000));
  }, []);

  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });
    const handleRowClick = (event, rowData) => {
      history.push({
        pathname: `/user/statistics/${rowData.original._id}`,
        // state: { data: rowData },
      });
      dispatch({
        type: "USERS_PROFILE",
        payload: rowData.original,
      });
    };

    const columns = useMemo(
      () => [
        {
          header: 'Name',
          accessorKey: 'name',
          Cell: ({ row }) => (
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={(e) => {
                handleRowClick(e, row);
              }}
            >
              <span className="avatar rounded-circle mr-3">
                <img
                  alt="..."
                  src={
                    row?.original?.profilePicUrl
                      ? row?.original?.profilePicUrl
                      : require('assets/img/theme/blank-profile.png')
                  }
                  style={{ height: '100%', width: 'fit-content' }}
                />
              </span>
              <Typography className="">{row?.original?.name}</Typography>
            </Box>
          ),
        },

        {
          header: 'Email',
          accessorKey: 'email',
          Cell: ({ row }) => (
            <>
              <Typography className="">{row?.original?.email}</Typography>
            </>
          ),
        },
        {
          header: 'Status',
          Cell: ({ row }) => (
            <>
              {' '}
              <Badge color="" className="badge-dot mr-4">
                <i
                  className={classNames(
                    { 'bg-success': row?.original?.isActive },
                    { 'bg-warning': !row?.original?.isActive }
                  )}
                />
                <span
                  className={classNames(
                    'status',
                    { 'text-success': row?.original?.isActive },
                    { 'text-warning': !row?.original?.isActive }
                  )}
                >
                  {row?.original?.isActive ? 'Enabled' : 'Disabled'}
                </span>
              </Badge>
            </>
          ),
        },

        {
          header: 'Groups',
          Cell: ({ row }) => <Typography className="">Coming Soon</Typography>,
        },
      ],
      []
    );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };
  const confirmAlert = () => {
    trackPromise(
      dispatch(deleteOrganisation(setOrgId)),
      loaderType.GENERAL_REQUEST
    );
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "success",
      message: "Category deleted successfully.",
    });
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <h5 className="org_page_title">Organization Users</h5>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={orgUser || plans || []}
            enableRowNumbers
            positionActionsColumn="last"
          />
        </MuiOverride>
      </Card>
      {alert.status === "warning" && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: "24px",
              }}
            >
              {alert?.message}{" "}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === "success" && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: "24px",
              }}
            >
              {alert?.message}{" "}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default ListOrganizationUser;
