/** @format */

import React from "react";
import { Col } from "reactstrap";
import { Pie } from "react-chartjs-2";

var colors = {
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529',
  },
  theme: {
    default: '#172b4d',
    primary: '#B71F24',
    secondary: '#5AB459',
    info: '#11cdef',
    success: '#2dce89',
    danger: '#f5365c',
    warning: '#fb6340',
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent',
};

export const ChartPie = ({ data = [0, 0], title }) => {
  const chartExample6 = {
    data: {
      labels: ['Solved', 'Pending'],
      datasets: [
        {
          data: data.map((x) => x),
          backgroundColor: [colors.theme['success'], colors.theme['primary']],
          label: 'Dataset 1',
        },
      ],
    },
    options: {
      responsive: true,
      legend: {
        position: 'top',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  return (
    <Col xl="12" className="p-0">
      <div class="card">
        <div class="card-header">
          <h5 class="h3 mb-0">{title}</h5>
        </div>

        <div class="card-body">
          <Pie
            height={100}
            data={chartExample6.data}
            options={chartExample6.options}
            className="chart-canvas"
            id="chart-pie"
          />
        </div>
      </div>
    </Col>
  );
};
