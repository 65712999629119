import React from "react";

export default function PlusIcon({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 14.1667C9.16667 14.6269 9.53976 15 10 15C10.4602 15 10.8333 14.6269 10.8333 14.1667V10.8333H14.1667C14.6269 10.8333 15 10.4602 15 10C15 9.53976 14.6269 9.16667 14.1667 9.16667H10.8333V5.83333C10.8333 5.3731 10.4602 5 10 5C9.53976 5 9.16667 5.3731 9.16667 5.83333V9.16667H5.83333C5.3731 9.16667 5 9.53976 5 10C5 10.4602 5.3731 10.8333 5.83333 10.8333H9.16667V14.1667Z"
        fill={color}
      />
    </svg>
  );
}
