import firebase from 'firebase/compat/app'
import API2 from 'api2'

export const loginAction = (email, password) => async dispatch => {
        API2.post('auth/login', {email, password})
          .then(res => {
            if (res.data.data.accessToken && res.data.data.userType === 'admin' ) {
              let data = res.data.data;
              dispatch({ type: "login", payload: true, data });
            }
            else {
              dispatch({ type: "login_failed", payload: "failed", err: "Login Failed. Please contact support." });
            }
          })
          .catch(function (err) {
            let errMessage = (err.response && err.response.data.message) || 'Unknown Error Occured';
      dispatch({ type: "login_failed", payload: "failed", err: errMessage });
    });
};

export const resetPassword = (email) => {
  return (dispatch, getState, {getFirestore}) => {
    firebase.auth().sendPasswordResetEmail(email).then((res) => {
      dispatch({ type: "password_reset_success", payload: "success", res });
    }).catch((err) => {
      dispatch({ type: "password_reset_error", payload: "failed", err });
    });
  }
};

export const tokenExpire = () => {
  return (dispatch) => {
    dispatch({ type: "TOKEN_EXPIRE", message: "Session expired"})
  }
}