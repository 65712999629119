import API from "api2";
import { dataURLtoFile } from "../../helpers";
import { showAlert } from "actions/templateAction";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "../../type";

const config = (accessToken) => ({
  headers: { Authorization: accessToken },
});

export const getExamsApi = (skip, limit) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API.get(`api/exam?skip=${skip}&limit=${limit}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "GET_EXAMS_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "GET_EXAMS_FAILED", payload: "failed", err });
          reject(errorMessage);
        });
    });
  };
};

export const fetchAllExams = (skip, limit) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    // API({
    //     method: "get",
    //     url:`api/exam?skip=${skip}&limit=${limit}`,
    //     headers: config(token)
    //   })
    API.get(`api/exam?skip=${skip}&limit=${limit}`, config(token))
      .then((res) => {
        // const { data } = res;
        dispatch({
          type: "GET_All_EXAMS_SUCCESS",
          payload: "success",
          data: res.data.data,
        });
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) ||
          "Unknown Error Occured";
        dispatch({
          type: "GET_ALL_EXAMS_FAILED",
          payload: "failed",
          err: errMessage,
        });
      });
  };
};

export const updateExamsLabs = (id, labs) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    let form = {
      exams: labs,
      // customCategoryIds: data.customCategoryIds.map(x => x.value),
    };

    return new Promise(function (resolve, reject) {
      API.put(`/api/exam/${id}/examLabs`, form, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "UPDATE_EXAM_LAB_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "UPDATE_EXAM_LAB_FAILED", payload: "failed", err });
          reject(errorMessage);
        });
    });
  };
};
export const getAllExam = (examStatus) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API.get(`/api/scheduledExams?examStatus=${examStatus}`,   config(token) )
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "FETCH_ALLEXAM_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "FETCH_AllEXAM_FAILED", payload: "failed", err });
          reject(errorMessage);
        });
    });
  };
};
export const getSingleScheduledExam = (scheduledId) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: "get",
        url: `/api/scheduledExams/${scheduledId}`,
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "FETCH_SINGLE_EXAM_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({
            type: "FETCH_SINGLE_EXAM_FAILED",
            payload: "failed",
            err,
          });
          reject(errorMessage);
        });
    });
  };
};

export const createExam = (formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    const bodyFormData = new FormData();

    if (formData.coverImage)
      bodyFormData.append("coverImage", formData.coverImage);
    if (formData.thumbnail)
      bodyFormData.append("thumbnail", formData.thumbnail);

    return new Promise((resolve, reject) => {
      API({
        method: "post",
        url: `api/exam`,
        headers: config(token),
        data: {
          ...formData,
          // title: formData.title,
          // content: formData.content,
          // instructions: formData.instructions,
          // flagQuestion: formData.flagQuestion,
          // flag: formData.flag,
          // categoryId: formData.categoryIds[0].value,
          // categoryIds: formData.categoryIds.map(x => x.value),
          // solution: formData.solution,
          // difficulty: formData.difficulty,
          // score: formData.score
        },
      })
        .then((res) => {
          API({
            method: "post",
            url: `api/exam/${res.data.data.id}/images`,
            headers: config(token),
            data: bodyFormData,
          })
            .then((res2) => {
              const { data } = res;
              dispatch({ type: "CREATE_EXAM_SUCCESS" });
              dispatch(showAlert(true, "success", data.message));
              resolve(data);
            })
            .catch((err2) => {
              let errMessage =
                (err2.response && err2.response.data.message) ||
                "Unknown Error Occured";
              dispatch({ type: "CREATE_EXAM_ERROR" }, err2);
              dispatch(showAlert(true, "failed", errMessage));
              reject();
            });

          // const { data } = res;
          // dispatch({ type: "CREATE_COURSE_SUCCESS" });
          // dispatch(showAlert(true, 'success', data.message));
          // resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({ type: "CREATE_EXAM_ERROR" }, err);
          dispatch(showAlert(true, "failed", errMessage));
          reject();
        });
    });
  };
};

export const addExamsPlans = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let form = {
      ...data,
      // customCategoryIds: data.customCategoryIds.map(x => x.value),
    };

    return new Promise(function (resolve, reject) {
      API.post(`api/license/exam/${data.courseid}`, form, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "UPDATE_COURSE_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "UPDATE_COURSE_FAILED", payload: "failed", err });
          reject(errorMessage);
        });
    });
  };
};

export const updateExamsPlans = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let form = {
      ...data,
      // customCategoryIds: data.customCategoryIds.map(x => x.value),
    };

    return new Promise(function (resolve, reject) {
      API.put(`api/license/exam/${data.courseid}`, form, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "UPDATE_COURSE_PLAN_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({
            type: "UPDATE_COURSE_PLAN_FAILED",
            payload: "failed",
            err,
          });
          reject(errorMessage);
        });
    });
  };
};

export const updateExams = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let payload = {
      ...data,
      // customCategoryIds: data.customCategoryIds.map(x => x.value)
    };

    const data2 = new FormData();
    data2.append("coverImage", data["coverImage"]);
    data2.append("thumbnail", data["thumbnail"]);

    return new Promise(function (resolve, reject) {
      API.put(`api/exam/${id}`, payload, config(token))
        .then((res) => {
          // const { data } = res;
          // dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: 'success', data })
          // resolve(data)
          if (data["coverImage"] != "" || data["thumbnail"] != "") {
            API.put(`api/exam/${res.data.data.id}/images`, data2, config(token))
              .then((res) => {
                const { data } = res;
                dispatch({
                  type: "UPDATE_COURSE_SUCCESS",
                  payload: "success",
                  data,
                });
                resolve(data);
              })
              .catch((err) => {
                // const errorMessage = err?.response?.data?.message;
                // dispatch({ type: 'UPDATE_COURSE_FAILED', payload: 'failed', err })
                // reject(errorMessage)

                const { data } = res;
                dispatch({
                  type: "UPDATE_COURSE_SUCCESS",
                  payload: "success",
                  data,
                });
                resolve(data);
              });
          } else {
            const { data } = res;
            dispatch({
              type: "UPDATE_COURSE_SUCCESS",
              payload: "success",
              data,
            });
            resolve(data);
          }

          // const { data } = res;
          // dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: 'success', data })
          // resolve(data)
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "UPDATE_COURSE_FAILED", payload: "failed", err });
          reject(errorMessage);
        });
    });
  };
};

export const deleteExams = (id) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API.delete(`api/exam/${id}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "DELETE_COURSE_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "DELETE_COURSE_FAILED", payload: "failed", err });
          reject(errorMessage);
        });
    });
  };
};
export const getExamResult = (examResultId) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API.get(`/api/examResult/${examResultId}`, config(token))
        .then((res) => {
          dispatch({
            type: 'FETCH_EXAM_RESULT_SUCCESS',
            payload: 'success',
            data: res,
          });
          resolve(res.data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({
            type: 'FETCH_EXAM_RESULT_RESULT',
            payload: 'failed',
            err,
          });
          reject(errorMessage);
        });
    });
  };
  // return (dispatch, getState) => {
  //   const token = getState().authState.user.accessToken;
  //   API({
  //     method: 'get',
  //     url: `/api/examResult/${examResultId}`,
  //     headers: config(token),
  //   })
  //     .then((res) => {
  //       // const { data } = res.data.data;
  //       dispatch({
  //         type: 'FETCH_EXAM_RESULT_SUCCESS',
  //         payload: true,
  //         data: res.data,
  //       });
  //     })
  //     .catch((err) => {
  //       let errMessage =
  //         (err.response && err.response.data.message) ||
  //         'Unknown Error Occured';
  //       dispatch({
  //         type: 'FETCH_EXAM_RESULT_RESULT',
  //         payload: 'failed',
  //         err: errMessage,
  //       });
  //     });
  // };
};