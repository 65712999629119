export const createTrack = (state) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection(`vcr/db/tracks`).add({
      name: state.customStyles.firstName,
      description: state.customStyles.lastName,
    }).then(() => {
      dispatch({ type: 'CREATE_TRACK_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'CREATE_TRACK_ERROR' }, err);
    });
  }
};

export const editTrack = (trackId, state) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection(`vcr/db/tracks`).doc(trackId).update({
      name: state.customStyles.firstName,
      description: state.customStyles.lastName,
    }).then(() => {
      dispatch({ type: 'UPDATE_TRACK_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'UPDATE_TRACK_ERROR' }, err);
    });
  }
};

export const deleteTrack = (trackId) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection(`vcr/db/tracks`).doc(trackId).delete().then(() => {
      dispatch({ type: 'DELETE_TRACK_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'DELETE_TRACK_ERROR' }, err);
    });
  }
};
