import React from "react";

export default function TrashIcon({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83335 5V15C5.83335 15.9205 6.57955 16.6667 7.50002 16.6667H12.5C13.4205 16.6667 14.1667 15.9205 14.1667 15V5H15.8334V15C15.8334 16.8409 14.341 18.3333 12.5 18.3333H7.50002C5.65907 18.3333 4.16669 16.8409 4.16669 15V5H5.83335Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99814 1.66602C9.07731 1.66602 8.33148 2.40967 8.33148 3.32782H4.16481C3.70481 3.32782 3.33148 3.70007 3.33148 4.15872C3.33148 4.61738 3.70481 4.98963 4.16481 4.98963H15.8315C16.2915 4.98963 16.6648 4.61738 16.6648 4.15872C16.6648 3.70007 16.2915 3.32782 15.8315 3.32782H11.6648C11.6648 2.40967 10.919 1.66602 9.99814 1.66602ZM8.33331 6.6903C8.79355 6.6903 9.16665 7.0623 9.16665 7.5212V14.1684C9.16665 14.6273 8.79355 14.9993 8.33331 14.9993C7.87308 14.9993 7.49998 14.6273 7.49998 14.1684V7.5212C7.49998 7.0623 7.87308 6.6903 8.33331 6.6903ZM12.5 7.5212C12.5 7.0623 12.1269 6.6903 11.6666 6.6903C11.2064 6.6903 10.8333 7.0623 10.8333 7.5212V14.1684C10.8333 14.6273 11.2064 14.9993 11.6666 14.9993C12.1269 14.9993 12.5 14.6273 12.5 14.1684V7.5212Z"
        fill={color}
      />
    </svg>
  );
}
