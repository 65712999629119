import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";

import { Badge, Card, CardHeader, Container } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import "./Quiz.css";
import { useHistory } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMyCourseExam } from "actions/adminActions/courseAction";
import moment from "moment";
import api2 from "api2";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import ConfirmationModel from "components/Common/ConfirmationModel";
import classNames from "classnames";

//Mock Data

const AllQuiz = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedQuiz, setSelectedQuiz] = useState();
  const token = useSelector((state) => state?.authState?.user?.accessToken);

  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });
  const myQuizList = useSelector((state) => state?.courses?.myQuizList);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Quiz Name',
        Cell: ({ row }) => <>{row?.original?.name}</>,
      },
      {
        accessorKey: 'createdAt',
        header: 'Date Created',
        Cell: ({ row }) => (
          <>{moment(row?.original?.createdAt).format('MMM DD, YYYY')}</>
        ),
      },
      {
        accessorKey: 'mcq.length',
        header: 'Total Questions',
        Cell: ({ row }) => <div>{row?.original?.mcqs?.length}</div>,
      },
      {
        header: 'Status',
        Cell: ({ row }) => (
          <Badge color="" className="badge-dot mr-4">
            <i
              className={classNames(
                { 'bg-success': !row?.original.isDraft },
                { 'bg-warning': row?.original.isDraft }
              )}
            />
            <span
              className={classNames(
                'status',
                { 'text-success': !row?.original.isDraft },
                { 'text-warning': row?.original.isDraft }
              )}
            >
              {row?.original.isDraft ? 'Draft' : 'Publish'}
            </span>
          </Badge>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(getMyCourseExam(0, 1000, "quiz"));
  }, [dispatch]);
  const handleQuiz = () => {
    history.push("/quiz/add");
  };
  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };
  const confirmDelete = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      await api2
        .delete(`/api/course/exam/${selectedQuiz}`, config)
        .then((res, err) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: "success",
            message: "Quiz deleted successfully!",
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  const manageAlert = () => {
    setAlert({ ...alert, isOpenAlert: false });
    dispatch(getMyCourseExam(0, 1000, "quiz"));
  };
  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">All Quiz</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button className="pr-btn-with-icon" onClick={handleQuiz}>
                <PlusIcon color="white" />
                Add Quiz
              </button>
            </div>
          </div>
        </CardHeader>
        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={myQuizList || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <button
                  type="button"
                  className="table-action-edit-btn"
                  onClick={() =>
                    history.push(`/quiz/edit/${row?.original?._id}`)
                  }
                >
                  <EditIcon color={'#070707'} />
                  Edit
                </button>
                <button
                  type="button"
                  className="table-action-delete-btn"
                  onClick={() => {
                    handleDelete();
                    setSelectedQuiz(row?.original?._id);
                  }}
                >
                  <SmTrashIcon color={'#EF466F'} />
                  Delete
                </button>
              </Box>
            )}
            sx={{
              '& th': { color: 'white', backgroundColor: 'blue' },
            }}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'success' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          buttonText={'OK'}
          confirmation={() => {
            manageAlert();
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
        />
      )}
      {alert.status === 'warning' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            confirmDelete();
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onClose={() => {
            setAlert({ isOpenAlert: false });
          }}
        />
      )}
    </Container>
  );
};

export default AllQuiz;
