import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, MenuItem, Typography } from '@mui/material';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo, useRef } from 'react';

import { getCoursesApi } from 'actions/adminActions/courseAction';
import { assignLicenseToUsers } from 'actions/adminActions/licenseActions';
import { getOrgUsers } from 'actions/adminActions/organisationsActions';
import {
  deleteUser,
  getAllUser,
  toggleExamMode,
  toggleUserStatus,
  updateUsers,
  verifyUserEmail,
} from 'actions/adminActions/usersActions';
import { hideAlert, showAlert } from 'actions/templateAction';
import classNames from 'classnames';
import MuiOverride from 'components/Common/MuiOverride';
import ModalCoursesMulti from 'components/Modal/ModalCoursesMulti';
import ModalLicenseMulti from 'components/Modal/ModalLicenseMulti';
import PlusIcon from 'iconComponents/PlusIcon';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Badge, Card, CardHeader, Container } from 'reactstrap';
import { loaderType } from 'type';

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orgId, setOrgId] = useState(null);
  const [licenseIds, setLicenseIds] = useState([]);
  const [selectUserId, setSelectUserId] = useState();
  const [actionType, setActionType] = useState('');
  const tableRef = useRef(null);
  const [courseIds, setCourseIds] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const [courseModal, setCourseModal] = useState();
  const { courses } = useSelector((state) => state.courses);
  useEffect(() => {
    dispatch(getCoursesApi(0, 3000));
  }, []);

  const users = useSelector((state) => {
    return state.users.userData;
  });
  const plans = useSelector((state) => state.plans.plans);
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);
  const [licenseModal, setLicenseModal] = useState(false);

  useEffect(() => {
    dispatch(getOrgUsers(0));
    trackPromise(dispatch(getAllUser(0, 3000)), loaderType.GENERAL_REQUEST);
  }, []);

  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });
  const handleRowClick = (event, rowData) => {
    history.push({
      pathname: `/user/statistics/${rowData.original._id}`,
    });
    dispatch({
      type: 'USERS_PROFILE',
      payload: rowData.original,
    });
  };
  const toggleCourseModal = (item) => {
    setCourseModal(!courseModal);
  };
  const handleUpdateCourse = () => {
    const courseIdArray = courseIds.map((item) => item.value);
    trackPromise(
      dispatch(
        updateUsers(
          { ...selectedUsers, assignCourses: courseIdArray },
          selectedUsers._id
        )
      ),
      loaderType.GENERAL_REQUEST
    )
      .then((res) => {
        showAlert('success', 'Assign courses successfully.');
        trackPromise(dispatch(getAllUser(0, 200)), loaderType.GENERAL_REQUEST);
        setCourseModal(false);
      })
      .catch((err) => {
        dispatch(showAlert(true, 'warning', err));
      });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={(e) => {
              handleRowClick(e, row);
            }}
          >
            <span className="avatar rounded-circle mr-3">
              <img
                alt="..."
                src={
                  row?.original?.profilePicUrl
                    ? row?.original?.profilePicUrl
                    : require('assets/img/theme/blank-profile.png')
                }
                style={{ height: '100%', width: 'fit-content' }}
              />
            </span>
            <Typography className="">{row?.original?.name}</Typography>
          </Box>
        ),
      },
      {
        header: 'Organisation',
        accessorKey: 'email',
        Cell: ({ row }) => (
          <>
            <Typography className="">
              {row?.original?.organisationMainUserIds?.length
                ? row?.original?.organisationMainUserIds[0].name
                : row?.original?.organisationMainUserId?.name}
            </Typography>
          </>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        Cell: ({ row }) => (
          <>
            <Typography className="">{row?.original?.email}</Typography>
          </>
        ),
      },
      {
        header: 'Status',
        Cell: ({ row }) => (
          <>
            <Badge color="" className="badge-dot mr-4">
              <i
                className={classNames(
                  { 'bg-success': row?.original?.isActive },
                  { 'bg-warning': !row?.original?.isActive }
                )}
              />
              <span
                className={classNames(
                  'status',
                  { 'text-success': row?.original?.isActive },
                  { 'text-warning': !row?.original?.isActive }
                )}
              >
                {row?.original?.isActive ? 'Enabled' : 'Disabled'}
              </span>
            </Badge>
          </>
        ),
      },
      {
        header: 'Solutions',
        Cell: ({ row }) => (
          <Badge color="" className="badge-dot mr-4">
            <i
              className={classNames(
                { 'bg-warning': row?.original?.isExamMode },
                { 'bg-success': !row?.original?.isExamMode }
              )}
            />
            <span
              className={classNames(
                'status',
                { 'text-warning': row?.original?.isExamMode },
                { 'text-success': !row?.original?.isExamMode }
              )}
            >
              {row?.original?.isExamMode ? 'Disabled' : 'Enabled'}
            </span>
          </Badge>
        ),
      },
      {
        header: 'Type',
        Cell: ({ row }) => (
          <Badge color="" className="badge-dot mr-4">
            <i
              className={classNames({
                'bg-warning': row?.original?.isDeleted,
              })}
            />
            <span
              className={classNames('status', {
                'text-warning': row?.original?.isDeleted,
              })}
            >
              {row?.original?.isDeleted ? 'Deleted' : ''}
            </span>
          </Badge>
        ),
      },
      {
        header: 'Licence',
        Cell: ({ row }) => (
          <Typography>
            {row?.original?.liceAssignedDetails?.length ? (
              <>
                {row?.original?.liceAssignedDetails.map((i, index) => {
                  {
                    return (
                      i?.licenseId?.name &&
                      i?.licenseId?.name +
                        (index !==
                        row?.original?.liceAssignedDetails?.length - 1
                          ? ','
                          : '')
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </Typography>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };
  const handleAddLicenseIds = (ids) => {
    setLicenseIds(ids);
  };
  const confirmAlert = () => {
    trackPromise(
      dispatch(deleteUser(selectUserId, actionType)),
      loaderType.GENERAL_REQUEST
    );
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'success',
      message: 'User deleted successfully.',
    });
  };
  const toggleLicenseModal = (item) => {
    setLicenseModal(!licenseModal);
  };
  const handleUpdateLicense = () => {
    const licenseIdsArray = licenseIds.length
      ? licenseIds.map((i) => i.value)
      : [];
    trackPromise(
      dispatch(assignLicenseToUsers(licenseIdsArray, orgId)),
      loaderType.GENERAL_REQUEST
    )
      .then((res) => {
        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'success',
          message: res.message,
        });
        // dispatch(showAlert(true, "success", res.message));
        dispatch(getAllUser(0, 2000));
        setLicenseModal(!licenseModal);
      })
      .catch((err) => {
        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'warning',
          message: err.message,
        });
        // dispatch(showAlert(true, "warning", err));
      });
  };

  const handleChangeStatus = (row) => {
    trackPromise(
      dispatch(
        toggleUserStatus({
          uid: row?.original?._id,
          status: !row?.original?.isActive,
        })
      )
        .then((res) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'success',
            message: res.message,
          });
          // dispatch(showAlert(true, "success", res.message));
          dispatch(getAllUser(0, 2000));
        })
        .catch((err) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'warning',
            message: err.message,
          });
          // dispatch(showAlert(true, 'warning', err.message));
        })
    );
  };
  const navigateTo = () => (type === 'success' ? history.push('/users') : null);
  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo('');
    }
  };
  const handleExamModeStatus = (row) => {
    trackPromise(
      dispatch(
        toggleExamMode({
          uid: row?.original?._id,
          status: !row?.original?.isExamMode,
        })
      )
        .then((res) => {
          // dispatch(
          //   showAlert(
          //     true,
          //     'success',
          //     `User solutation ${
          //       !row?.original?.isExamMode ? 'disabled' : 'enabled'
          //     } successfully.`
          //   )
          // );
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'success',
            message: `User solutation ${
              !row?.original?.isExamMode ? 'disabled' : 'enabled'
            } successfully.`,
          });
          dispatch(getAllUser(0, 2000));
        })
        .catch((err) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'warning',
            message: err.message,
          });
          // dispatch(showAlert(true, "warning", err.message));
        })
    );
  };

  const handleVerifyEmail = (row) => {
    // verifyUserEmail(row?.original._id, row.original?.email)
    trackPromise(
      dispatch(
        verifyUserEmail({
          id: row?.original._id,
          email: row.original?.email,
        })
      )
        .then((res) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'success',
            message: res.message,
          });
          // dispatch(showAlert(true, "success", res.message));
          dispatch(getAllUser(0, 2000));
        })
        .catch((err) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'warning',
            message: err.message,
          });
          // dispatch(showAlert(true, 'warning', err.message));
        })
    );
  };
  const handleExportData = () => {
    const preprocessData = (data) => {
      return data.map((item) => {
        const processedItem = {};
        Object.keys(item).forEach((key) => {
          const value = item[key];
          if (typeof value === 'object' && value !== null) {
            processedItem[key] = JSON.stringify(value);
          } else {
            processedItem[key] = value;
          }
        });
        return processedItem;
      });
    };

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Labs Data',
      useBom: true,
      useKeysAsHeaders: true,
    });

    const processedLabs = preprocessData(users || plans);
    const csv = generateCsv(csvConfig)(processedLabs);
    download(csvConfig)(csv);
  };
  const handleAddCourseIds = (ids) => {
    setCourseIds(ids);
  };
  return (
    <Container fluid>
      <ModalLicenseMulti
        open={licenseModal}
        plans={plans}
        licenseId={licenseIds}
        setLicenseId={handleAddLicenseIds}
        toggleVisible={toggleLicenseModal}
        handleUpdateLicense={handleUpdateLicense}
      />
      <ModalCoursesMulti
        open={courseModal}
        plans={courses}
        licenseId={courseIds}
        setLicenseId={handleAddCourseIds}
        toggleVisible={toggleCourseModal}
        handleUpdateLicense={handleUpdateCourse}
      />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">All Users</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push('/addUser')}
              >
                <PlusIcon color="white" />
                Add User
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={users || plans || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderTopToolbarCustomActions={() => (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  padding: '8px',
                  flexWrap: 'wrap',
                }}
              >
                <button
                  className="pr-btn-with-icon"
                  color="primary"
                  onClick={handleExportData}
                  variant="contained"
                >
                  <FileDownloadIcon /> Export All Data
                </button>
              </Box>
            )}
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={() => {
                    history.push(`edit/${row?.original?._id}`);
                  }}
                  sx={{ m: 0 }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    let assignLicense = [];
                    if (row?.original?.liceAssignedDetails?.length) {
                      row?.original?.liceAssignedDetails.map((i, index) => {
                        assignLicense.push({
                          label: i?.licenseId?.name,
                          value: i?.licenseId?._id,
                        });
                      });
                    }
                    handleAddLicenseIds(assignLicense);
                    setOrgId(row?.original?._id);
                    toggleLicenseModal(row?.original);
                  }}
                  sx={{ m: 0 }}
                >
                  Manage License
                </MenuItem>
                {row?.original?.organisationMainUserIds ? (
                  <></>
                ) : (
                  <MenuItem
                    key={0}
                    onClick={(e) => {
                      let assignCourse = [];
                      if (row?.original?.assignCourses?.length) {
                        row?.original?.assignCourses?.map((i, index) => {
                          assignCourse.push({
                            label: i?.courseName,
                            value: i?._id,
                          });
                        });
                      }
                      setCourseIds(assignCourse);
                      setSelectedUsers(row?.original);
                      setOrgId(row?.original?._id);
                      toggleCourseModal(row?.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    Manage Course Access
                  </MenuItem>
                )}

                <MenuItem
                  key={1}
                  onClick={() => {
                    handleChangeStatus(row);
                  }}
                  sx={{ m: 0 }}
                >
                  {!row?.original?.isActive ? 'Enable User' : 'Disable User'}
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={() => {
                    handleExamModeStatus(row);
                  }}
                  sx={{ m: 0 }}
                >
                  {row.original.isExamMode
                    ? 'Enable Solutions'
                    : 'Disable Solutions'}
                </MenuItem>
                {!row.original.isEmailVerified && (
                  <MenuItem
                    key={1}
                    onClick={() => {
                      setOrgId(row?.original?._id);
                      handleVerifyEmail(row);
                    }}
                    sx={{ m: 0 }}
                  >
                    Verify Email
                  </MenuItem>
                )}
                {!row.original.isDeleted && (
                  <MenuItem
                    key={1}
                    onClick={() => {
                      setSelectUserId(row.original._id);
                      handleDelete();
                      setActionType('soft');
                    }}
                    sx={{ m: 0 }}
                  >
                    Soft Delete User
                  </MenuItem>
                )}
                <MenuItem
                  key={1}
                  onClick={() => {
                    setSelectUserId(row.original._id);
                    setActionType('hard');
                    handleDelete();
                  }}
                  sx={{ m: 0 }}
                >
                  Hard Delete User
                </MenuItem>
              </>,
            ]}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'warning' && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === 'success' && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default Users;
