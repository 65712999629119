const initState = {
    plans: [],
    coursePlans: []
}

const plansReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_ALL_QUESTION_TAGS_SUCCESS":
            return {
                ...state,
                questionTags: action?.data?.tags,
            };
        case "GET_ALL_QUESTION_TAGS_FAILED":
            return {
                ...state,
                questionTags: {},
            };
        case "FETCH_ALL_QUESTIONS_FAILED":
            return {
                ...state,
                questionList: action.data.length ? action.data : [],
            };
        case "FETCH_ALL_QUESTIONS":
            return {
                ...state,
                questionList: action.data.length ? action.data : [],
            };

        default:
            return state;
    }
};

export default plansReducer;
