import Labs from 'views/pages/Labs/Labs.jsx';
import PackLicenses from 'views/pages/License/PackLicenses.jsx';
import Plans from 'views/pages/License/Plans.jsx';
import Organisations from 'views/pages/Organisations/Organisations.jsx';
import Users from 'views/pages/Users/Users.jsx';
import AddCourseLabs from './views/pages/Courses/AddCourseLabs.jsx';
import NewAddCourse from './views/pages/Courses/index.js';
import CoursePlans from './views/pages/single/CoursePlans.jsx';
import adminAddedUsers from './views/pages/Users/adminAddedUsers.jsx';

import Announcements from 'views/pages/Announcements/index.jsx';
import AddCourseCategory from 'views/pages/Courses/CourseCategory/AddCourseCategory.jsx';
import CourseCategory from 'views/pages/Courses/CourseCategory/CourseCategory.jsx';
import AllTests from 'views/pages/Exams/AllTests.jsx';
import CreateNewExam from 'views/pages/Exams/CreateNewExam.jsx';
import ExamResult from 'views/pages/Exams/examResult.js';
import PDFPreview from 'views/pages/Exams/PDFPreview/PDFPreview.jsx';
import Reports from 'views/pages/Exams/Report.jsx';
import Schedule from 'views/pages/Exams/Schedule.jsx';
import AddCourseLicenses from 'views/pages/License/AddCourseLicenses.jsx';
import AddQuestionCategory from 'views/pages/QuestionCategory/AddQuestionCategory.jsx';
import QuestionCategory from 'views/pages/QuestionCategory/QuestionCategory.jsx';
import AddQuiz from 'views/pages/Quiz/AddQuiz.jsx';
import AllQuiz from 'views/pages/Quiz/AllQuiz.jsx';
import Questions from 'views/pages/Quiz/Questions';
import AddExamToCource from 'views/pages/single/AddExamToCource';
import AddLabsToExam from 'views/pages/single/AddLabsToExam';
import Tracks from 'views/pages/single/Tracks.jsx';
import AddTag from 'views/pages/Tags/AddTag.jsx';
import Tags from 'views/pages/Tags/Tags.jsx';
import ImportUsers from 'views/pages/Users/ImportUsers.jsx';
import AddCategoy from './views/pages/Category/AddCategory.jsx';
import Categories from './views/pages/Category/Categories.jsx';
import Course from './views/pages/Courses/Course.jsx';
import AddLabs from './views/pages/Labs/AddLabs.jsx';
import AddLicense from './views/pages/License/AddLicense.jsx';
import AddOrgUser from './views/pages/Organisations/AddOrgUser.jsx';
import ListOrganisationUser from './views/pages/Organisations/ListOraganizationUser.jsx';
import CourseExamPlans from './views/pages/single/CourseExamPlans.jsx';
import AddUser from './views/pages/Users/AddUser.jsx';
import UserStats from './views/pages/Users/UserStats.jsx';
import AddPhygitalLabAdmin from 'views/pages/PhygitalLabAdmin/AddPhygitalLabAdmin.jsx';
import PhygitalLabAdmins from 'views/pages/PhygitalLabAdmin/PhygitalLabAdmins.jsx';

const routes = [
  {
    path: '/categories',
    name: 'Categories',
    icon: 'ni ni-ungroup text-orange',
    component: Categories,
    layout: 'admin',
    visible: true,
  },
  {
    path: '/addCategories',
    name: 'Categories',
    icon: 'ni ni-ungroup text-orange',
    component: AddCategoy,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/editCategories/:_id',
    name: 'Categories',
    icon: 'ni ni-ungroup text-orange',
    component: AddCategoy,
    layout: 'admin',
    visible: false,
  },
  // {
  //   path: "/my-categories",
  //   name: "Custom Categories",
  //   icon: "ni ni-ungroup text-orange",
  //   component: CustomCategories,
  //   layout: "admin",
  //   visible: true,
  // },
  {
    path: '/addLabs',
    name: 'Labs',
    icon: 'fas fa-flask text-teal',
    component: AddLabs,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/labs/edit/:id',
    name: 'Labs',
    icon: 'fas fa-flask text-teal',
    component: AddLabs,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/labs',
    name: 'Labs',
    icon: 'fas fa-flask text-teal',
    component: Labs,
    layout: 'admin',
    visible: true,
  },
  // {
  //   path: "/custom-challenges",
  //   name: "Custom Labs",
  //   icon: "fas fa-flask text-teal",
  //   component: CustomLabs,
  //   layout: "admin",
  //   visible: true
  // },
  {
    path: '/edit-phygital-lab-Admin/:id',
    name: 'Edit Phygital Lab Admin',
    icon: 'fas fa-building text-blue',
    component: AddPhygitalLabAdmin,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/add-phygital-lab-Admin',
    name: 'Add Phygital Lab Admin',
    icon: 'fas fa-building text-blue',
    component: AddPhygitalLabAdmin,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/phygital-lab-Admin',
    name: 'Phygital Lab Admin',
    icon: 'fas fa-building text-blue',
    component: PhygitalLabAdmins,
    layout: 'admin',
    visible: true,
  },
  {
    path: '/tracks',
    name: 'Tracks',
    icon: 'fas fa-tasks text-yellow',
    component: Tracks,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/addOrg',
    name: 'Organisations',
    icon: 'fas fa-building text-blue',
    component: AddOrgUser,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/editOrg/:id',
    name: 'Organisations',
    icon: 'fas fa-building text-blue',
    component: AddOrgUser,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/organisations',
    name: 'Organisations',
    icon: 'fas fa-building text-blue',
    component: Organisations,
    layout: 'admin',
    visible: true,
  },
  {
    path: '/tags',
    name: 'Tags',
    icon: 'fas fa-tag text-blue',
    component: Tags,
    layout: 'admin',
    visible: true,
  },
  {
    path: '/add-tag',
    name: 'Tags',
    icon: 'fas fa-building text-blue',
    component: AddTag,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/edit-tag/:id',
    name: 'Tag',
    icon: 'fas fa-building text-blue',
    component: AddTag,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/organisation/org-users/:id',
    name: 'Organisations',
    icon: 'fas fa-building text-blue',
    component: ListOrganisationUser,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/addUser',
    name: 'Users',
    icon: 'fas fa-users text-red',
    component: AddUser,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/edit/:id',
    name: 'Users',
    icon: 'fas fa-users text-red',
    component: AddUser,
    layout: 'admin',
    visible: false,
  },
  {
    collapse: true,
    name: 'Users',
    icon: 'fas fa-users text-red',
    component: Users,
    layout: 'admin',
    state: 'examplesCollapse2',
    visible: true,
    views: [
      {
        path: '/import',
        name: 'Import Users',
        component: ImportUsers,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/users',
        name: 'All Users',
        component: Users,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/adminaddedusers',
        name: 'Admin Added Users ..',
        component: adminAddedUsers,
        layout: 'admin',
        visible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Quiz',
    icon: 'fas fa-users text-blue',
    // component: Course,
    layout: 'admin',
    state: 'category',
    visible: true,
    views: [
      {
        path: '/quiz/questions',
        name: 'Questions',
        component: Questions,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/quiz/add',
        name: 'Add Quiz',
        component: AddQuiz,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/quiz/edit/:_id',
        name: 'Edit Quiz',
        component: AddQuiz,
        layout: 'admin',
      },
      {
        path: '/quiz',
        name: 'All Quiz',
        component: AllQuiz,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/question/category',
        name: 'Question Category',
        component: QuestionCategory,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/question/add-category',
        name: 'Add Question Category',
        component: AddQuestionCategory,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/question/edit-category/:_id',
        name: 'Edit Question Category',
        component: AddQuestionCategory,
        layout: 'admin',
        visible: false,
      },
    ],
  },
  {
    collapse: true,
    name: 'Courses',
    icon: 'fas fa-users text-blue',
    component: Course,
    layout: 'admin',
    visible: true,
    views: [
      {
        path: '/addcourse',
        name: 'Add Course',
        component: NewAddCourse,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/editcourse/:id',
        name: 'edit Add Course',
        component: NewAddCourse,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/allcourse',
        name: 'All Courses',
        component: Course,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/course/category',
        name: 'Courses Category',
        component: CourseCategory,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/course/add-category',
        name: 'Add Course Category',
        component: AddCourseCategory,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/course/edit-category/:_id',
        name: 'Edit Course Category',
        component: AddCourseCategory,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/addCourseLabs',
        name: 'Add Course Lab',
        component: AddCourseLabs,
        layout: 'admin',
        visible: false,
      },
    ],
  },

  {
    collapse: true,
    name: 'Exams',
    icon: 'fas fa-layer-group',
    color: 'teal',
    state: 'examplesCollapse1',
    visible: true,
    views: [
      {
        path: '/exam/create-new',
        name: 'Create New',
        component: CreateNewExam,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/exam/edit/:_id',
        name: 'Edit',
        component: CreateNewExam,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/tests',
        name: 'All Tests',
        component: AllTests,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/exam/schedule',
        name: 'Schedule',
        component: Schedule,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/report',
        name: 'Report',
        component: Reports,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/user/report/:_id',
        name: 'ExamResult',
        component: ExamResult,
        layout: 'admin',
        visible: false,
      },
    ],
  },
  {
    path: '/assignExam',
    name: 'Add Exam',
    component: AddExamToCource,
    layout: 'admin',
    visible: false,
  },
  {
    path: '/addlabsToExam/add',
    name: 'add Exam Labs',
    component: AddLabsToExam,
    layout: 'admin',
    visible: false,
  },
  // {
  //   collapse: true,
  //   name: "Exams",
  //   icon: "fas fa-layer-group text-red",
  //   color: "teal",
  //   layout: "admin",
  //   state: "examplesCollapse1",
  //   visible: false,
  //   views: [
  //     {
  //       path: "/exam/add",
  //       name: "Add Exam",
  //       component: AddExam,
  //       layout: "admin",
  //       visible: true,
  //     },
  //     {
  //       path: "/exam/edit/:id",
  //       name: "Edit Exam",
  //       component: AddExam,
  //       layout: "admin",
  //       visible: false,
  //     },
  //     {
  //       path: "/ExamLicenses",
  //       name: "Assign labs to Course",
  //       icon: "fas fa-credit-card text-green",
  //       component: ExamPlansLab,
  //       layout: "admin",
  //       visible: false,
  //     },
  //     {
  //       path: "/exams",
  //       name: "All Exams",
  //       component: Exams,
  //       layout: "admin",
  //       visible: true,
  //     },
  //   ],
  // },

  {
    path: '/user/statistics/:id',
    name: 'Statistics',
    icon: 'fas fa-users text-red',
    component: UserStats,
    layout: 'admin',
    visible: false,
  },

  {
    collapse: true,
    name: 'License',
    icon: 'fas fa-credit-card text-green',
    color: 'teal',
    layout: 'admin',
    state: 'examplesCollapse3',
    visible: true,
    views: [
      {
        path: '/packlicenses',
        name: 'Pack Licenses',
        component: PackLicenses,
        layout: 'admin',
        visible: true,
      },
      {
        path: '/addPackLicenses',
        name: 'Pack Licence',
        // icon: "fas fa-credit-card text-green",
        component: AddLicense,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/editPackLicenses/:id',
        name: 'Pack Licence',
        // icon: "fas fa-credit-card text-green",
        component: AddLicense,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/licenses',
        name: 'Course Licence',
        // icon: "fas fa-credit-card text-green",
        component: Plans,
        layout: 'admin',
        visible: true,
      },

      {
        path: '/add-course-licenses',
        name: 'Licenses',
        // icon: "fas fa-credit-card text-green",
        component: AddCourseLicenses,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/edit-course-licenses/:id',
        name: 'Licenses',
        // icon: "fas fa-credit-card text-green",
        component: AddCourseLicenses,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/courseLicenses',
        name: 'Assign labs to Course',
        // icon: "fas fa-credit-card text-green",
        component: CoursePlans,
        layout: 'admin',
        visible: false,
      },
      {
        path: '/courseExamLicenses',
        name: 'Assign labs to Course',
        // icon: "fas fa-credit-card text-green",
        component: CourseExamPlans,
        layout: 'admin',
        visible: false,
      },
    ],
  },

  {
    path: '/announcements',
    name: 'Announcements',
    icon: 'fas fa-bullhorn text-blue',
    component: Announcements,
    layout: 'admin',
    visible: true,
  },
  {
    path: '/pdf/preview/:id',
    name: 'PDF Preview',
    // miniName: "EV",
    component: PDFPreview,
    layout: 'admin',
    visible: false,
  },
];

export default routes;
