import React, { useEffect, useState } from "react";

// third-party
import ReactApexChart from "react-apexcharts";

// chart options
const columnChartOptions = {
  chart: {
    type: "pie",
    width: 300,
    height: 300,
  },

  labels: ["Right Answer", "Wrong Answer", "Skipped Answer"],
    
  legend: {
    show: true,
    position: "bottom",
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      radius: 5,
    },
    itemMargin: {
      horizontal: 25,
      vertical: 4,
    },
  },
  responsive: [
    {
      breakpoint: 450,
      chart: {
        width: 280,
        height: 280,
      },
      options: {
        legend: {
          show: true,
          position: "bottom",
        },
      },
    },
  ],
};

const OverviewChart = ({examResult}) => {

  const { primary } = "yellow";
  const darkLight = "red";
  const gridLine = "green";

  const firstCol = "#0D79A7";
  const secondCol = "#72ADCF";
  const thirdCol = "#BFE8FF";
  const [series, setSeries] = useState([0,0,0]);

  const [options, setOptions] = useState(columnChartOptions);
  useEffect(()=>{
    let valid = 0
    let inValid = 0
    let visited = 0
    let totalQuestion = 0
    
    examResult?.mcqQuestionsAnswers.map((item)=>{
        totalQuestion+=1
        if(item?.isValid){
            valid+=1 
        }
        if(!item?.isValid && item?.answer){
            inValid+=1
        }
        if(!item?.answer){
            visited+=1
        }
    })
    examResult?.challengeQuestionsAnswers.map((item) => {
      item?.questions.map((question) => {
        totalQuestion += 1;
        if (question?.isValid) {
          valid += 1;
        }
        if (!question?.isValid && question?.answer) {
          inValid += 1;
        }
        if (!question?.answer) {
          visited += 1;
        }
      });
    });
    setSeries([valid,inValid,visited])
  },[examResult])

  React.useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [firstCol, secondCol, thirdCol],

      xaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      legend: {
        labels: {
          colors: "gray",
        },
      },
      stroke: {
        colors: "white",
      },

      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
    }));
  }, [primary, darkLight, firstCol, secondCol, thirdCol, gridLine,examResult]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        height={350}
      />
    </div>
  );
};


export default OverviewChart;
