const initialState = {
    visible: false,
    type: null,
    msg: ''
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case "SHOW_ALERT":
        return { ...state, ...payload }
    case "HIDE_ALERT":
        return { ...state, ...payload }

    default:
        return state
    }
}
