import React from 'react'
import { Card, Col, Modal } from 'reactstrap'
import LgCheck from "assets/img/icons/common/lgCheck.svg";
import Warning from "assets/img/icons/common/warning.png";
import { Link } from 'react-router-dom';


export default function ConfirmationModel({
  isOpen,
  status,
  confirmation,
  onClose,
  title,
  buttonText,
}) {
  return (
    <Modal
      className="modal-dialog-centered conformation-modal"
      size="md"
      isOpen={isOpen}
      //   toggle={() => this.toggleModal("defaultModal")}
    >
      <div className="modal-body p-0">
        <Card className="p-4 border-0 mb-0 w-100">
          <div className="d-flex align-items-center flex-column gap-4">
            <div className="mb-3">
              {status === 'warning' ? (
                <img className="warning-img" src={Warning} alt="" />
              ) : (
                <img src={LgCheck} alt="" />
              )}
            </div>
            <span className="finish-exam-title">{title}</span>
          </div>

          <div className="d-flex justify-content-end algin-items-center gap-3 mt-4">
            {onClose && (
              <div
                className="pr-cancel-btn-with-icon"
                onClick={() => onClose()}
              >
                Cancel
              </div>
            )}
            {confirmation && (
              <div className="pr-btn-with-icon" onClick={() => confirmation()}>
                {buttonText ? buttonText : 'Confirm'}
              </div>
            )}
          </div>
        </Card>
      </div>
    </Modal>
  );
}
