import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { deleteTrack } from "actions/adminActions/tracksActions";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import MaterialTable from "material-table";

import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Container,
  Modal,
  Button,
  Row,
} from "reactstrap";

class Tracks extends React.Component {
  state = {
    notificationModal: false,
    trackId: null,
  };
  toggleModal = (state, trackId) => {
    this.setState({
      [state]: !this.state[state],
      trackId: trackId,
    });
  };

  render() {
    const { tracks } = this.props;
    return (
      <>
        <SimpleHeader name="React Tables" parentName="Tables" />
        <Container className="" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Tracks</h3>
                </CardHeader>
                <MaterialTable
                  title="Categories"
                  columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Description', field: 'description' },
                    { title: 'Categories', field: 'categories' },
                    {
                      title: '',
                      field: '',
                      align: 'right',
                      render: (item) => (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            color=""
                            role="button"
                            size="sm"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu
                            className="dropdown-menu-arrow table-dropdown-action"
                            right
                          >
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={() =>
                                this.toggleModal('notificationModal', item.id)
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ),
                    },
                  ]}
                  data={tracks}
                  options={{
                    actionsColumnIndex: -1,
                    sorting: true,
                    selection: true,
                    exportButton: true,
                    exportAllData: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#8898aa',
                      fontFamily: 'inherit',
                      fontSize: '12px',
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                    },

                    rowStyle: {
                      fontFamily: 'inherit',
                      fontSize: '13px',
                      color: '#525f7f',
                      padding: '2px',
                    },
                  }}
                />

                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="table-check-all"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="table-check-all"
                          />
                        </div>
                      </th>
                      <th className="sort" data-sort="name" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Description
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Categories
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {tracks &&
                      tracks.map((track) => {
                        return (
                          <tr key={track.id}>
                            <td>
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id={track.id}
                                  type="checkbox"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="table-check-all"
                                />
                              </div>
                            </td>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="name mb-0 text-sm">
                                    {track.name}
                                  </span>
                                </Media>
                              </Media>
                            </td>
                            <td className="budget">{track.description}</td>
                            <td className="budget">{track.categories}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={() =>
                                      this.toggleModal(
                                        "notificationModal",
                                        track.id
                                      )
                                    }
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
               */}
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={this.state.notificationModal}
          toggle={() => this.toggleModal('notificationModal')}
        >
          <div className="modal-body">
            <div className="">
              <h4 className="heading mt-2">
                Are you sure you want to delete? the selected track?
              </h4>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.props.deleteTrack(this.state.trackId);
                this.toggleModal('notificationModal');
              }}
            >
              Delete
            </Button>
            <Button
              className="text-white"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal('notificationModal')}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tracks: state.firestore.ordered.tracks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteTrack: (trackId) => dispatch(deleteTrack(trackId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    { collection: `vcr/db/tracks/`, storeAs: "tracks" },
  ])
)(Tracks);
