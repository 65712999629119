import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Checkbox } from '@mui/material';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { loaderType } from 'type';
import {
  createOrganisation,
  editOrganisation,
  getAllOrgUser,
  getOrgUsers,
} from 'actions/adminActions/organisationsActions';
import { updateUserOrganisationPassword } from 'actions/adminActions/usersActions';
import { hideAlert, showAlert } from 'actions/templateAction';
import SimpleHeader from 'components/Headers/SimpleHeader.jsx';
import { trackPromise } from 'react-promise-tracker';
import { getAllPhygitalLabAdmins } from 'actions/adminActions/organisationsActions';
const tabs = [
  {
    label: 'dashboard',
    value: 'isDashboard',
  },
  {
    label: 'Units',
    value: 'isUnits',
  },
  {
    label: 'Components',
    value: 'isComponents',
  },
  {
    label: 'Tools',
    value: 'isTools',
  },
  {
    label: 'Mitre Attack',
    value: 'isMitre',
  },
  {
    label: 'Quiz',
    value: 'isQuiz',
  },
  {
    label: 'Team',
    value: 'isTeams',
  },
  {
    label: 'Exam',
    value: 'isExams',
  },
  {
    label: 'CTF',
    value: 'isCTF',
  },
  {
    label: 'My Courses',
    value: 'isMycourses',
  },
  {
    label: 'Announcement',
    value: 'isAnnouncemnet',
  },
  {
    label: 'Modes',
    value: 'isModes',
  },
  {
    label: 'Users',
    value: 'isUser',
  },
  {
    label: 'Guest',
    value: 'isGuest',
  },
];
const statusList = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

const AddPhygitalLabAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);
  const [state, setState] = useState({
    name: '',
    nameErr: null,
    email: '',
    emailErr: null,
    phoneNumber: '',
    phoneNumberErr: null,
    password: '',
    passwordErr: null,
    sendEmail: true,
    userType: 'phygitalLabAdmin',
    tabAccess: [],
  });
  const users = useSelector((state) => state.organisation.orgData);

  useEffect(() => {
    // dispatch(getOrgUsers(id, 0, 1000));
    trackPromise(
      dispatch(getAllPhygitalLabAdmins(0, 2000)),
      loaderType.GENERAL_REQUEST
    );
  }, []);

  useEffect(() => {
    let findData = users.find((item) => item._id === id);
    setState({
      name: findData?.name,
      email: findData?.email,
      phoneNumber: findData?.phoneNumber,
      tabAccess: findData?.tabAccess || [],
    });
  }, [users]);

  const validateCustomStylesForm = async () => {
    let newState = state;
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    newState.nameErr = newState.name === '' ? 'invalid' : 'valid';
    newState.emailErr =
      newState.email === '' || !emailRegex.test(newState.email)
        ? 'invalid'
        : 'valid';
    newState.email = newState.email.trim();
    newState.phoneNumberErr = newState.phoneNumber === '' ? 'invalid' : 'valid';
    newState.passwordErr = newState.password === '' ? 'invalid' : 'valid';
    console.log('newState', newState);
    if (
      newState.nameErr === 'invalid' ||
      newState.emailErr === 'invalid' ||
      newState.phoneNumberErr === 'invalid' ||
      newState.passwordErr === 'invalid'
    ) {
      setState({ ...state, ...newState });
    } else {
      if (!id) {
        trackPromise(
          dispatch(
            createOrganisation({ ...state, userType: 'phygitalLabAdmin' })
          ),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => dispatch(showAlert(true, 'success', res.message)))
          .catch((err) => dispatch(showAlert(true, 'warning', err.message)));
      } else {
        updatePassword();
        trackPromise(
          dispatch(editOrganisation(id, state)),
          loaderType.GENERAL_REQUEST
        )
          .then((res) =>
            dispatch(showAlert(true, 'success', res?.data?.message))
          )
          .catch((err) => dispatch(showAlert(true, 'warning', err.message)));
      }
    }
  };

  const updatePassword = () => {
    if (state.password) {
      trackPromise(
        dispatch(updateUserOrganisationPassword(id, state.password)),
        loaderType.GENERAL_REQUEST
      )
        .then((res) => dispatch(showAlert(true, 'success', res.message)))
        .catch((err) => dispatch(showAlert(true, 'warning', err.message)));
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = state;
    newState[name] = value;
    newState[name + 'Err'] = value === '' ? 'invalid' : 'valid';
    setState({ ...state, ...newState });
  };

  const navigateTo = () =>
    type === 'success' ? history.push('/phygital-lab-Admin') : null;

  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo();
    }
  };

  const handleChecked = (e) => {
    const access = state.tabAccess;
    const { name, checked } = e.target;
    if (checked) {
      access.push(name);
    } else {
      const findIndex = access.findIndex((i) => i === name);
      access.splice(findIndex, 1);
    }
    console.log('access', access);
    setState({ ...state, tabAccess: access });
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {!id ? 'Add' : 'Edit'} Phygital Lab Admin
                      </h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/phygital-lab-Admin')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={validateCustomStylesForm}
                      >
                        {id ? 'Edit' : 'Add'} Phygital Lab Admin
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Name
                          </label>
                          <Input
                            value={state.name}
                            className="org_form_input"
                            name="name"
                            placeholder="Name"
                            type="text"
                            autoComplete="off"
                            valid={state.nameErr === 'valid'}
                            invalid={state.nameErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Email
                          </label>
                          <Input
                            value={state.email}
                            name="email"
                            placeholder="Email"
                            type="text"
                            autoComplete="off"
                            valid={state.emailErr === 'valid'}
                            invalid={state.emailErr === 'invalid'}
                            className="org_form_input"
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Phone Number
                          </label>
                          <Input
                            value={state.phoneNumber}
                            name="phoneNumber"
                            placeholder="Phone Number"
                            type="number"
                            autoComplete="off"
                            valid={state.phoneNumberErr === 'valid'}
                            invalid={state.phoneNumberErr === 'invalid'}
                            className="org_form_input"
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Password
                          </label>
                          <Input
                            value={state.password}
                            name="password"
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            valid={state.passwordErr === 'valid'}
                            invalid={state.passwordErr === 'invalid'}
                            className="org_form_input"
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Status
                        </label>
                        <Select
                          value={statusList.find(
                            (item) => item.value === state.isActive
                          )}
                          name="statusList"
                          options={statusList}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: '45px',
                              border: state.categoryIdsErr
                                ? '1px solid red'
                                : '1px solid #d2d6da',
                            }),
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) =>
                            setState({ ...state, status: e.value })
                          }
                        />
                      </Col>
                      <Col className="mt-4 mb-2" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Feature Access
                        </label>
                      </Col>

                      <Col className="mb-2" md="7">
                        <table className="tab-access-tbl">
                          <thead>
                            <tr>
                              <th>Feature</th>
                              <th>Access</th>
                            </tr>
                          </thead>

                          <tbody>
                            {tabs.map((feature, index) => (
                              <tr key={index}>
                                <td>{feature.label}</td>
                                <td>
                                  <Checkbox
                                    checked={state.tabAccess.includes(
                                      feature.value
                                    )}
                                    name={feature.value}
                                    id={feature.value}
                                    onClick={handleChecked}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddPhygitalLabAdmin;
