import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleHeader from 'components/Headers/SimpleHeader.jsx';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import SweetAlert from 'react-bootstrap-sweetalert';
import { showAlert, hideAlert } from 'actions/templateAction';
import { loaderType } from '../../../type';
import { trackPromise } from 'react-promise-tracker';
import {
  getAllUser,
  editUser,
  createUser,
  updateUserOrganisationPassword,
} from 'actions/adminActions/usersActions';
import { Checkbox } from '@mui/material';

const AddUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const users = useSelector((state) => {
    return state.users.userData;
  });
  console.log('users', users);
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);
  const [state, setState] = useState({
    name: '',
    nameErr: null,
    email: '',
    emailErr: null,
    phoneNumber: '',
    phoneNumberErr: null,
    password: '',
    passwordErr: null,
    userType: 'individual',
    sendEmail: true,
    tabAccess: [],
    labTypeAccess: [],
  });

  const validateCustomStylesForm = async () => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneNumberRegex = /^[0-9]{10}$/;
    let newState = state;

    if (newState.name === '') {
      newState.nameErr = 'invalid';
    } else {
      newState.nameErr = 'valid';
    }

    if (newState.email === '' || !emailRegex.test(newState.email)) {
      newState.emailErr = 'invalid';
    } else {
      newState.emailErr = 'valid';
      newState.email = newState.email.trim();
    }

    if (newState.phoneNumber && !phoneNumberRegex.test(newState.phoneNumber)) {
      newState.phoneNumberErr = 'invalid';
    } else {
      newState.phoneNumberErr = 'valid';
    }

    if (!id && newState.password === '') {
      newState.passwordErr = 'invalid';
    } else {
      newState.passwordErr = 'valid';
    }

    setState({
      ...state,
      ...newState,
    });

    if (
      newState.nameErr === 'valid' &&
      newState.emailErr === 'valid' &&
      newState.passwordErr === 'valid' &&
      (newState.phoneNumber === '' || newState.phoneNumberErr === 'valid')
    ) {
      if (!id) {
        trackPromise(dispatch(createUser(newState)), loaderType.GENERAL_REQUEST)
          .then((res) => {
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      } else {
        trackPromise(
          dispatch(editUser(id, newState)),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => {
            updatePassword();
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      }
    }
  };

  const updatePassword = () => {
    if (state.password) {
      trackPromise(
        dispatch(updateUserOrganisationPassword(id, state.password)),
        loaderType.GENERAL_REQUEST
      )
        .then((res) => {
          dispatch(showAlert(true, 'success', res.message));
        })
        .catch((err) => {
          dispatch(showAlert(true, 'warning', err.message));
        });
    }
  };

  const navigateTo = () => (type === 'success' ? history.push('/users') : null);
  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo();
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = state;
    newState[name] = value;

    if (name === 'phoneNumber') {
      newState[name + 'Err'] =
        value === '' || /^[0-9]{10}$/.test(value) ? 'valid' : 'invalid';
    } else if (name === 'email') {
      newState[name + 'Err'] = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? 'valid'
        : 'invalid';
    } else {
      newState[name + 'Err'] = value === '' ? 'invalid' : 'valid';
    }

    setState({
      ...state,
      ...newState,
    });
  };

  useEffect(() => {
    trackPromise(dispatch(getAllUser(0, 3000)), loaderType.GENERAL_REQUEST);
  }, [dispatch]);

  useEffect(() => {
    const selectedUserInfo = users?.length && users.find((i) => i._id === id);
    if (selectedUserInfo) {
      setState({
        ...state,
        name: selectedUserInfo.name,
        email: selectedUserInfo.email,
        phoneNumber: selectedUserInfo.phoneNumber,
        tabAccess: selectedUserInfo.tabAccess || [],
        labTypeAccess: selectedUserInfo.labTypeAccess || [],
      });
    }
  }, [users, id]);

  const handleChecked = (e) => {
    const access = [...state.tabAccess];
    const { name, checked } = e.target;
    if (checked) {
      access.push(name);
    } else {
      const findIndex = access.findIndex((i) => i === name);
      access.splice(findIndex, 1);
    }
    setState({
      ...state,
      tabAccess: access,
    });
  };

  const handleLabChecked = (e) => {
    const access = [...state.labTypeAccess];
    const { name, checked } = e.target;
    if (checked) {
      access.push(name);
    } else {
      const findIndex = access.findIndex((i) => i === name);
      access.splice(findIndex, 1);
    }
    setState({
      ...state,
      labTypeAccess: access,
    });
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {' '}
                        {id ? 'Edit' : 'Add'} User
                      </h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/users')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={validateCustomStylesForm}
                      >
                        {id ? 'Edit' : 'Add'} User
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate>
                    <div className="form-row">
                      <Col className="mb-3" md="7">
                        <label className="org_form_label my-2 mx-0">Name</label>
                        <Input
                          value={state.name}
                          className="org_form_input"
                          name="name"
                          placeholder="Name"
                          type="text"
                          valid={state.nameErr === 'valid'}
                          invalid={state.nameErr === 'invalid'}
                          onChange={customStylesForm}
                        />
                      </Col>
                      <Col className="mb-3" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Email
                        </label>
                        <Input
                          value={state.email}
                          className="org_form_input"
                          name="email"
                          placeholder="Email"
                          type="text"
                          valid={state.emailErr === 'valid'}
                          invalid={state.emailErr === 'invalid'}
                          onChange={customStylesForm}
                        />
                      </Col>
                      <Col className="mb-3" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Phone Number
                        </label>
                        <Input
                          value={state.phoneNumber}
                          className="org_form_input"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          type="text"
                          valid={
                            state.phoneNumber === '' ||
                            state.phoneNumberErr === 'valid'
                          }
                          invalid={
                            state.phoneNumber !== '' &&
                            state.phoneNumberErr === 'invalid'
                          }
                          onChange={customStylesForm}
                        />
                      </Col>

                      <Col className="mb-3" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Password
                        </label>
                        <Input
                          value={state.password}
                          className="org_form_input"
                          name="password"
                          placeholder="Password"
                          type="password"
                          valid={!id && state.passwordErr === 'valid'}
                          invalid={!id && state.passwordErr === 'invalid'}
                          onChange={customStylesForm}
                        />
                      </Col>

                      <Col classlassName="" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Feature Access
                        </label>
                      </Col>

                      <Col className="mb-4" md="7">
                        <table className="tab-access-tbl">
                          <thead>
                            <tr>
                              <th>Feature</th>
                              <th>Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Courses</td>
                              <td>
                                {' '}
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isMycourses'
                                  )}
                                  name="isMycourses"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Exams</td>
                              <td>
                                {' '}
                                <Checkbox
                                  checked={state.tabAccess.includes('isExams')}
                                  name="isExams"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Labs</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isLab')}
                                  name="isLab"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>

                      <Col classlassName="" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Lab Type Access
                        </label>
                      </Col>

                      <Col className="mb-4" md="7">
                        <table className="tab-access-tbl">
                          <thead>
                            <tr>
                              <th>Lab Type</th>
                              <th>Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Certification Lab</td>
                              <td>
                                {' '}
                                <Checkbox
                                  checked={state.labTypeAccess.includes(
                                    'certification'
                                  )}
                                  name="certification"
                                  onClick={handleLabChecked}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddUser;
