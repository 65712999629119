import React, { useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box, MenuItem, Typography } from "@mui/material";

import { Badge, Card, CardHeader, Container } from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import classNames from "classnames";
import { trackPromise } from "react-promise-tracker";
import { getAllLab } from "actions/adminActions/labsActions";
import { loaderType } from "type";
import { Link } from "react-router-dom";
import { deleteLab } from "actions/adminActions/labsActions";
import { getAllPlans } from "actions/adminActions/licenseActions";
import moment from "moment";
import { deletePlan } from "actions/adminActions/licenseActions";
import { enableLicenseActivation } from "actions/adminActions/licenseActions";
import { disableLicenseActivation } from "actions/adminActions/licenseActions";

const PackLicenses = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const labs = useSelector((state) => state.labs.labs);
  const plans = useSelector((state) => state.plans.plans);
  const [planId, setPlanId] = useState();

  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });

  useEffect(() => {
    // trackPromise(dispatch(getAllLab(0, 3000)), loaderType.GENERAL_REQUEST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        Cell: ({ row }) => (
          <>
            <Typography className="">{row?.original?.name}</Typography>
          </>
        ),
      },
      {
        header: "Allowed Categories",
        accessorKey: "fullName",
        Cell: ({ row }) => (
          <>
            {row?.original?.allowedCategories.map((item) => (
              <Typography className="">{item?.fullName}</Typography>
            ))}
          </>
        ),
      },

      {
        header: "Type",
        accessorKey: "type",
        Cell: ({ row }) => (
          <>
            <Typography className="">{row?.original?.type}</Typography>
          </>
        ),
      },
      {
        header: "Allowed Users",
        accessKey: "allowedUsersCount",
        Cell: ({ row }) => (
          <>
            <Typography className="">
              {row?.original?.allowedUsersCount}
            </Typography>
          </>
        ),
      },
      {
        header: "Active",
        Cell: ({ row }) => (
          <>
            {" "}
            <Badge color="" className="badge-dot mr-4">
              <i
                className={classNames(
                  { "bg-success": row?.original?.isActive },
                  { "bg-warning": !row?.original?.isActive }
                )}
              />
              <span
                className={classNames(
                  "status",
                  { "text-success": row?.original?.isActive },
                  { "text-warning": !row?.original?.isActive }
                )}
              >
                {row?.original?.isActive ? "Yes" : "No"}
              </span>
            </Badge>
          </>
        ),
      },
      {
        header: "Created On",
        accessorKey: "createdAt",
        Cell: ({ row }) => (
          <Typography className="">
            {moment(row?.original?.createdAt).format("DD-MM-YYYY ")}
          </Typography>
        ),
      },
      {
        header: "Validity",
        accessorKey: "validity",
        Cell: ({ row }) => (
          <Typography className="">{row?.original?.validity} days</Typography>
        ),
      },
      {
        header: "License Id",
        Cell: ({ row }) => (
          <Typography className="">{row?.original?._id}</Typography>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };
  const confirmAlert = () => {
    trackPromise(dispatch(deletePlan(planId)), loaderType.GENERAL_REQUEST);
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "success",
      message: "Licenses deleted successfully.",
    });
  };
  useEffect(() => {
    trackPromise(dispatch(getAllPlans(0, 300)), loaderType.GENERAL_REQUEST);
  }, [dispatch]);
  const handleLicenseActivation = (e, item) => {
    e.preventDefault();
    trackPromise(
      !item?.original?.isActive
        ? dispatch(enableLicenseActivation(item?.original))
        : dispatch(disableLicenseActivation(item?.original)),
      loaderType.GENERAL_REQUEST
    ).then(() => {
      trackPromise(dispatch(getAllPlans(0, 300)), loaderType.GENERAL_REQUEST);
    });
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Pack Licenses</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push('/addPackLicenses')}
              >
                <PlusIcon color="white" />
                {id ? 'Edit' : 'Add'} Pack Licenses
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={plans || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={() => {
                    history.push(`editPackLicenses/${row?.original?._id}`);
                  }}
                  sx={{ m: 0 }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={() => {
                    setPlanId(row?.original?._id);
                    setAlert({
                      isOpenAlert: true,
                      status: 'warning',
                      message: 'Are you sure you want to delete? this',
                    });
                  }}
                  sx={{ m: 0 }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={(e) => {
                    handleLicenseActivation(e, row);
                  }}
                  sx={{ m: 0 }}
                >
                  {!row?.original?.isActive
                    ? 'Enable License'
                    : 'Disable License'}
                  {/* Enable License */}
                </MenuItem>
              </>,
            ]}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'warning' && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === 'success' && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default PackLicenses;
