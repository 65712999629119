import React from 'react'
import { Card, Modal } from 'reactstrap'
import Select from "react-select";
import { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { getAllUser } from "actions/adminActions/usersActions";
import { useSelector } from 'react-redux';
import { useState } from 'react';


export default function AssignUserExam({ isOpen,existingUser, onClose, handleSubmit }) {
    const dispatch = useDispatch()
    const [userList, setUserList] = useState()
    const users = useSelector((state) => state.users.userData);
    const [selectedUsers,setSelectedUsers] = useState([])
    useEffect(() => {
        dispatch(getAllUser(0, 200,'individual'))
    }, [dispatch]);
    useEffect(() => {
        let userArray = []
         users.forEach((item,index) => {
            if(existingUser?.length && !existingUser.includes(item?._id)){
                let obj ={
                    id:index,
                    label: item?.name,
                    value: item?._id
                }
                userArray.push(obj)
            }
        })
        setUserList(userArray)
    }, [users])
    const handleSave = ()=>{
        if(selectedUsers.length){
            let selectedUsersIds = selectedUsers.map((item)=>item.value)
            handleSubmit(selectedUsersIds)
            setSelectedUsers([])
        }
    }
    return (
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={isOpen}
        //   toggle={() => this.toggleModal("defaultModal")}
      >
        <div className="modal-body p-0">
          <Card className="p-4 border-0 mb-0 w-100 ">
            <div className="mb-3">Assign Exam</div>
            <Select
              isMulti
              name="selectUsers"
              placeholder="Select Users..."
              options={userList}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              value={selectedUsers}
              onChange={(newValue) => {
                setSelectedUsers(newValue);
              }}
            />

            <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
              <div
                className="pr-cancel-btn-with-icon"
                onClick={() => {
                  onClose();
                  setSelectedUsers([]);
                }}
              >
                Cancel
              </div>
              <div className="pr-btn-with-icon" onClick={handleSave}>
                Save
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    );
}
