import React, { useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box, MenuItem, Typography } from "@mui/material";

import {
  Badge,
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import classNames from "classnames";
import { trackPromise } from "react-promise-tracker";
import { getAllLab } from "actions/adminActions/labsActions";
import { loaderType } from "type";
import { Link } from "react-router-dom";
import { deleteLab } from "actions/adminActions/labsActions";
import { getOrgUsers } from "actions/adminActions/organisationsActions";
import { getAllOrgUser } from "actions/adminActions/organisationsActions";
import { deleteOrganisation } from "actions/adminActions/organisationsActions";
import { getAllCategory } from "actions/adminActions/categoriesActions";
import { getCoursesApi } from "actions/adminActions/courseAction";
import { deleteCourse } from "actions/adminActions/courseAction";
import { deletePermanentCourse } from "actions/adminActions/courseAction";
import { restoreCourse } from 'actions/adminActions/courseAction';

const Course = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orgId, setOrgId] = useState(null);
  const [courseId, setCourseId] = useState();
  const [deleteType, setDeleteType] = useState('');

  const users = useSelector((state) => {
    return state.organisation.orgData;
  });
  // const plans = useSelector((state) => state.plans.plans);
  const courses = useSelector((state) => state.courses.courses);

  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  useEffect(() => {
    trackPromise(dispatch(getAllCategory(0, 500)), loaderType.GENERAL_REQUEST);
    trackPromise(dispatch(getCoursesApi(0, 500)), loaderType.GENERAL_REQUEST);
  }, []);

  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });

  const columns = useMemo(
    () => [
      {
        header: 'Course',
        accessorKey: 'courseName',
        Cell: ({ row }) => (
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              dispatch(getOrgUsers(row?.original._id, 0, 20));
              history.push(`/editcourse/${row?.original.id}`);
            }}
          >
            {/* <span className="avatar rounded-circle mr-3">
              <img
                alt="..."
                src={
                  row?.original?.profilePicUrl
                    ? row?.original?.profilePicUrl
                    : require("assets/img/theme/blank-profile.png")
                }
                style={{ height: "100%", width: "fit-content" }}
              />
            </span> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                marginRight: '20px',
              }}
            >
              <img
                alt="avatar"
                height={80}
                width={80}
                src={row?.original?.thumbnail}
                loading="lazy"
                style={{ borderRadius: '8px', objectFit: 'cover' }}
              />
            </Box>
            <Typography className="color-primary">
              {row?.original?.courseName}
              <br />
              <p className="text-muted">
                {row?.original?.isPublic ? 'Public Course' : 'Private Course'}
              </p>
            </Typography>
          </Box>
        ),
      },
      {
        header: 'Organisation Name',
        accessorKey: 'createdBy.name',
        Cell: ({ row }) => (
          <Typography className="">{row?.original?.createdBy?.name}</Typography>
        ),
      },

      {
        header: 'Description',
        Cell: ({ row }) => (
          <>
            <Typography
              className="tbl-description"
              dangerouslySetInnerHTML={{
                __html: row?.original.description,
              }}
            />
          </>
        ),
      },

      // {
      //   header: "Licence ID",
      //   Cell: ({ row }) => (
      //     <Typography className="">{row?.original?.licenseId?._id}</Typography>
      //   ),
      // },
      {
        header: 'Users Enrolled',
        Cell: ({ row }) => (
          <Typography className="">{row?.original?.usersEnrolled}</Typography>
        ),
      },
      {
        header: 'Status',
        Cell: ({ row }) => (
          <Badge color="" className="badge-dot mr-4">
            <i
              className={classNames(
                { 'bg-success': !row?.original?.isDraft },
                { 'bg-warning': row?.original?.isDraft },
                { 'bg-warning': row?.original?.isDeleted }
              )}
            />
            <span
              className={classNames(
                'status',
                {
                  'text-success':
                    !row?.original?.isDeleted && !row?.original?.isDraft,
                },
                {
                  'text-warning':
                    !row?.original?.isDeleted && row?.original?.isDraft,
                },
                { 'text-warning': row?.original?.isDeleted }
              )}
            >
              {!row?.original?.isDeleted && row?.original?.isDraft && 'Draft'}
              {!row?.original?.isDeleted &&
                !row?.original?.isDraft &&
                'Publish'}
              {row?.original?.isDeleted && 'Deleted'}
            </span>
          </Badge>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };
  const handleHardDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };
  const handlePutBack = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to restore this course?',
    });
  };
  const confirmAlert = () => {
    if (deleteType === 'soft') {
      trackPromise(
        dispatch(deleteCourse(courseId)),
        loaderType.GENERAL_REQUEST
      );
    }
    if (deleteType === 'hard') {
      trackPromise(
        dispatch(deletePermanentCourse(courseId)),
        loaderType.GENERAL_REQUEST
      );
    }
    if (deleteType === 'restore') {
      trackPromise(
        dispatch(restoreCourse(courseId)),
        loaderType.GENERAL_REQUEST
      );
    }
    setTimeout(() => {
      trackPromise(dispatch(getCoursesApi(0, 500)), loaderType.GENERAL_REQUEST);
    }, 300);
    setDeleteType('');
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'success',
      message: 'Course deleted successfully.',
    });
  };
  const MenuItemComponent = React.memo(
    ({ row, setDeleteType, setCourseId, handleDelete }) => (
      <>
        {row?.original?.isDeleted && (
          <MenuItem
            key={1}
            onClick={() => {
              setDeleteType('restore');
              setCourseId(row?.original?._id);
              handlePutBack();
            }}
            sx={{ m: 0 }}
          >
            Put back
          </MenuItem>
        )}
      </>
    )
  );

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">All Courses</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push('/addcourse')}
              >
                <PlusIcon color="white" />
                Add Course
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={courses || []}
            enableRowActions
            // enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={() => {
                    history.push(`/editcourse/${row.original?.id}`);
                  }}
                  sx={{ m: 0 }}
                >
                  Edit
                </MenuItem>
                {!row?.original?.isDeleted && (
                  <MenuItem
                    key={1}
                    onClick={() => {
                      setDeleteType('soft');
                      setCourseId(row?.original?._id);
                      handleDelete();
                    }}
                    sx={{ m: 0 }}
                  >
                    Soft Delete
                  </MenuItem>
                )}
                <MenuItemComponent
                  row={row}
                  setDeleteType={setDeleteType}
                  setCourseId={setCourseId}
                  handleDelete={handleDelete}
                />
                <MenuItem
                  key={1}
                  onClick={() => {
                    setDeleteType('hard');
                    setCourseId(row?.original?._id);
                    handleHardDelete();
                  }}
                  sx={{ m: 0 }}
                >
                  Hard Delete
                </MenuItem>
              </>,
            ]}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'warning' && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === 'success' && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default Course;
