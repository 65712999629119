const initialState = {
    examsData: [],
    exams:[]
}

const CourseExamsReducer = (state = initialState, action ) => {
    switch (action.type) {
    case "GET_CUSTOM_CATEGORY_SUCCESS":
         return { ...state, examsData: action.data.data.list }
    case "GET_EXAMS_SUCCESS":
        return { ...state, examsData: action.data.data.list }
    case "GET_EXAMS_FAILED":
        return { ...state,  }
    case 'GET_All_EXAMS_SUCCESS':
            return {
              ...state,
              exam: action.data,
            };
    case 'GET_ALL_EXAMS_FAILED':
            return {
              ...state,
              exam: [],
            };
    
    default:
        return state
    }
}

export default CourseExamsReducer 
