import React from "react";

export default function CloseIcon({ color }) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5799 25.0805C25.1006 24.5598 25.1006 23.7156 24.5799 23.1949L9.49495 8.10994C8.97425 7.58924 8.13003 7.58924 7.60933 8.10994C7.08863 8.63064 7.08864 9.47486 7.60933 9.99556L22.6943 25.0805C23.215 25.6012 24.0592 25.6012 24.5799 25.0805Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.60931 25.0805C7.08861 24.5598 7.08861 23.7156 7.60931 23.1949L22.6943 8.10994C23.215 7.58924 24.0592 7.58924 24.5799 8.10994C25.1006 8.63064 25.1006 9.47486 24.5799 9.99556L9.49493 25.0805C8.97423 25.6012 8.13001 25.6012 7.60931 25.0805Z"
        fill={color}
      />
    </svg>
  );
}
