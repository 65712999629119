import PlusIcon from "iconComponents/PlusIcon";
import TrashIcon from "iconComponents/TrashIcon";
import React from "react";
import { useState } from "react";
import Select, { components } from "react-select";
import {
  Card,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { GrSearch } from "react-icons/gr";
import SweetAlert from "react-bootstrap-sweetalert";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMyCourseExam } from "actions/adminActions/courseAction";
import { useSelector } from "react-redux";
import { getAllLab } from "actions/adminActions/labsActions";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import api2 from "api2";
import classNames from "classnames";
import ConfirmationModel from "components/Common/ConfirmationModel";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};

const CreateNewExam = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const { _id } = useParams();
  const history = useHistory();
  const [testName, setTestName] = useState();
  const [searchedQuiz, setSearchedQuiz] = useState("");
  const [searchedLab, setSearchedLab] = useState("");
  const [selectedQuiz, setSelectedQuiz] = useState();
  const [selectedLab, setSelectedLab] = useState();
  const [allQuiz, setAllQuiz] = useState([]);
  const [allLabs, setAllLabs] = useState();
  const [selectedQuizIndex, setSelectedQuizIndex] = useState();
  const [selectedLabIndex, setSelectedLabIndex] = useState();
  const [isDraft, setIsDraft] = useState();
  const [isShowError, setIsShowError] = useState(false);
  const [isValidField, setIsValidField] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });

  const [selectedQuizList, setSelectedQuizList] = useState([
    { label: '', value: '' },
  ]);
  const [selectedLabList, setSelectedLabList] = useState([
    { label: '', value: '' },
  ]);

  const myQuizList = useSelector((state) => state?.courses?.myQuizList);
  const allLab = useSelector((state) => state.labs.labs);

  const fetchCourseExam = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };

      const result = await api2.get(`/api/course/exam/${_id}`, config);
      setTestName(result.data?.data.name);
      const selectedQuizArray = result.data?.data.quiz.map((item, index) => {
        return {
          label: item.name,
          value: item._id,
        };
      });
      const selectedLabArray = result.data?.data.challengeIds.map(
        (item, index) => {
          return {
            label: item.title,
            value: item._id,
          };
        }
      );
      setSelectedQuizList(selectedQuizArray);
      setSelectedLabList(selectedLabArray);
      setIsDraft(result.data?.data?.isDraft);
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  useEffect(() => {
    if (_id) {
      fetchCourseExam();
    }
  }, []);
  useEffect(() => {
    dispatch(getMyCourseExam(0, 1000, 'quiz'));
    dispatch(getAllLab());
  }, [dispatch]);
  useEffect(() => {
    let quizArray = [];
    if (myQuizList?.length) {
      myQuizList.map((i) => {
        let matchQuiz = selectedQuizList.find((v) => v?.value === i?._id);
        if (!matchQuiz) {
          quizArray.push({
            label: i.name,
            value: i._id,
          });
        }
      });
      setAllQuiz(quizArray);
    }
  }, [selectedQuizList]);
  useEffect(() => {
    let labArray = [];
    if (allLab?.length) {
      allLab.map((i) => {
        let matchLab = selectedLabList.find((v) => v?.value === i?._id);
        if (!matchLab) {
          labArray.push({
            label: i?.title,
            value: i._id,
          });
        }
      });
      setAllLabs(labArray);
    }
  }, [selectedLabList]);
  const handleAddQuiz = () => {
    const selectedList = [...selectedQuizList];
    // if (selectedQuiz) {
    selectedList.push(null);
    setSelectedQuizList(selectedList);
    setSelectedQuizIndex(selectedList.length - 1);
    setSelectedQuiz(null);
    setSearchedQuiz(null);
    // }
  };
  const handleAddLab = () => {
    const selectedList = [...selectedLabList];
    // if (selectedLab) {
    selectedList.push(null);
    setSelectedLabList(selectedList);
    setSelectedLabIndex(selectedList.length - 1);
    setSelectedLab(null);
    setSearchedLab(null);
    // }
  };
  useEffect(() => {
    if (selectedQuizIndex >= 0) {
      const quizArray = [...selectedQuizList];
      quizArray.splice(selectedQuizIndex, 1, selectedQuiz);
      setSelectedQuizList(quizArray);
    }
  }, [selectedQuiz]);
  useEffect(() => {
    if (selectedLabIndex >= 0) {
      const labArray = [...selectedLabList];
      labArray.splice(selectedLabIndex, 1, selectedLab);
      setSelectedLabList(labArray);
    }
  }, [selectedLab]);
  const handleChangeName = (e) => {
    validationFuc();
    setTestName(e.target.value);
  };

  const handleDeleteQuiz = (index) => {
    const quizArray = [...selectedQuizList];
    quizArray.splice(index, 1);
    setSelectedQuizList(quizArray);
  };
  const handleDeleteLab = (index) => {
    const labArray = [...selectedLabList];
    labArray.splice(index, 1);
    setSelectedLabList(labArray);
  };
  const validationFuc = () => {
    let hasTestName = true;
    let hasQuizName = true;
    let hasChallenge = true;
    if (!testName) {
      hasTestName = false;
    }
    if (!selectedQuizList[0]?.label) {
      hasQuizName = false;
    }
    if (!selectedLabList[0]?.label) {
      hasChallenge = false;
    }
    let errObj = {
      ...isValidField,
      hasTestName,
      hasQuizName,
      hasChallenge,
    };
    setIsValidField(errObj);
  };
  const saveExam = async (isDraft) => {
    setIsShowError(true);
    validationFuc();
    const quiz = [];
    const challengeIds = [];
    if (
      selectedQuizList[0]?.label &&
      // selectedLabList[0]?.label &&
      testName
    ) {
      selectedQuizList.forEach((item) => {
        if (item?.value) {
          quiz.push(item?.value);
        }
      });

      selectedLabList.forEach((item) => {
        if (item?.value) {
          challengeIds.push(item?.value);
        }
      });
      let payload = {
        quiz,
        challengeIds,
        isDraft,
        listType: 'exam',
        name: testName,
      };
      try {
        const config = {
          headers: { Authorization: token },
        };
        if (!_id) {
          await api2.post(`/api/course/exam`, payload, config);
        } else {
          await api2.put(`/api/course/exam/${_id}`, payload, config);
        }

        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'success',
          message: _id
            ? 'Exam updated successfully!'
            : 'Exam added successfully!',
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error('Cancelled');
        }
      }
    }
  };
  const handleSaveAsDraft = () => {
    setIsDisabled(true);
    saveExam(true);
  };
  const handlePublish = () => {
    setIsDisabled(true);
    saveExam(false);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <GrSearch />
      </components.DropdownIndicator>
    );
  };
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card className="p-lg-4 ">
                <div className="add_course_page_header">
                  <div className="d-flex gap-3 align-items-center">
                    <h5 className="org_page_title">
                      {_id ? 'Edit' : 'Create '} Exams
                    </h5>
                    {isDraft && <span className="draft_badge">Draft</span>}
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <button
                      className="cancel_form_btn"
                      onClick={() => history.push('/tests')}
                    >
                      Cancel
                    </button>
                    {isDraft !== false && (
                      <button
                        className="draft_form_btn"
                        disabled={isDisabled}
                        onClick={handleSaveAsDraft}
                      >
                        Save Draft
                      </button>
                    )}
                    <button
                      className="publish_form_btn"
                      disabled={isDisabled}
                      onClick={handlePublish}
                    >
                      Publish
                    </button>
                  </div>
                </div>

                <Form role="form" className="add_exam_form">
                  <Row className="">
                    <div className="col-md-8 my-3 form-content-wrapper-main">
                      <div className="exam_title_box_wrapper">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Test Name
                          </label>
                          <Input
                            placeholder="Your Test Name"
                            type="text"
                            autoComplete="off"
                            name="questionTitle"
                            className="org_form_input"
                            value={testName}
                            valid={
                              !isShowError &&
                              isValidField &&
                              isValidField?.hasTestName
                            }
                            invalid={
                              isShowError &&
                              isValidField &&
                              !isValidField?.hasTestName
                            }
                            onChange={handleChangeName}
                          />
                        </FormGroup>
                      </div>

                      {/* Select Quiz */}
                      <div className="form-content-wrapper">
                        {/* <FormGroup className="w-100">
                          <label className="org_form_label my-2 mx-0">
                            Select Quiz
                          </label>
                          <Select
                            name="colors"
                            components={{
                              DropdownIndicator,
                              IndicatorSeparator: () => null,
                            }}
                            placeholder="Search Quiz"
                            options={allQuiz}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            styles={customStyles}
                            value={searchedQuiz}
                            onChange={(value) => {
                              if (!selectedQuizIndex) {
                                setSelectedQuizIndex(
                                  selectedQuizList.length - 1
                                );
                              }
                              setSearchedQuiz(value);
                              setSelectedQuiz(value);
                              setIsValidField({
                                ...isValidField,
                                hasQuizName: true,
                              });
                            }}
                          />
                        </FormGroup> */}

                        <FormGroup className="w-100">
                          <label className="org_form_label my-2 mx-0">
                            Selected Quizzes
                          </label>
                          {selectedQuizList.length ? (
                            <>
                              {selectedQuizList.map((item, index) => (
                                <div className="d-flex flex-column gap-3 mt-2">
                                  <div className="d-flex align-items-center gap-4 w-100">
                                    <Select
                                      key={index}
                                      name="colors"
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      placeholder="Select Quiz"
                                      options={allQuiz}
                                      // className="basic-multi-select w-100"
                                      className={classNames({
                                        'basic-multi-select w-100': true,
                                        'is-error':
                                          isShowError &&
                                          isValidField &&
                                          !isValidField?.hasQuizName,
                                        'is-success':
                                          !isShowError &&
                                          isValidField &&
                                          isValidField?.hasQuizName,
                                      })}
                                      classNamePrefix="select"
                                      value={item?.value ? item : selectedQuiz}
                                      onChange={(value) => {
                                        setSelectedQuizIndex(index);
                                        setSelectedQuiz(value);
                                        setSearchedQuiz(null);
                                        setIsValidField({
                                          ...isValidField,
                                          hasQuizName: true,
                                        });
                                      }}
                                    />
                                    {selectedQuizList.length > 1 && (
                                      <div
                                        className="form-content-remove cursor-pointer"
                                        type="button"
                                        onClick={() => handleDeleteQuiz(index)}
                                      >
                                        <TrashIcon color={'#EF466F'} />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </FormGroup>

                        <div
                          className="add_new_input w-100"
                          onClick={handleAddQuiz}
                        >
                          <PlusIcon color={'#070707'} />
                          <span className="add_new_title disabled">
                            Add Quiz
                          </span>
                        </div>
                      </div>

                      {/* Select Challenge */}
                      <div className="form-content-wrapper">
                        {/* <FormGroup className="w-100">
                          <label className="org_form_label my-2 mx-0">
                            Select Challenge
                          </label>
                          <Select
                            //   defaultValue={[colourOptions[2], colourOptions[3]]}
                            // isMulti
                            name="colors"
                            components={{
                              DropdownIndicator,
                              IndicatorSeparator: () => null,
                            }}
                            placeholder="Search Challenge"
                            options={allLabs}
                            classNamePrefix="select"
                            value={searchedLab}
                            onChange={(value) => {
                              if (!selectedLabIndex) {
                                setSelectedLabIndex(selectedLabList.length - 1);
                              }
                              setSearchedLab(value);
                              setSelectedLab(value);
                              setIsValidField({
                                ...isValidField,
                                hasChallenge: true,
                              });
                            }}
                          />
                        </FormGroup> */}

                        <FormGroup className="w-100">
                          <label className="org_form_label my-2 mx-0">
                            Selected Challenges
                          </label>
                          <>
                            {selectedLabList.map((item, index) => (
                              <div className="d-flex flex-column gap-3 mt-2">
                                <div className="d-flex align-items-center gap-4 w-100">
                                  <Select
                                    name="colors"
                                    options={allLabs}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    placeholder="Select Challenges"
                                    className={classNames({
                                      'basic-multi-select w-100': true,
                                      // "is-error":
                                      //   isShowError &&
                                      //   isValidField &&
                                      //   !isValidField?.hasChallenge,
                                      // "is-success":
                                      //   !isShowError &&
                                      //   isValidField &&
                                      //   isValidField?.hasChallenge,
                                    })}
                                    classNamePrefix="select"
                                    value={item?.value ? item : selectedLab}
                                    onChange={(value) => {
                                      setSelectedLabIndex(index);
                                      setSelectedLab(value);
                                      setSearchedLab(null);
                                      setIsValidField({
                                        ...isValidField,
                                        hasChallenge: true,
                                      });
                                    }}
                                  />

                                  {selectedLabList.length > 1 && (
                                    <div
                                      className="form-content-remove cursor-pointer"
                                      type="button"
                                      onClick={() => handleDeleteLab(index)}
                                    >
                                      <TrashIcon color={'#EF466F'} />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        </FormGroup>

                        <div
                          className="add_new_input w-100"
                          onClick={handleAddLab}
                        >
                          <PlusIcon color={'#070707'} />
                          <span className="add_new_title disabled">
                            Add Challenge
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Form>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      {alert.status === 'success' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          buttonText="OK"
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            setAlert({ isOpenAlert: false });
            history.push('/tests');
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
        />
      )}
      {alert.status === 'warning' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            setAlert({ isOpenAlert: false });
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onClose={() => {
            setAlert({
              isOpenAlert: false,
            });
          }}
        />
      )}
    </>
  );
};

export default CreateNewExam;
