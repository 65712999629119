import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Button,
  Row,
  CardBody,
  Form,
  Modal,
} from "reactstrap";
import MaterialTable from "material-table";
//import { useHistory ,useLocation} from "react-router-dom";
import { hideAlert } from "actions/templateAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { loaderType } from "../../../type";
import { trackPromise } from "react-promise-tracker";
import classnames from "classnames";
import Select2 from "react-select2-wrapper";
import {
  deleteExams,
  getExamsApi,
  fetchAllExams,
} from "../../../actions/adminActions/examsAction";
import axios from "axios";
import API from "api2";
import {
  getExamLabs,
  updateExamLabs,
  updateExams,
} from "../../../actions/adminActions/examlabsActions";
const AddExamToCource = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const examsData = useSelector((state) => state.examsData.examsData);
  const [notificationModal, setNotificationModal] = useState(false);
  const [editData, setEditData] = useState({
    alert: false,
    alertStatus: "",
    alertMsg: "",
    navigateTo: null,
    _id: props.location.state.data._id ? props.location.state.data._id : "",
    allowedCategories: [],
    allowedChallenges: [],
    examChallenges: [],
    exams: [],
  });
  const [editStatus, setEditStatus] = useState(false);
  const [examRestricted, setexamRestricted] = useState(true);
  const [categoryRestricted, setCategoryRestricted] = useState(true);
  const [labsRestricted, setlabsRestricted] = useState(true);
  const location = useLocation();
  const data = location.state ? location.state.data : {};

  // const { visible: alertVisible, type, msg } = useSelector(state => state.template);

  useEffect(() => {
    if (props.location) {
      //   id:x._id,
      // })))

      setEditStatus(true);
      //setEditData(props.location.state.data);
    }
    trackPromise(dispatch(getExamsApi(0, 300)), loaderType.GENERAL_REQUEST);
    // let ids = examsData.map(x=>({
    //   id : x._id,
    // }))

    setEditData({
      ...editData,
      _id: props.location.state.data._id,
      examChallenges: props.location.state.data.examChallenges,
      exams: props.location.state.data.exams,
    });
  }, [dispatch]);

  const toggleModal = () => {
    setNotificationModal(!notificationModal);
  };

  const validateCustomStylesForm = async () => {
    let newState = editData;
    if (newState.type === "") {
      newState.typeErr = "invalid";
    } else {
      newState.typeErr = "valid";
    }

    if (newState.validity === "") {
      newState.validityErr = "invalid";
    } else {
      newState.validityErr = "valid";
    }
    if (newState.typeErr === "invalid" || newState.contentErr === "invalid") {
      setEditData({
        ...editData,
        ...newState,
      });
    } else {
      if (editData._id == "") {
        history.push({ pathname: `/o/my-course` });
      } else {
        trackPromise(dispatch(updateExams(editData._id, editData)))
          .then((res) => {
            // setEditData({
            //   alert: true,
            //   alertStatus: "success",
            //   alertMsg: "Exam update successfully!",
            //   redirectPath: "/o/my-course",
            //    pathname: `/o/my-course`
            // })
            showAlert(true, "success", "Exam update successfully!");
            // history.push({pathname:`/o/my-course`});
          })
          .catch((err) => {
            showAlert(true, "warning", err);
          });
      }
    }
  };

  const showAlert = (visible, status, msg) => {
    setEditData({
      alert: visible,
      alertStatus: status,
      alertMsg: msg,
    });
  };
  const capFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = editData;
    newState[name] = value;
    if (value === "") {
      newState[name + "Err"] = "invalid";
    } else {
      newState[name + "Err"] = "valid";
    }
    setEditData({
      ...editData,
      ...newState,
    });
  };

  const categoryIdsHander = useCallback(
    (e, stateName) => {
      let newState = editData;
      let targetValue = e.params.data.id;
      let isExist =
        newState[stateName].findIndex((x) => x === targetValue) >= 0;

      if (isExist) {
        newState[stateName] = newState[stateName].filter(
          (x) => x !== targetValue
        );
      } else {
        newState[stateName].push(targetValue);
      }

      setEditData(newState);
    },
    [editData.exams, editData.allowedCategories, editData.examChallenges]
  );

  const test = (data) => {};

  const closeAlert = () => {
    setEditData({
      alert: false,
      alertStatus: "",
      alertMsg: "",
      // redirectPath: "/o/my-course",
    });
    history.push({ pathname: `/allcourse` });
  };

  return (
    <>
      <SimpleHeader name="Assign Exam To Course" parentName="Groups" />
      {/* <SimpleHeader name="React Tables" parentName="Tables" /> */}
      {/* {alertVisible && <SweetAlert success={type === 'success'} warning={type === 'warning'} title={msg} onConfirm={handleConfirm} />}
          {!editStatus ? (
            <Container className="" fluid>
              <Row>
      
              </Row>
            </Container>
          ) : ( */}

      <Container className=" mb-6" fluid>
        {/* {test(editData)} */}
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    {editData.exams != "" ? "Edit" : "Add"} Exams
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom07"
                        >
                          Exams
                        </label>
                        <Select2
                          className="form-control"
                          multiple
                          onSelect={(e) => categoryIdsHander(e, "exams")}
                          onUnselect={(e) => categoryIdsHander(e, "exams")}
                          value={editData.examChallenges}
                          valid={
                            editData.examChallenges &&
                            editData.examChallenges > 0
                          }
                          options={{
                            placeholder: "Please select Exams",
                          }}
                          data={(examRestricted
                            ? examsData
                            : editData
                            ? editData.exams
                            : []
                          ).map((x, i) => {
                            return {
                              id: examRestricted ? x._id : x,
                              text: x.examName,
                              value: examRestricted ? x._id : x,
                            };
                          })}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                    </div>
                    {editData.alert && editData.alertStatus === "success" ? (
                      <SweetAlert
                        success
                        title={editData.alertMsg}
                        onConfirm={() => {
                          closeAlert();
                        }}
                      />
                    ) : editData.alertStatus === "warning" ? (
                      <SweetAlert
                        warning
                        title={editData.alertMsg}
                        onConfirm={() => {
                          closeAlert();
                        }}
                      />
                    ) : null}

                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        validateCustomStylesForm();
                      }}
                    >
                      {editData.exams != "" ? "Edit" : "Add"} Exams
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>

      {/* )} */}
      {/* <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={notificationModal}
            toggle={toggleModal}
          >
            <div className="modal-body">
              <div className="">
                <h4 className="heading mt-2">
                  Are you sure you want to delete? the selected license?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                data-dismiss="modal"
                type="button"
                // onClick={() => {
                //   trackPromise(dispatch(deletePlan(planId)), loaderType.GENERAL_REQUEST);
                //   toggleModal();
                // }}
              >
                Delete
              </Button>
              <Button
                className="text-white"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal()}
              >
                Cancel
              </Button>
            </div>
          </Modal> */}
    </>
  );
};

export default AddExamToCource;
