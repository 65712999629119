// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import MaterialTable from "material-table";
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { CardStats } from 'components/Card/CardStats';
import { getUserStatistics } from 'actions/adminActions/usersActions';
import { trackPromise } from 'react-promise-tracker';
import { loaderType } from 'type';
import { ChartPie } from '../../../components/Card/ChartPie';
import { useLocation } from 'react-router';
import { CardProfile } from '../../../components/Card/CardProfile';
import moment from 'moment';
import classNames from 'classnames';
import { fetchAllFreeLab } from 'actions/adminActions/usersActions';

const UserStats = (props) => {
  const [freeLabs, setFreeLabs] = useState([]);
  const [completedLabs, setCompletedLabs] = useState([]);
  const [startedLabs, setStartedLabs] = useState([]);
  let userStats = useSelector((state) => state.users.userStats);
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location && location.state && location.state.data;

  useEffect(() => {
    trackPromise(dispatch(fetchAllFreeLab()), loaderType.GENERAL_REQUEST);
    trackPromise(
      dispatch(getUserStatistics(props.match.params.id)),
      loaderType.GENERAL_REQUEST
    );
  }, []);

  let solvedData = userStats && userStats.numberOflabsSolved;
  let pendingData = userStats && userStats.numberOflabsPending;
  let solvedFreeData = userStats && userStats.completedWLabs?.length;
  let pendingFreeData = userStats && userStats.numberWlabsOflabsPending;

  const dataLabs = [solvedData, pendingData];
  const dataFreeLabs = [solvedFreeData, pendingFreeData];

  const labsColumn = [
    {
      title: 'Challenge',
      field: 'challenge',
      render: (item) => {
        return <span>{item.challengeId && item.challengeId.title}</span>;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.challengeId.title.toLowerCase()?.includes(term.toLowerCase()),
    },
  ];
  const findData = (data) => {
    const selectedLabs = [...completedLabs, ...startedLabs];
    let data1 = [];
    if (selectedLabs.length) {
      let findLabs = selectedLabs.filter(
        (item) => item?.challengeId?._id === data?._id
      );
      if (findLabs.length) {
        const completed = findLabs.find((item) => item.status === 'Completed');
        const started = findLabs.find((item) => item.status === 'Started');
        data1.push({ ...completed, ...started });
      } else {
        data1.push(findLabs[0]);
      }
      return data1[0];
    }
  };
  const freeLabColumn = useMemo(
    () => [
      {
        title: 'Name',
        field: 'name',
        render: (item) => {
          return <span>{item?.name}</span>;
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.name.toLowerCase().includes(term.toLowerCase()),
      },

      {
        title: 'Status',
        field: 'Status',
        render: (item) => {
          return (
            <span>
              <Badge color="" className="badge-dot mr-4">
                <i
                  className={classNames(
                    {
                      'bg-success': findData(item)?._id
                        ? findData(item)?.completedAt
                        : '',
                    },
                    {
                      'bg-warning': findData(item)?._id
                        ? !findData(item)?.completedAt
                        : '',
                    }
                  )}
                />
                <span
                  className={classNames(
                    'status',
                    {
                      'text-success': findData(item)?._id
                        ? findData(item)?.completedAt
                        : '',
                    },
                    {
                      'text-warning': findData(item)?._id
                        ? !findData(item)?.completedAt
                        : '',
                    }
                  )}
                >
                  {findData(item)?._id
                    ? findData(item)?.completedAt
                      ? 'Completed'
                      : 'Started'
                    : 'Not Started'}
                </span>
              </Badge>{' '}
            </span>
          );
        },
      },

      {
        title: 'Started Date',
        field: 'startedAt',
        render: (item) => {
          return (
            <span>
              {findData(item)?._id
                ? findData(item)?.startedAt
                  ? moment(findData(item)?.startedAt).format('DD MMM YYYY')
                  : ''
                : ''}
            </span>
          );
        },
      },
      {
        title: 'Completed Date',
        field: 'completedAt',
        render: (item) => {
          return (
            <span>
              {findData(item)?._id
                ? findData(item)?.completedAt
                  ? moment(findData(item)?.completedAt).format('DD MMM YYYY')
                  : ''
                : ''}
            </span>
          );
        },
      },
    ],
    [completedLabs, startedLabs]
  );

  useEffect(() => {
    let completedChallenges = [];
    completedChallenges = userStats?.completedWLabs?.length
      ? userStats?.completedWLabs.map((item) => ({
          ...item,
          status: 'Completed',
        }))
      : [];

    let startedChallenges = [];
    startedChallenges = userStats?.startedWLabs?.length
      ? userStats?.startedWLabs.map((item) => ({
          ...item,
          status: 'Started',
        }))
      : [];

    setCompletedLabs(completedChallenges);
    setStartedLabs(startedChallenges);
  }, [userStats]);
  return (
    <>
      <SimpleHeader name="React Tables" parentName="Tables" />
      <Container className="" fluid>
        <Row>
          <div className="col">
            <Card className="pb-4">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">User Statistics</h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="">
                <Row>
                  <Col className="">
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                      }}
                    >
                      <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Solved labs"
                          value={userStats && userStats.numberOflabsSolved}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Pending labs"
                          value={userStats && userStats.numberOflabsPending}
                        />
                      </div>

                      {/* <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Ongoing labs"
                          value={userStats && userStats.ongoingLabs}
                        />
                      </div> */}

                      <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Total points"
                          value={userStats && userStats.totalPoints}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Total time spent"
                          value={
                            userStats && userStats.totalTimeSpent
                              ? `${moment
                                  .utc(userStats.totalTimeSpent)
                                  .format('HH')} Hours, ${moment
                                  .utc(userStats.totalTimeSpent)
                                  .format('mm')} Minutes`
                              : 0
                          }
                        />
                      </div>
                      <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Free Labs Solved"
                          value={
                            (userStats && userStats.completedWLabs?.length) || 0
                          }
                        />
                      </div>
                      <div className="col-xl-3 col-lg-6 mb-4">
                        <CardStats
                          title="Free Tab Pending"
                          value={
                            userStats && userStats.numberWlabsOflabsPending
                          }
                        />
                      </div>
                      {/* <div className="col-xl-4 col-lg-6 mb-4">
                        <CardStats
                          title="Free Labs Ongoing"
                          value={
                            (userStats && userStats.startedWLabs?.length) || 0
                          }
                        />
                      </div> */}
                      {/* <div className="col-xl-4 col-lg-6 mb-4">
                        <CardStats
                          title="Free Labs Total time spent"
                          value={0}
                        />
                      </div> */}
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 ">
                  <Col className="mb-4 " lg="6" xl="12">
                    <CardProfile />
                  </Col>
                  <Col className="mb-4 " lg="6" xl="6">
                    <div className="card card-profile">
                      <div className="card-body pt-0">
                        {console.log(
                          'nikunj',
                          userStats.labData
                            ? userStats.labData
                            : [].filter((x) => x.completionStatus)
                        )}
                        <MaterialTable
                          title="Labs Data"
                          columns={labsColumn}
                          data={
                            userStats.labData
                              ? userStats.labData
                              : [].filter((x) => x.completionStatus)
                          }
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            headerStyle: {
                              backgroundColor: '#F6F9FC',
                              color: '#8898aa',
                              fontFamily: 'inherit',
                              fontSize: '12px',
                              textTransform: 'uppercase',
                              fontWeight: '600',
                            },

                            rowStyle: {
                              fontFamily: 'inherit',
                              fontSize: '13px',
                              color: '#525f7f',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 " lg="6" xl="6">
                    <div className="card card-profile">
                      <div className="card-body pt-0">
                        <MaterialTable
                          title="Free Labs Data"
                          columns={freeLabColumn}
                          data={props?.allFreeLabs || []}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            headerStyle: {
                              backgroundColor: '#F6F9FC',
                              color: '#8898aa',
                              fontFamily: 'inherit',
                              fontSize: '12px',
                              textTransform: 'uppercase',
                              fontWeight: '600',
                            },

                            rowStyle: {
                              fontFamily: 'inherit',
                              fontSize: '13px',
                              color: '#525f7f',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col className="mb-4 " lg="6" xl="6">
                    <ChartPie data={dataLabs} title={'Labs'} />
                  </Col>

                  <Col className="mb-4 " lg="6" xl="6">
                    <ChartPie data={dataFreeLabs} title={'Free Labs'} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.authState.profile,
    stats: state.authState.stats,
    orgProfile: state.authState.orgProfile,
    allFreeLabs: state?.users?.allFreeLabs?.data,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(UserStats);
