const initState = {
  mcqList: {},
  challengesList: {},
  courses:[]
};

const courseReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_COURSE_SUCCESS":
      return {
        ...state,
        message: "Course created Successfully!",
      };
    case "GET_COURSES_SUCCESS":
      return {
        ...state,
        courses: action.data,
        message: "course fetch Successfully!",
      };
    case "GET_COURSES_FAILED":
      return { ...state };
    case "GET_MCQ_SUCCESS":
      return { ...state, mcqList: action.data.data };
    case "GET_ANNOUNCEMENT_SUCCESS":
      return { ...state, announcement: action.data };
    case "GET_ANNOUNCEMENT_FAILED":
      return { ...state, announcement: {} };

    case "GET_CHALLENGE_SUCCESS":
      return { ...state, challengesList: action.data.data };
    case "FETCH_MY_COURSE_SUCCESS":
      return {
        ...state,
        myCourses: {
          list: action.list,
          loading: false,
        },
      };
    case "DELETE_COURSE_SUCCESS":
      return {
        ...state,
        courses: action.data,
        message: "course deleted Successfully!",
      };
    case "UPDATE_COURSE_SUCCESS":
      return {
        ...state,
        message: "course updated Successfully!",
      };
      case "GET_QUIZ_SUCCESS":
      return {
        ...state,
        myQuizList: action.data?.list.length ? action.data?.list : [],
      };    case "GET_EXAM_SUCCESS":
      return {
        ...state,
        myExamList: action.data?.list.length ? action.data?.list : [],
      };
    default:
      return state;
  }
};

export default courseReducer;