import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
// replace this variable, with your own config variable
// from your firebase project
var config = {
  apiKey: "AIzaSyCP2OR27OjBp-xTXSGHdnt44mIGlY3DMrc",
  authDomain: "debug-vcr.firebaseapp.com",
  databaseURL: "https://debug-vcr.firebaseio.com",
  projectId: "debug-vcr",
  storageBucket: "debug-vcr.appspot.com",
  messagingSenderId: "988268468012",
  appId: "1:988268468012:web:88dfa205efef53a81f7e89",
  measurementId: "G-RC6HM26T3L"
};
let firebaseConfig = firebase.initializeApp(config);
export default firebaseConfig;
