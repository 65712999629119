import React from "react";

export default function EditIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4.66667C2 3.19391 3.19391 2 4.66667 2H7.33333C7.70152 2 8 2.29848 8 2.66667C8 3.03486 7.70152 3.33333 7.33333 3.33333H4.66667C3.93029 3.33333 3.33333 3.93029 3.33333 4.66667V11.3333C3.33333 12.0697 3.93029 12.6667 4.66667 12.6667H11.3333C12.0697 12.6667 12.6667 12.0697 12.6667 11.3333V8.66667C12.6667 8.29848 12.9651 8 13.3333 8C13.7015 8 14 8.29848 14 8.66667V11.3333C14 12.8061 12.8061 14 11.3333 14H4.66667C3.19391 14 2 12.8061 2 11.3333V4.66667Z"
        fill={color}
      />
      <path
        opacity="0.986784"
        d="M11.3334 1.33398C11.1567 1.33398 10.9794 1.39665 10.8541 1.52132C9.85409 2.52132 9.85409 2.52132 9.52075 2.85466L4.85409 7.52132C4.72942 7.64666 4.66675 7.82398 4.66675 8.00065V10.6673C4.66675 11.0353 4.96541 11.334 5.33341 11.334C5.66675 11.334 7.66675 11.334 8.00008 11.334C8.17675 11.334 8.35408 11.2713 8.47941 11.1466L13.1461 6.47998C13.4794 6.14665 13.4794 6.14665 14.4794 5.14665C14.6041 5.02131 14.6667 4.84398 14.6667 4.66732C14.6667 3.57665 14.3887 2.79733 13.8127 2.20866C13.2307 1.61533 12.4541 1.33398 11.3334 1.33398ZM11.6047 2.67997C12.2061 2.71531 12.5868 2.85264 12.8541 3.12598C13.1274 3.40464 13.2847 3.82532 13.3214 4.41532C12.9954 4.74132 12.7934 4.93466 12.6694 5.05933C12.1407 4.53133 11.4701 3.86664 10.9421 3.33797C11.0667 3.21331 11.2787 3.00597 11.6047 2.67997ZM10.0014 4.28467L11.7248 6.00334L7.70808 10.0007C7.28675 10.0007 6.61408 10.0007 6.00008 10.0007V8.29264L10.0014 4.28467Z"
        fill={color}
      />
    </svg>
  );
}
