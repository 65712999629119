import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { useHistory } from "react-router";

import {
  createPlan,
  createCoursePlan,
} from "actions/adminActions/plansActions";
import { hideAlert, showAlert } from "actions/templateAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackPromise } from "react-promise-tracker";
import Select from "react-select";
import { getAllCategory } from "actions/adminActions/categoriesActions";
import { getAllLab } from "actions/adminActions/labsActions";
import { loaderType } from "../../../type";

const AddCourseLabs = (props) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const {
  //   visible: alertVisible,
  //   type,
  //   msg,
  // } = useSelector((state) => state.template);
  // const [state, setState] = useState({
  //   name: "",
  //   allowedUsersCount: null,
  //   type: "",
  //   courseid: "",
  //   typeErr: null,
  //   validity: "",
  //   validityErr: null,
  //   isCategoryViewRestricted: true,
  //   allowedCategories: [],
  //   allowedChallenges: [],
  // });
  // const categories = useSelector((state) => state.categories.categories);
  // const courses = useSelector((state) => state.courses.courses);
  // const labs = useSelector((state) => state.labs.labs);

  // const [categoryRestricted, setCategoryRestricted] = useState(true);
  // const [labsRestricted, setlabsRestricted] = useState(true);
  // useEffect(() => {
  //   // const location = props?.location;
  //   // const data = location?.state?.data;
  //   setState({
  //     ...state,
  //     courseid: props.location.state.data._id,
  //   });
  //   trackPromise(dispatch(getAllCategory(0, 50)), loaderType.GENERAL_REQUEST);
  //   trackPromise(dispatch(getAllLab(0, 3000)), loaderType.GENERAL_REQUEST);
  // }, []);

  // const validateCustomStylesForm = async () => {
  //   let newState = state;
  //   // if (newState.type === "") {
  //   //   newState.typeErr = "invalid";
  //   // } else {
  //   //   newState.typeErr = "valid";
  //   // }

  //   // if (newState.validity === "") {
  //   //   newState.validityErr = "invalid";
  //   // } else {
  //   //   newState.validityErr = "valid";
  //   // }

  //   // if (
  //   //   newState.typeErr === "invalid" ||
  //   //   newState.contentErr === "invalid"
  //   // ) {
  //   //   setState({
  //   //     ...state,
  //   //     ...newState,
  //   //   });
  //   // }
  //   //  else {
  //   trackPromise(dispatch(createCoursePlan(state)), loaderType.GENERAL_REQUEST)
  //     .then((res) => {
  //       dispatch(showAlert(true, "success", res.message));
  //     })
  //     .catch((err) => {
  //       dispatch(showAlert(true, "warning", err));
  //     });
  //   // }
  // };

  // const customStylesForm = (e) => {
  //   const { name, value } = e.target;
  //   let newState = state;
  //   newState[name] = value;
  //   if (value === "") {
  //     newState[name + "Err"] = "invalid";
  //   } else {
  //     newState[name + "Err"] = "valid";
  //   }
  //   setState({
  //     ...state,
  //     ...newState,
  //   });
  // };

  // const navigateTo = () =>
  //   type === "success" ? history.push("/allcourse") : null;

  // const handleConfirm = () => {
  //   const isSuccess = type === "success";
  //   dispatch(hideAlert());
  //   if (isSuccess) {
  //     navigateTo();
  //   }
  // };

  // const categoryIdsHander = (e, stateName) => {
  //   let newState = state;
  //   let targetValue = e.params.data.id;
  //   let isExist = newState[stateName].findIndex((x) => x === targetValue) >= 0;

  //   if (isExist) {
  //     newState[stateName] = newState[stateName].filter(
  //       (x) => x !== targetValue
  //     );
  //   } else {
  //     newState[stateName].push(targetValue);
  //   }

  //   setState(newState);
  // };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {/* {alertVisible && (
        <SweetAlert
          success={type === "success"}
          warning={type === "warning"}
          title={msg}
          onConfirm={handleConfirm}
        />
      )} */}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">Add Course Lab</h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push("/allcourse")}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        // onClick={() => {
                        //   validateCustomStylesForm();
                        // }}
                      >
                        Add Course Lab
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate>
                    <Row>
                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Category
                          </label>
                          <Select className="org_form_input" isMulti />
                        </FormGroup>
                      </Col>

                      <Col md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Labs
                          </label>
                          <Select className="org_form_input" isMulti />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        validateCustomStylesForm();
                      }}
                    >
                      Add License
                    </Button> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddCourseLabs;
