import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

import { Badge, Card, CardHeader, Container } from "reactstrap";
import PlusIcon from "iconComponents/PlusIcon";
import SmDownloadIcon from "iconComponents/SmDownloadIcon";
import SmEmailIcon from "iconComponents/SmEmailIcon";
import { useHistory, useParams } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import api2 from "api2";
import axios from "axios";
import ConfirmationModel from "components/Common/ConfirmationModel";
import { AiFillEye } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { getSingleScheduledExam } from "actions/adminActions/examsAction";
import AssignUserExam from "components/Common/AssignUserExam";

const ExamResult = () => {
  const { _id } = useParams();
  const history = useHistory();
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    isOpen: false,
    state: "",
    messages: "",
  });
  const [isOpen, setIsOpen] = useState();
  const singleUserExams = useSelector(
    (state) => state.examlabs.singleUserExams
  );
  const columns = useMemo(
    () => [
      {
        header: 'User Name',
        accessorKey: 'userId.name',
        Cell: ({ row }) => <>{row?.original?.userId?.name}</>,
      },
      {
        header: 'Status',
        Cell: ({ row }) => (
          <>
            <Badge
              className={classNames(
                ' complexity-badge ',
                {
                  'complexity-bg-badge-success': row?.original.resultId?.length,
                },
                {
                  'complexity-bg-badge-warning':
                    !row?.original.resultId?.length,
                }
              )}
            >
              {row?.original?.resultId?.length ? 'complete' : 'Not Attempted'}
            </Badge>
          </>
        ),
      },
      {
        header: 'Total score',
        accessorKey: 'resultId[0].totalScore',
        Cell: ({ row }) => (
          <>
            {row?.original?.resultId?.[0]?.totalScore ? (
              <>
                {row?.original?.resultId?.length
                  ? row?.original?.resultId?.[0]?.score
                  : 0}{' '}
                / {row?.original?.resultId?.[0]?.totalScore}
              </>
            ) : (
              <>---</>
            )}
          </>
        ),
      },
      {
        header: 'Test Taken',
        accessorKey: 'resultId[0].createdAt',

        Cell: ({ row }) => (
          <>
            {(row?.original?.resultId?.[0]?.createdAt &&
              moment(row?.original?.resultId?.[0]?.createdAt).format(
                'MMM DD YYYY'
              )) ||
              '---'}
          </>
        ),
      },
      {
        header: 'Total Time ',
        accessorKey: 'resultId[0].examTime',
        Cell: ({ row }) => (
          <>{row?.original?.resultId?.[0]?.examTime || '---'}</>
        ),
      },
      {
        header: 'Show video',
        Cell: ({ row }) => (
          <>
            {row?.original?.resultId[0]?.screenVideo && (
              <div
                className="d-flex align-items-center justify-content-center cursor-pointer"
                onClick={() =>
                  handleDownload(
                    row?.original?.resultId?.length &&
                      row?.original?.resultId[0]?.screenVideo
                  )
                }
              >
                {/* <i class="fas fa-download"></i>     */}
                <i class="fas fa-eye"></i>
              </div>
            )}
          </>
        ),
      },
    ],
    []
  );
  const handleDownload = (value) => {
    window.open(value);
  };
  const allExams = useSelector((state) => state?.exams?.allExams?.data?.list);
  useEffect(() => {
    dispatch(getSingleScheduledExam(_id));
  }, [dispatch]);
  const handleAddTest = () => {
    setIsOpen(true);
  };
  const handleSubmit = async (selectedUsers) => {
    let payload = [];
    payload = selectedUsers.map((item) => {
      return {
        userId: item,
        resultId: [],
      };
    });
    try {
      const config = {
        headers: { Authorization: token },
      };
      await api2
        .put(`/api/scheduledExam/${_id}`, {assignIds:payload}, config)
        .then((res, err) => {
          if (res) {
            setIsOpen(false);
            setAlert({
              ...alert,
              isOpen: true,
              status: "success",
              messages: "Exam Assign successfully!",
            });
            dispatch(getSingleScheduledExam(_id));
          }
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Exam Users</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button className="pr-btn-with-icon" onClick={handleAddTest}>
                <PlusIcon color="white" />
                Add User
              </button>
            </div>
          </div>
        </CardHeader>
        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={singleUserExams?.data?.exams?.[0]?.userIds || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                {row?.original?.resultId?.length ? (
                  <button
                    className="table-action-other-btn"
                    style={{ width: "max-content" }}
                    onClick={() =>
                      history.push(
                        `/pdf/preview/${row?.original?.resultId?.[0]?._id}`
                      )
                    }
                  >
                    <IoEyeOutline color={"#070707"} />
                    View Report
                  </button>
                ) : (
                  <>--</>
                )}
                {/* <button className="table-action-other-btn">
                  <SmEmailIcon color={"#070707"} />
                  Send Email
                </button> */}
              </Box>
            )}
          />
        </MuiOverride>
      </Card>
      <AssignUserExam
        isOpen={isOpen}
        existingUser={singleUserExams?.data?.exams?.[0]?.userIds.map(
          (i) => i.userId?._id
        )}
        handleSubmit={handleSubmit}
        onClose={() => setIsOpen(false)}
      />
      {alert?.status === "success" && alert?.isOpen === true && (
        // <SweetAlert success title={alert?.messages} onConfirm={()=>setAlert({isOpen:false})} />
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpen}
          confirmation={() => setAlert({ isOpen: false })}
          title={alert?.messages}
        />
      )}
    </Container>
  );
};

export default ExamResult;
