import API from "api2";
import { showAlert } from "actions/templateAction";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "../../type";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const getCoursesApi = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: "get",
        url: `api/course?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: "GET_COURSES_SUCCESS",
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({
            type: "GET_COURSES_FAILED",
            payload: "failed",
            err: errMessage,
          });
          reject();
        });
    });
  };
};

export const createCourse = (formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    const bodyFormData = new FormData();

    if (formData.coverImage)
      bodyFormData.append('coverImage', formData.coverImage);
    if (formData.thumbnail)
      bodyFormData.append('thumbnail', formData.thumbnail);

    return new Promise((resolve, reject) => {
      API({
        method: 'post',
        url: `api/course`,
        headers: config(token),
        data: {
          ...formData,
          // title: formData.title,
          // content: formData.content,
          // instructions: formData.instructions,
          // flagQuestion: formData.flagQuestion,
          // flag: formData.flag,
          // categoryId: formData.categoryIds[0].value,
          // categoryIds: formData.categoryIds.map(x => x.value),
          // solution: formData.solution,
          // difficulty: formData.difficulty,
          // score: formData.score
        },
      })
        .then((res) => {
          // API({
          //   method: 'post',
          //   url: `api/course/${res.data.data._id}/images`,
          //   headers: config(token),
          //   data: bodyFormData,
          // })
          //   .then((res2) => {
          //     const { data } = res;
          //     dispatch({ type: 'CREATE_COURSE_SUCCESS' });
          //     dispatch(showAlert(true, 'success', data.message));
          //     resolve(data);
          //   })
          //   .catch((err2) => {
          //     let errMessage =
          //       (err2.response && err2.response.data.message) ||
          //       'Unknown Error Occured';
          //     dispatch({ type: 'CREATE_COURSE_ERROR' }, err2);
          //     dispatch(showAlert(true, 'failed', errMessage));
          //     reject();
          //   });
          // const { data } = res;
          // dispatch({ type: "CREATE_COURSE_SUCCESS" });
          // dispatch(showAlert(true, 'success', data.message));
          // resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'CREATE_COURSE_ERROR' }, err);
          dispatch(showAlert(true, 'failed', errMessage));
          reject();
        });
    });
  };
};
export const getMCQApi = (skip, limit) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'get',
        url: `api/mcq`,
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'GET_MCQ_SUCCESS', payload: 'success', data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: 'GET_MCQ_FAILED', payload: 'failed', err });
          reject(errorMessage);
        });
    });
  };
};

export const getLabApi = () => {
  return async (dispatch, getState) => {
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MGRhYTU3MTFjZjIyN2IzMGI0YmQzZjMiLCJpc0VtYWlsVmVyaWZpZWQiOnRydWUsImlzQWN0aXZlIjp0cnVlLCJuYW1lIjoiTWVldCIsImVtYWlsIjoibWVldG9ucHJlbWlzZUB5b3BtYWlsLmNvbSIsInVzZXJUeXBlIjoiYWRtaW4iLCJpYXQiOjE2Mjg4MzQ5MjV9._AfmfixJFOeipqKuXn_BdNdhk2uvSWXXuOe-I4keRPA";
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API.get(`api/public/challenge?skip=0&limit=300`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'GET_CHALLENGE_SUCCESS', payload: 'success', data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: 'GET_CHALLENGE_FAILED', payload: 'failed', err });
          reject(errorMessage);
        });
    });
  };
};
export const deleteCourse = (courseId) => {
  return (dispatch, getState) => {
    const state = getState().courses.courses;
    const token = getState().authState.user.accessToken;
    return trackPromise(
      new Promise((resolve, reject) => {
        API({
          method: 'delete',
          url: `api/course/${courseId}`,
          headers: config(token),
        })
          .then(() => {
            let c = state.filter((item) => item.id !== courseId);
            dispatch({ type: 'DELETE_COURSE_SUCCESS', data: c });
            resolve();
          })
          .catch((err) => {
            dispatch({ type: 'DELETE_COURSE_ERROR' }, err);
            reject();
          });
      }),
      loaderType.GET_CATEGORIES
    );
  };
};
export const restoreCourse = (courseId) => {
  return (dispatch, getState) => {
    const state = getState().courses.courses;
    const token = getState().authState.user.accessToken;
    return trackPromise(
      new Promise((resolve, reject) => {
        API({
          method: 'put',
          url: `api/course/${courseId}`,
          headers: config(token),
          data: {
            isDeleted: false,
          },
        })
          .then(() => {
            let c = state.filter((item) => item.id !== courseId);
            dispatch({ type: 'DELETE_COURSE_SUCCESS', data: c });
            resolve();
          })
          .catch((err) => {
            dispatch({ type: 'DELETE_COURSE_ERROR' }, err);
            reject();
          });
      }),
      loaderType.GET_CATEGORIES
    );
  };
};
export const deletePermanentCourse = (courseId) => {
  return (dispatch, getState) => {
    const state = getState().courses.courses;
    const token = getState().authState.user.accessToken;
    return trackPromise(
      new Promise((resolve, reject) => {
        API({
          method: 'delete',
          url: `api/course/deletePermanent/${courseId}`,
          headers: config(token),
        })
          .then(() => {
            let c = state.filter((item) => item.id !== courseId);
            dispatch({ type: 'DELETE_COURSE_SUCCESS', data: c });
            resolve();
          })
          .catch((err) => {
            dispatch({ type: 'DELETE_COURSE_ERROR' }, err);
            reject();
          });
      }),
      loaderType.GET_CATEGORIES
    );
  };
};

export const editCourse = (categoryId, formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    const bodyFormData = new FormData();
    // bodyFormData.append("fullName", formData.fullName);
    // bodyFormData.append("shortName", formData.shortName);
    // bodyFormData.append("path", formData.path);
    // bodyFormData.append("type", formData.type);
    // bodyFormData.append("description", formData.description);
    // if (formData.type === "sub-category") bodyFormData.append("mainCategoryId", formData.mainCategoryId);
    if (typeof formData.coverImage === 'object')
      bodyFormData.append('coverImage', formData.coverImage);
    if (typeof formData.thumbnail === 'object')
      bodyFormData.append('thumbnail', formData.thumbnail);
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/course/${categoryId}`,
        headers: config(token),
        data: formData,
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_COURSE_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          dispatch(getCoursesApi(0, 50));
          resolve({ success: true, message: data.message });
        })
        .catch((err) => {
          dispatch({ type: 'UPDATE_COURSE_ERROR' }, err);
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch(showAlert(true, 'warning', errMessage));
          reject({ success: false, message: errMessage });
        });
    });
  };
};
export const addCoursesAPI = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let form = {
      ...data,
      // customCategoryIds: data.customCategoryIds.map(x => x.value),
    };

    const data2 = new FormData();
    data2.append('coverImage', data['coverImage']);
    data2.append('thumbnail', data['thumbnail']);
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/course`,
        headers: config(token),
        data: form,
      })
        .then((res) => {
          if (form['coverImage'] != '' || form['thumbnail'] != '') {
            API({
              method: 'post',
              url: `api/course/${res.data.data._id}/images`,
              headers: config(token),
              data: data2,
            })
              .then((res) => {
                const { data } = res;
                dispatch({
                  type: 'UPDATE_COURSE_SUCCESS',
                  payload: 'success',
                  data,
                });
                resolve(data);
              })
              .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                dispatch({
                  type: 'UPDATE_COURSE_FAILED',
                  payload: 'failed',
                  err,
                });
                reject(errorMessage);
              });
          } else {
            const { data } = res;
            dispatch({
              type: 'UPDATE_COURSE_SUCCESS',
              payload: 'success',
              data,
            });
            resolve(data);
          }
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: 'UPDATE_COURSE_FAILED', payload: 'failed', err });
          reject(errorMessage);
        });
    });
  };
};
export const updateCourse = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let payload = {
      ...data,
      // customCategoryIds: data.customCategoryIds.map(x => x.value)
    };

    const data2 = new FormData();
    if (typeof data['coverImage'] === 'object')
      data2.append('coverImage', data['coverImage']);
    if (typeof data['thumbnail'] === 'object')
      data2.append('thumbnail', data['thumbnail']);
    // data2.append("coverImage", data["coverImage"]);
    // data2.append("thumbnail", data["thumbnail"]);

    return new Promise(function (resolve, reject) {
      // API.put(`api/course/${id}`, payload, config(token))
      API({
        method: 'put',
        url: `api/course/${id}`,
        headers: config(token),
        data: payload,
      })
        .then((res) => {
          // const { data } = res;
          // dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: 'success', data })
          // resolve(data)
          if (
            typeof data['coverImage'] === 'object' ||
            typeof data['thumbnail'] === 'object'
          ) {
            API({
              method: 'post',
              url: `api/course/${res.data.data.id}/images`,
              headers: config(token),
              data: data2,
            })
              .then((res) => {
                const { data } = res;
                dispatch({
                  type: 'UPDATE_COURSE_SUCCESS',
                  payload: 'success',
                  data,
                });
                resolve(data);
              })
              .catch((err) => {
                // const errorMessage = err?.response?.data?.message;
                // dispatch({ type: 'UPDATE_COURSE_FAILED', payload: 'failed', err })
                // reject(errorMessage)

                const { data } = res;
                dispatch({
                  type: 'UPDATE_COURSE_SUCCESS',
                  payload: 'success',
                  data,
                });
                resolve(data);
              });
          } else {
            const { data } = res;
            dispatch({
              type: 'UPDATE_COURSE_SUCCESS',
              payload: 'success',
              data,
            });
            resolve(data);
          }

          // const { data } = res;
          // dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: 'success', data })
          // resolve(data)
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: 'UPDATE_COURSE_FAILED', payload: 'failed', err });
          reject(errorMessage);
        });
    });
  };
};
export const getMyCourses = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API({
        method: 'get',
        url: `api/course?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: 'FETCH_MY_COURSE_SUCCESS',
            payload: true,
            list: res.data.data.list,
          });
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_MY_COURSE_FAILED',
            payload: 'failed',
            err: errMessage,
          });
        });
    });
    // API({
    //   method: "get",
    //   url: `/api/mycourse?skip=${skip}&limit=${limit}`,
    //   headers: config(token).headers,
    // })
    //   .then((res) => {
    //     dispatch({
    //       type: "FETCH_MY_COURSE_SUCCESS",
    //       payload: true,
    //       list: res.data.data.list,
    //     });
    //   })
    //   .catch((err) => {
    //     let errMessage =
    //       (err.response && err.response.data.message) ||
    //       "Unknown Error Occured";
    //     dispatch({
    //       type: "FETCH_MY_COURSE_FAILED",
    //       payload: "failed",
    //       err: errMessage,
    //     });
    //   });
  };
};
export const addCoursesCategoryAPI = (data) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      // API.put(`api/course/${id}`, payload, config(token))
      API({
        method: 'post',
        url: `api/courseCategory`,
        headers: config(token),
        data: data,
      })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';

          reject();
        });
    });
  };
};
export const addQuestionCategoryAPI = (data) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/QuestionCategory`,
        headers: config(token),
        data: data,
      })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';

          reject();
        });
    });
  };
};
export const getAllCourseCategory = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/courseCategory?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          {
          }
          dispatch({
            type: 'GET_ALL_COURSE_CATEGORY_SUCCESS',
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'GET_ALL_COURSE_CATEGORY_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};
export const getAllQuestionCategory = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/questionCategory?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          {
          }
          dispatch({
            type: 'GET_ALL_QUESTION_CATEGORY_SUCCESS',
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'GET_ALL_QUESTION_CATEGORY_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};
export const updateCourseCategory = (data, categoryId) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise((resolve, reject) => {
      API({
        method: 'put',
        url: `api/courseCategory/${categoryId}`,
        data: data,
        headers: config(token),
      })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          reject();
        });
    });
  };
};
export const updateQuestionCategory = (data, categoryId) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise((resolve, reject) => {
      API({
        method: 'put',
        url: `api/questionCategory/${categoryId}`,
        data: data,
        headers: config(token),
      })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          reject();
        });
    });
  };
};
export const getMyCourseExam = (skip, limit, listType) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'get',
        url: `/api/getMyExams?skip=${skip}&limit=${limit}&listType=${listType}`,
        headers: config(token),
      })
        .then((res) => {
          const { data } = res?.data;
          if (listType === 'quiz') {
            dispatch({
              type: 'GET_QUIZ_SUCCESS',
              payload: 'success',
              data,
            });
          } else {
            dispatch({
              type: 'GET_EXAM_SUCCESS',
              payload: 'success',
              data,
            });
          }
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: 'GET_COURSE_EXAM_FAILED', payload: 'failed', err });
          reject(errorMessage);
        });
    });
  };
};

// export const getCoursesApi = (skip, limit) => {
//     return async (dispatch, getState) => {

//         const token = getState().authState.user.accessToken;

//         return new Promise(function (resolve, reject) {
//             API.get(`api/course?skip=${skip}&limit=${limit}`, config(token)
//             ).then((res) => {
//                 const { data } = res;
//                 dispatch({ type: 'GET_COURSES_SUCCESS', payload: 'success', data })
//                 resolve(data)
//             }).catch((err) => {
//                 const errorMessage = err?.response?.data?.message;
//                 dispatch({ type: 'GET_COURSES_FAILED', payload: 'failed', err })
//                 reject(errorMessage)
//             })
//         })
//     }
// }

// export const addCourses = (data, id) => {
//     return async (dispatch, getState) => {

//         const token = getState().authState.user.accessToken;
//         let form = {
//             ...data,
//             // customCategoryIds: data.customCategoryIds.map(x => x.value),
//         }
//         return new Promise(function(resolve, reject) {
//             API.post(`api/course`, form, config(token))
//             .then((res) => {
//                 const { data } = res;
//                 dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: 'success', data })
//                 resolve(data)
//             }).catch((err) => {
//                 const errorMessage = err?.response?.data?.message;
//                 dispatch({ type: 'UPDATE_COURSE_FAILED', payload: 'failed', err })
//                 reject(errorMessage)
//             })
//         })
//     }
// }

// export const updateCourse = (data, id) => {
//     return async (dispatch, getState) => {

//         const token = getState().authState.user.accessToken;
//         let payload = {
//             ...data,
//             // customCategoryIds: data.customCategoryIds.map(x => x.value)
//         }

//         return new Promise(function(resolve, reject) {
//             API.put(`api/course/${id}`, payload, config(token))
//             .then((res) => {
//                 const { data } = res;
//                 dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: 'success', data })
//                 resolve(data)
//             }).catch((err) => {
//                 const errorMessage = err?.response?.data?.message;
//                 dispatch({ type: 'UPDATE_COURSE_FAILED', payload: 'failed', err })
//                 reject(errorMessage)
//             })
//         })
//     }
// }

// export const deleteCourses = (id) => {
//     return async (dispatch, getState) => {

//         const token = getState().authState.user.accessToken;

//         return new Promise(function(resolve, reject) {
//             API.delete(`api/course/${id}`, config(token))
//             .then((res) => {
//                 const { data } = res;
//                 dispatch({ type: 'DELETE_COURSE_SUCCESS', payload: 'success', data })
//                 resolve(data)
//             }).catch((err) => {
//                 const errorMessage = err?.response?.data?.message;
//                 dispatch({ type: 'DELETE_COURSE_FAILED', payload: 'failed', err })
//                 reject(errorMessage)
//             })
//         })
//     }
// }
