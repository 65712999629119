import React from "react";

export default function CamIcon({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18C9.51562 18 7.5 15.9844 7.5 13.5C7.5 11.0156 9.51562 9 12 9C14.4844 9 16.5 11.0156 16.5 13.5C16.5 15.9844 14.4844 18 12 18Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.625 4.5H21.75C22.9688 4.5 24 5.53125 24 6.75V20.25C24 21.4688 22.9688 22.5 21.75 22.5H2.25C1.03125 22.5 0 21.4688 0 20.25V6.75C0 5.53125 1.03125 4.5 2.25 4.5H6.375L6.9375 2.95312C7.26562 2.0625 8.10938 1.5 9.04688 1.5H14.9531C15.8906 1.5 16.7344 2.0625 17.0625 2.95312L17.625 4.5ZM7.5 13.5C7.5 15.9844 9.51562 18 12 18C14.4844 18 16.5 15.9844 16.5 13.5C16.5 11.0156 14.4844 9 12 9C9.51562 9 7.5 11.0156 7.5 13.5Z"
        fill={color}
      />
    </svg>
  );
}
